import React from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import { useMutation } from "@apollo/client";
import { LoginDocument } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [login, { loading: creating, error: createError }] =
    useMutation(LoginDocument);

  const onFinish = async (values: {
    userEmail: string;
    userPassword: string;
  }) => {
    try {
      const { data } = await login({
        variables: {
          email: values.userEmail.toLowerCase(),
          password: values.userPassword,
        },
      });
      if (data) {
        const { userPassword, ...userData } = data.user;
        document.cookie = `token=${data.token}; path=/`;
        document.cookie = `user=${JSON.stringify({ ...userData })}; path=/`;
        navigate("/");
      }
    } catch (error) {
      if ((error as any).graphQLErrors) {
        console.error("GraphQL Errors", (error as any).graphQLErrors);
      }
      if (
        (error as any).networkError &&
        (error as any).networkError.message.includes("404")
      ) {
        console.error("Network Error", (error as any).networkError);
        setErrorMsg("Invalid email or password");
      }
      console.error("Error logging in:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
      }}
    >
      <Card style={{ maxWidth: "400px", width: "100%" }}>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Email"
            name="userEmail"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input onChange={() => setErrorMsg(null)} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="userPassword"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={creating}
            >
              Log in
            </Button>
          </Form.Item>
          {errorMsg && (
            <p style={{ color: "red", textAlign: "center" }}>{errorMsg}</p>
          )}
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            Don't have an account?{" "}
            <Typography.Link href="/register">Register now</Typography.Link>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
