import { Footer } from "antd/es/layout/layout";
import React from "react";

const FooterContainer = () => {
  return (
    <Footer style={{ textAlign: "center" }}>
      Copyright ©{new Date().getFullYear()} Restyn, Inc. All Rights Reserved.
    </Footer>
  );
};

export default FooterContainer;
