import React, { createContext, useContext, useState } from "react";

interface Sphere {
  sphereId: string;
  sphereName: string;
  sphereColor: string;
}

interface SelectedSpheresContextType {
  selectedSpheres: Sphere[];
  setSelectedSpheres: React.Dispatch<React.SetStateAction<Sphere[]>>;
  addSphere: (sphere: Sphere) => void;
  removeSphere: (sphereId: string) => void;
}

const SelectedSpheresContext = createContext<SelectedSpheresContextType | null>(
  null
);

export const SelectedSpheresProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedSpheres, setSelectedSpheres] = useState<Sphere[]>([]);

  const addSphere = (sphere: Sphere) => {
    setSelectedSpheres((prev) =>
      prev.find((s) => s.sphereId === sphere.sphereId)
        ? [...prev]
        : [...prev, sphere]
    );
  };

  const removeSphere = (sphereId: string) => {
    setSelectedSpheres((prev) => prev.filter((s) => s.sphereId !== sphereId));
  };

  return (
    <SelectedSpheresContext.Provider
      value={{ selectedSpheres, setSelectedSpheres, addSphere, removeSphere }}
    >
      {children}
    </SelectedSpheresContext.Provider>
  );
};

export const useSelectedSpheres = () => {
  const context = useContext(SelectedSpheresContext);
  if (!context) {
    throw new Error(
      "useSelectedSpheres must be used within SelectedSpheresProvider"
    );
  }
  return context;
};
