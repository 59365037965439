import { ConfigProvider, Layout } from "antd";
import React, { useEffect } from "react";
import Navbar from "./components/navigation/Navbar";
import FooterContainer from "./components/FooterContainer";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import HeaderComponent from "./components/Header";
import "./App.scss";
import LoginForm from "./components/pages/login/LoginForm";
import RegisterForm from "./components/pages/login/RegisterForm";
import AllPeople from "./components/pages/people/AllPeople";
import PeopleList from "./components/pages/people/PeopleList";
import { getCookie } from "./components/hooks/cookie";
import UserProfile from "./components/pages/user/UserProfile";
import UserUpdateForm from "./components/pages/user/UserUpdateForm";
import PersonForm from "./components/pages/people/PersonForm";
import DisplayPerson from "./components/pages/people/DisplayPerson";
import TaskForm from "./components/pages/tasks/TaskForm";
import { SelectedSpheresProvider } from "./contexts/SelectedSpheresContext";

/**
 * App renders all components and manages routes
 */
const App: React.FC = () => {
  const token = getCookie("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !["/login", "/register"].includes(window.location.pathname)) {
      navigate("/login");
    }
  }, [token, navigate]);

  return (
    <SelectedSpheresProvider>
      <ConfigProvider theme={{ token: { colorPrimary: "#3a86ff" } }}>
        <HeaderComponent />
        <Layout style={{ minHeight: "100vh" }} className="app">
          {token ? (
            <>
              <Navbar />
              <Layout>
                <Content style={{ padding: "48px 24px 24px 24px" }}>
                  <Routes>
                    <Route path="/" element={<AllPeople />} />
                    <Route path="/people-list" element={<PeopleList />} />
                    <Route path="/my-account" element={<UserProfile />} />
                    <Route path="/person/:name" element={<DisplayPerson />} />
                    <Route
                      path="/person-form/:action"
                      element={<PersonForm />}
                    />
                    <Route path="/task-form/:action" element={<TaskForm />} />
                    <Route
                      path="/my-account/update"
                      element={<UserUpdateForm />}
                    />
                    {/* Add more protected routes here */}
                  </Routes>
                </Content>
                <FooterContainer />
              </Layout>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<LoginForm />} />
              <Route path="/register" element={<RegisterForm />} />
              {/* Add more public routes here */}
            </Routes>
          )}
        </Layout>
      </ConfigProvider>
    </SelectedSpheresProvider>
  );
};

export default App;
