import React from "react";
import { EmailInterface } from "../../../types/types";
import { Button, Input, Popconfirm, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const EmailInputs = ({ action, emails, setEmails, emailTypes }: any) => {
  const handleEmailChange = (
    id: string,
    field: keyof EmailInterface,
    value: any
  ) => {
    const modifiedValue = (email: any) => {
      if (!email.newEmail) {
        if (field === "modified" && value === "delete") {
          return "delete";
        } else {
          return "update";
        }
      } else if (email.newEmail && field === "modified" && value === "delete") {
        return "delete";
      }
    };
    setEmails((prevEmails: any) =>
      prevEmails.map((email: EmailInterface) =>
        email.emailId === id
          ? {
              ...email,
              [field]: value,
              modified: modifiedValue(email),
            }
          : email
      )
    );
  };

  return (
    emailTypes?.__type?.enumValues &&
    emails.filter((email: EmailInterface) => email?.modified !== "delete")
      .length > 0 &&
    emails
      .filter((email: EmailInterface) => email?.modified !== "delete")
      .map((email: EmailInterface) => (
        <span className="email-input-container" key={email.emailId}>
          <Input
            className="email-input"
            placeholder="Enter email address"
            defaultValue={action === "update" ? email.emailAddress : ""}
            onChange={(e) => {
              handleEmailChange(email.emailId, "emailAddress", e.target.value);
            }}
            addonBefore={
              <Select
                className="email-select"
                placeholder="Select Type"
                value={
                  action === "update" && email.emailType
                    ? email.emailType
                    : undefined
                }
                options={
                  emailTypes.__type?.enumValues
                    ? emailTypes.__type?.enumValues.map((media: any) => ({
                        value: media.name,
                        label: media.name,
                      }))
                    : []
                }
                onChange={(value) => {
                  handleEmailChange(email.emailId, "emailType", value);
                }}
              ></Select>
            }
          />
          <Popconfirm
            title="Are you sure you want to delete this email address?"
            onConfirm={() =>
              handleEmailChange(email.emailId, "modified", "delete")
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </span>
      ))
  );
};

export default EmailInputs;
