import React from "react";
import { Card, Typography } from "antd";
import UserForm from "../../reusable-components/UserForm";

/**
 * RegisterForm component: create new User account
 * Calls UserForm component with page="register" to use the form for registration
 */
const RegisterForm: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Card style={{ maxWidth: "400px", width: "100%" }}>
        <UserForm page="register" />
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          Already have an account?{" "}
          <Typography.Link href="/login">Log In</Typography.Link>
        </div>
      </Card>
    </div>
  );
};

export default RegisterForm;
