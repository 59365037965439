export function capitalizeWords(str: string): string {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });
  return capitalizedWords.join(" ");
}

export function handlePhoneFormat(personPhoneNumber: string) {
  if (
    personPhoneNumber &&
    personPhoneNumber.length &&
    personPhoneNumber.includes(".")
  ) {
    const [isoCode, countryCode, areaCode, phoneNumber] =
      personPhoneNumber.split(".");

    return {
      isoCode,
      countryCode: parseInt(countryCode),
      areaCode,
      phoneNumber,
    };
  } else {
    return {
      isoCode: "",
      countryCode: null,
      areaCode: "",
      phoneNumber: "",
    };
  }
}

export function displayPhoneNumber(
  personPhoneNumber: string,
  personCountryCode: number,
  personAreaCode: string
) {
  const formattedPhoneNumber =
    personPhoneNumber.slice(0, 3) + "-" + personPhoneNumber.slice(3);
  return (
    "+" +
    personCountryCode +
    " ( " +
    personAreaCode +
    " ) " +
    formattedPhoneNumber
  );
}

export const renderPhone = (phone: any) => {
  const personPhoneNumber = handlePhoneFormat(phone).phoneNumber;
  const personCountryCode = handlePhoneFormat(phone).countryCode;
  const personAreaCode = handlePhoneFormat(phone).areaCode;
  if (personPhoneNumber.length && personCountryCode && personAreaCode.length) {
    return (
      <a
        href={`tel:+${personCountryCode}${personAreaCode}${personPhoneNumber}`}
      >
        {displayPhoneNumber(
          personPhoneNumber,
          personCountryCode,
          personAreaCode
        )}
      </a>
    );
  }
};
