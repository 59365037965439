import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type AuthPayloadAggregateSelection = {
  __typename?: 'AuthPayloadAggregateSelection';
  count: Scalars['Int']['output'];
  token: StringAggregateSelection;
};

export type AuthPayloadCreateInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPayloadEdge = {
  __typename?: 'AuthPayloadEdge';
  cursor: Scalars['String']['output'];
  node: AuthPayload;
};

export type AuthPayloadOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more AuthPayloadSort objects to sort AuthPayloads by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadSort>>;
};

/** Fields to sort AuthPayloads by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadSort object. */
export type AuthPayloadSort = {
  token?: InputMaybe<SortDirection>;
};

export type AuthPayloadUpdateInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPayloadWhere = {
  AND?: InputMaybe<Array<AuthPayloadWhere>>;
  NOT?: InputMaybe<AuthPayloadWhere>;
  OR?: InputMaybe<Array<AuthPayloadWhere>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  token_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  token_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  token_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPayloadsConnection = {
  __typename?: 'AuthPayloadsConnection';
  edges: Array<AuthPayloadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreateAuthPayloadsMutationResponse = {
  __typename?: 'CreateAuthPayloadsMutationResponse';
  authPayloads: Array<AuthPayload>;
  info: CreateInfo;
};

export type CreateEmailsMutationResponse = {
  __typename?: 'CreateEmailsMutationResponse';
  emails: Array<Email>;
  info: CreateInfo;
};

/** Information about the number of nodes and relationships created during a create mutation */
export type CreateInfo = {
  __typename?: 'CreateInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesCreated: Scalars['Int']['output'];
  relationshipsCreated: Scalars['Int']['output'];
};

export type CreatePeopleMutationResponse = {
  __typename?: 'CreatePeopleMutationResponse';
  info: CreateInfo;
  people: Array<Person>;
};

export type CreatePersonRelationsMutationResponse = {
  __typename?: 'CreatePersonRelationsMutationResponse';
  info: CreateInfo;
  personRelations: Array<PersonRelation>;
};

export type CreatePhonesMutationResponse = {
  __typename?: 'CreatePhonesMutationResponse';
  info: CreateInfo;
  phones: Array<Phone>;
};

export type CreatePlatformsMutationResponse = {
  __typename?: 'CreatePlatformsMutationResponse';
  info: CreateInfo;
  platforms: Array<Platform>;
};

export type CreateSpheresMutationResponse = {
  __typename?: 'CreateSpheresMutationResponse';
  info: CreateInfo;
  spheres: Array<Sphere>;
};

export type CreateTasksMutationResponse = {
  __typename?: 'CreateTasksMutationResponse';
  info: CreateInfo;
  tasks: Array<Task>;
};

export type CreateUsersMutationResponse = {
  __typename?: 'CreateUsersMutationResponse';
  info: CreateInfo;
  users: Array<User>;
};

/** Information about the number of nodes and relationships deleted during a delete mutation */
export type DeleteInfo = {
  __typename?: 'DeleteInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesDeleted: Scalars['Int']['output'];
  relationshipsDeleted: Scalars['Int']['output'];
};

export type Email = {
  __typename?: 'Email';
  emailAddress: Scalars['String']['output'];
  emailId: Scalars['ID']['output'];
  emailOwner: Person;
  emailOwnerAggregate?: Maybe<EmailPersonEmailOwnerAggregationSelection>;
  emailOwnerConnection: EmailEmailOwnerConnection;
  emailType: EmailType;
};


export type EmailEmailOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type EmailEmailOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type EmailEmailOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EmailEmailOwnerConnectionSort>>;
  where?: InputMaybe<EmailEmailOwnerConnectionWhere>;
};

export type EmailAggregateSelection = {
  __typename?: 'EmailAggregateSelection';
  count: Scalars['Int']['output'];
  emailAddress: StringAggregateSelection;
  emailId: IdAggregateSelection;
};

export type EmailConnectInput = {
  emailOwner?: InputMaybe<EmailEmailOwnerConnectFieldInput>;
};

export type EmailConnectOrCreateInput = {
  emailOwner?: InputMaybe<EmailEmailOwnerConnectOrCreateFieldInput>;
};

export type EmailConnectWhere = {
  node: EmailWhere;
};

export type EmailCreateInput = {
  emailAddress: Scalars['String']['input'];
  emailId: Scalars['ID']['input'];
  emailOwner?: InputMaybe<EmailEmailOwnerFieldInput>;
  emailType: EmailType;
};

export type EmailDeleteInput = {
  emailOwner?: InputMaybe<EmailEmailOwnerDeleteFieldInput>;
};

export type EmailDisconnectInput = {
  emailOwner?: InputMaybe<EmailEmailOwnerDisconnectFieldInput>;
};

export type EmailEdge = {
  __typename?: 'EmailEdge';
  cursor: Scalars['String']['output'];
  node: Email;
};

export type EmailEmailOwnerAggregateInput = {
  AND?: InputMaybe<Array<EmailEmailOwnerAggregateInput>>;
  NOT?: InputMaybe<EmailEmailOwnerAggregateInput>;
  OR?: InputMaybe<Array<EmailEmailOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EmailEmailOwnerNodeAggregationWhereInput>;
};

export type EmailEmailOwnerConnectFieldInput = {
  connect?: InputMaybe<PersonConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type EmailEmailOwnerConnectOrCreateFieldInput = {
  onCreate: EmailEmailOwnerConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type EmailEmailOwnerConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type EmailEmailOwnerConnection = {
  __typename?: 'EmailEmailOwnerConnection';
  edges: Array<EmailEmailOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmailEmailOwnerConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type EmailEmailOwnerConnectionWhere = {
  AND?: InputMaybe<Array<EmailEmailOwnerConnectionWhere>>;
  NOT?: InputMaybe<EmailEmailOwnerConnectionWhere>;
  OR?: InputMaybe<Array<EmailEmailOwnerConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type EmailEmailOwnerCreateFieldInput = {
  node: PersonCreateInput;
};

export type EmailEmailOwnerDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<EmailEmailOwnerConnectionWhere>;
};

export type EmailEmailOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<EmailEmailOwnerConnectionWhere>;
};

export type EmailEmailOwnerFieldInput = {
  connect?: InputMaybe<EmailEmailOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<EmailEmailOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<EmailEmailOwnerCreateFieldInput>;
};

export type EmailEmailOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EmailEmailOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EmailEmailOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EmailEmailOwnerNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailEmailOwnerRelationship = {
  __typename?: 'EmailEmailOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type EmailEmailOwnerUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type EmailEmailOwnerUpdateFieldInput = {
  connect?: InputMaybe<EmailEmailOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<EmailEmailOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<EmailEmailOwnerCreateFieldInput>;
  delete?: InputMaybe<EmailEmailOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<EmailEmailOwnerDisconnectFieldInput>;
  update?: InputMaybe<EmailEmailOwnerUpdateConnectionInput>;
  where?: InputMaybe<EmailEmailOwnerConnectionWhere>;
};

export type EmailOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more EmailSort objects to sort Emails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EmailSort>>;
};

export type EmailPersonEmailOwnerAggregationSelection = {
  __typename?: 'EmailPersonEmailOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EmailPersonEmailOwnerNodeAggregateSelection>;
};

export type EmailPersonEmailOwnerNodeAggregateSelection = {
  __typename?: 'EmailPersonEmailOwnerNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type EmailRelationInput = {
  emailOwner?: InputMaybe<EmailEmailOwnerCreateFieldInput>;
};

/** Fields to sort Emails by. The order in which sorts are applied is not guaranteed when specifying many fields in one EmailSort object. */
export type EmailSort = {
  emailAddress?: InputMaybe<SortDirection>;
  emailId?: InputMaybe<SortDirection>;
  emailType?: InputMaybe<SortDirection>;
};

export enum EmailType {
  Aol = 'AOL',
  Business = 'BUSINESS',
  Gmail = 'GMAIL',
  Home = 'HOME',
  Icloud = 'ICLOUD',
  Main = 'MAIN',
  Newsletter = 'NEWSLETTER',
  Other = 'OTHER',
  Outlook = 'OUTLOOK',
  Personal = 'PERSONAL',
  Primary = 'PRIMARY',
  Protonmail = 'PROTONMAIL',
  Recovery = 'RECOVERY',
  School = 'SCHOOL',
  Secondary = 'SECONDARY',
  Spam = 'SPAM',
  Support = 'SUPPORT',
  Temporary = 'TEMPORARY',
  Work = 'WORK',
  WorkAlias = 'WORK_ALIAS',
  Yahoo = 'YAHOO',
  Yandex = 'YANDEX',
  Zoho = 'ZOHO'
}

export type EmailUpdateInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailId?: InputMaybe<Scalars['ID']['input']>;
  emailOwner?: InputMaybe<EmailEmailOwnerUpdateFieldInput>;
  emailType?: InputMaybe<EmailType>;
};

export type EmailWhere = {
  AND?: InputMaybe<Array<EmailWhere>>;
  NOT?: InputMaybe<EmailWhere>;
  OR?: InputMaybe<Array<EmailWhere>>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  emailAddress_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  emailAddress_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  emailAddress_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  emailId?: InputMaybe<Scalars['ID']['input']>;
  emailId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  emailId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  emailId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  emailId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  emailOwner?: InputMaybe<PersonWhere>;
  emailOwnerAggregate?: InputMaybe<EmailEmailOwnerAggregateInput>;
  emailOwnerConnection?: InputMaybe<EmailEmailOwnerConnectionWhere>;
  emailOwnerConnection_NOT?: InputMaybe<EmailEmailOwnerConnectionWhere>;
  emailOwner_NOT?: InputMaybe<PersonWhere>;
  emailType?: InputMaybe<EmailType>;
  emailType_IN?: InputMaybe<Array<EmailType>>;
};

export type EmailsConnection = {
  __typename?: 'EmailsConnection';
  edges: Array<EmailEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IdAggregateSelection = {
  __typename?: 'IDAggregateSelection';
  longest?: Maybe<Scalars['ID']['output']>;
  shortest?: Maybe<Scalars['ID']['output']>;
};

export type IntAggregateSelection = {
  __typename?: 'IntAggregateSelection';
  average?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAuthPayloads: CreateAuthPayloadsMutationResponse;
  createEmails: CreateEmailsMutationResponse;
  createPeople: CreatePeopleMutationResponse;
  createPersonRelations: CreatePersonRelationsMutationResponse;
  createPhones: CreatePhonesMutationResponse;
  createPlatforms: CreatePlatformsMutationResponse;
  createSpheres: CreateSpheresMutationResponse;
  createTasks: CreateTasksMutationResponse;
  createUsers: CreateUsersMutationResponse;
  deleteAuthPayloads: DeleteInfo;
  deleteEmails: DeleteInfo;
  deletePeople: DeleteInfo;
  deletePersonRelations: DeleteInfo;
  deletePhones: DeleteInfo;
  deletePlatforms: DeleteInfo;
  deleteSpheres: DeleteInfo;
  deleteTasks: DeleteInfo;
  deleteUsers: DeleteInfo;
  login?: Maybe<AuthPayload>;
  updateAuthPayloads: UpdateAuthPayloadsMutationResponse;
  updateEmails: UpdateEmailsMutationResponse;
  updatePeople: UpdatePeopleMutationResponse;
  updatePersonRelations: UpdatePersonRelationsMutationResponse;
  updatePhones: UpdatePhonesMutationResponse;
  updatePlatforms: UpdatePlatformsMutationResponse;
  updateSpheres: UpdateSpheresMutationResponse;
  updateTasks: UpdateTasksMutationResponse;
  updateUsers: UpdateUsersMutationResponse;
};


export type MutationCreateAuthPayloadsArgs = {
  input: Array<AuthPayloadCreateInput>;
};


export type MutationCreateEmailsArgs = {
  input: Array<EmailCreateInput>;
};


export type MutationCreatePeopleArgs = {
  input: Array<PersonCreateInput>;
};


export type MutationCreatePersonRelationsArgs = {
  input: Array<PersonRelationCreateInput>;
};


export type MutationCreatePhonesArgs = {
  input: Array<PhoneCreateInput>;
};


export type MutationCreatePlatformsArgs = {
  input: Array<PlatformCreateInput>;
};


export type MutationCreateSpheresArgs = {
  input: Array<SphereCreateInput>;
};


export type MutationCreateTasksArgs = {
  input: Array<TaskCreateInput>;
};


export type MutationCreateUsersArgs = {
  input: Array<UserCreateInput>;
};


export type MutationDeleteAuthPayloadsArgs = {
  where?: InputMaybe<AuthPayloadWhere>;
};


export type MutationDeleteEmailsArgs = {
  delete?: InputMaybe<EmailDeleteInput>;
  where?: InputMaybe<EmailWhere>;
};


export type MutationDeletePeopleArgs = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<PersonWhere>;
};


export type MutationDeletePersonRelationsArgs = {
  delete?: InputMaybe<PersonRelationDeleteInput>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type MutationDeletePhonesArgs = {
  delete?: InputMaybe<PhoneDeleteInput>;
  where?: InputMaybe<PhoneWhere>;
};


export type MutationDeletePlatformsArgs = {
  delete?: InputMaybe<PlatformDeleteInput>;
  where?: InputMaybe<PlatformWhere>;
};


export type MutationDeleteSpheresArgs = {
  delete?: InputMaybe<SphereDeleteInput>;
  where?: InputMaybe<SphereWhere>;
};


export type MutationDeleteTasksArgs = {
  delete?: InputMaybe<TaskDeleteInput>;
  where?: InputMaybe<TaskWhere>;
};


export type MutationDeleteUsersArgs = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<UserWhere>;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateAuthPayloadsArgs = {
  update?: InputMaybe<AuthPayloadUpdateInput>;
  where?: InputMaybe<AuthPayloadWhere>;
};


export type MutationUpdateEmailsArgs = {
  connect?: InputMaybe<EmailConnectInput>;
  connectOrCreate?: InputMaybe<EmailConnectOrCreateInput>;
  create?: InputMaybe<EmailRelationInput>;
  delete?: InputMaybe<EmailDeleteInput>;
  disconnect?: InputMaybe<EmailDisconnectInput>;
  update?: InputMaybe<EmailUpdateInput>;
  where?: InputMaybe<EmailWhere>;
};


export type MutationUpdatePeopleArgs = {
  connect?: InputMaybe<PersonConnectInput>;
  connectOrCreate?: InputMaybe<PersonConnectOrCreateInput>;
  create?: InputMaybe<PersonRelationInput>;
  delete?: InputMaybe<PersonDeleteInput>;
  disconnect?: InputMaybe<PersonDisconnectInput>;
  update?: InputMaybe<PersonUpdateInput>;
  where?: InputMaybe<PersonWhere>;
};


export type MutationUpdatePersonRelationsArgs = {
  connect?: InputMaybe<PersonRelationConnectInput>;
  connectOrCreate?: InputMaybe<PersonRelationConnectOrCreateInput>;
  create?: InputMaybe<PersonRelationRelationInput>;
  delete?: InputMaybe<PersonRelationDeleteInput>;
  disconnect?: InputMaybe<PersonRelationDisconnectInput>;
  update?: InputMaybe<PersonRelationUpdateInput>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type MutationUpdatePhonesArgs = {
  connect?: InputMaybe<PhoneConnectInput>;
  connectOrCreate?: InputMaybe<PhoneConnectOrCreateInput>;
  create?: InputMaybe<PhoneRelationInput>;
  delete?: InputMaybe<PhoneDeleteInput>;
  disconnect?: InputMaybe<PhoneDisconnectInput>;
  update?: InputMaybe<PhoneUpdateInput>;
  where?: InputMaybe<PhoneWhere>;
};


export type MutationUpdatePlatformsArgs = {
  connect?: InputMaybe<PlatformConnectInput>;
  connectOrCreate?: InputMaybe<PlatformConnectOrCreateInput>;
  create?: InputMaybe<PlatformRelationInput>;
  delete?: InputMaybe<PlatformDeleteInput>;
  disconnect?: InputMaybe<PlatformDisconnectInput>;
  update?: InputMaybe<PlatformUpdateInput>;
  where?: InputMaybe<PlatformWhere>;
};


export type MutationUpdateSpheresArgs = {
  connect?: InputMaybe<SphereConnectInput>;
  connectOrCreate?: InputMaybe<SphereConnectOrCreateInput>;
  create?: InputMaybe<SphereRelationInput>;
  delete?: InputMaybe<SphereDeleteInput>;
  disconnect?: InputMaybe<SphereDisconnectInput>;
  update?: InputMaybe<SphereUpdateInput>;
  where?: InputMaybe<SphereWhere>;
};


export type MutationUpdateTasksArgs = {
  connect?: InputMaybe<TaskConnectInput>;
  connectOrCreate?: InputMaybe<TaskConnectOrCreateInput>;
  create?: InputMaybe<TaskRelationInput>;
  delete?: InputMaybe<TaskDeleteInput>;
  disconnect?: InputMaybe<TaskDisconnectInput>;
  update?: InputMaybe<TaskUpdateInput>;
  where?: InputMaybe<TaskWhere>;
};


export type MutationUpdateUsersArgs = {
  connect?: InputMaybe<UserConnectInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserRelationInput>;
  delete?: InputMaybe<UserDeleteInput>;
  disconnect?: InputMaybe<UserDisconnectInput>;
  update?: InputMaybe<UserUpdateInput>;
  where?: InputMaybe<UserWhere>;
};

/** Pagination information (Relay) */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PeopleConnection = {
  __typename?: 'PeopleConnection';
  edges: Array<PersonEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Person = {
  __typename?: 'Person';
  personAreaCode?: Maybe<Scalars['String']['output']>;
  personBirthDate?: Maybe<Scalars['String']['output']>;
  personCountryCode?: Maybe<Scalars['Int']['output']>;
  personCreatedAt?: Maybe<Scalars['String']['output']>;
  personCreatedBy: User;
  personCreatedByAggregate?: Maybe<PersonUserPersonCreatedByAggregationSelection>;
  personCreatedByConnection: PersonPersonCreatedByConnection;
  personCreatedById: Scalars['ID']['output'];
  personDescription?: Maybe<Scalars['String']['output']>;
  personEmails: Array<Email>;
  personEmailsAggregate?: Maybe<PersonEmailPersonEmailsAggregationSelection>;
  personEmailsConnection: PersonPersonEmailsConnection;
  personId: Scalars['ID']['output'];
  personIsoCode?: Maybe<Scalars['String']['output']>;
  personJobTitle?: Maybe<Scalars['String']['output']>;
  personName: Scalars['String']['output'];
  personPhoneNumber?: Maybe<Scalars['String']['output']>;
  personPhones: Array<Phone>;
  personPhonesAggregate?: Maybe<PersonPhonePersonPhonesAggregationSelection>;
  personPhonesConnection: PersonPersonPhonesConnection;
  personPlatforms: Array<Platform>;
  personPlatformsAggregate?: Maybe<PersonPlatformPersonPlatformsAggregationSelection>;
  personPlatformsConnection: PersonPersonPlatformsConnection;
  personPrimaryEmail: Scalars['String']['output'];
  personPrimaryPhone: Scalars['String']['output'];
  personRelations: Array<PersonRelation>;
  personRelationsAggregate?: Maybe<PersonPersonRelationPersonRelationsAggregationSelection>;
  personRelationsConnection: PersonPersonRelationsConnection;
  personSpheres: Array<Sphere>;
  personSpheresAggregate?: Maybe<PersonSpherePersonSpheresAggregationSelection>;
  personSpheresConnection: PersonPersonSpheresConnection;
  personTasks: Array<Task>;
  personTasksAggregate?: Maybe<PersonTaskPersonTasksAggregationSelection>;
  personTasksConnection: PersonPersonTasksConnection;
  personUpdatedAt?: Maybe<Scalars['String']['output']>;
};


export type PersonPersonCreatedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


export type PersonPersonCreatedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type PersonPersonCreatedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonCreatedByConnectionSort>>;
  where?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
};


export type PersonPersonEmailsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EmailOptions>;
  where?: InputMaybe<EmailWhere>;
};


export type PersonPersonEmailsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EmailWhere>;
};


export type PersonPersonEmailsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonEmailsConnectionSort>>;
  where?: InputMaybe<PersonPersonEmailsConnectionWhere>;
};


export type PersonPersonPhonesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PhoneOptions>;
  where?: InputMaybe<PhoneWhere>;
};


export type PersonPersonPhonesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PhoneWhere>;
};


export type PersonPersonPhonesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonPhonesConnectionSort>>;
  where?: InputMaybe<PersonPersonPhonesConnectionWhere>;
};


export type PersonPersonPlatformsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PlatformOptions>;
  where?: InputMaybe<PlatformWhere>;
};


export type PersonPersonPlatformsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PlatformWhere>;
};


export type PersonPersonPlatformsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonPlatformsConnectionSort>>;
  where?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
};


export type PersonPersonRelationsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonRelationOptions>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type PersonPersonRelationsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type PersonPersonRelationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonRelationsConnectionSort>>;
  where?: InputMaybe<PersonPersonRelationsConnectionWhere>;
};


export type PersonPersonSpheresArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<SphereOptions>;
  where?: InputMaybe<SphereWhere>;
};


export type PersonPersonSpheresAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SphereWhere>;
};


export type PersonPersonSpheresConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonSpheresConnectionSort>>;
  where?: InputMaybe<PersonPersonSpheresConnectionWhere>;
};


export type PersonPersonTasksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TaskOptions>;
  where?: InputMaybe<TaskWhere>;
};


export type PersonPersonTasksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TaskWhere>;
};


export type PersonPersonTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonPersonTasksConnectionSort>>;
  where?: InputMaybe<PersonPersonTasksConnectionWhere>;
};

export type PersonAggregateSelection = {
  __typename?: 'PersonAggregateSelection';
  count: Scalars['Int']['output'];
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type PersonConnectInput = {
  personCreatedBy?: InputMaybe<PersonPersonCreatedByConnectFieldInput>;
  personEmails?: InputMaybe<Array<PersonPersonEmailsConnectFieldInput>>;
  personPhones?: InputMaybe<Array<PersonPersonPhonesConnectFieldInput>>;
  personPlatforms?: InputMaybe<Array<PersonPersonPlatformsConnectFieldInput>>;
  personRelations?: InputMaybe<Array<PersonPersonRelationsConnectFieldInput>>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresConnectFieldInput>>;
  personTasks?: InputMaybe<Array<PersonPersonTasksConnectFieldInput>>;
};

export type PersonConnectOrCreateInput = {
  personCreatedBy?: InputMaybe<PersonPersonCreatedByConnectOrCreateFieldInput>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresConnectOrCreateFieldInput>>;
};

export type PersonConnectOrCreateWhere = {
  node: PersonUniqueWhere;
};

export type PersonConnectWhere = {
  node: PersonWhere;
};

export type PersonCreateInput = {
  personAreaCode?: InputMaybe<Scalars['String']['input']>;
  personBirthDate?: InputMaybe<Scalars['String']['input']>;
  personCountryCode?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt?: InputMaybe<Scalars['String']['input']>;
  personCreatedBy?: InputMaybe<PersonPersonCreatedByFieldInput>;
  personCreatedById: Scalars['ID']['input'];
  personDescription?: InputMaybe<Scalars['String']['input']>;
  personEmails?: InputMaybe<PersonPersonEmailsFieldInput>;
  personId: Scalars['ID']['input'];
  personIsoCode?: InputMaybe<Scalars['String']['input']>;
  personJobTitle?: InputMaybe<Scalars['String']['input']>;
  personName: Scalars['String']['input'];
  personPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  personPhones?: InputMaybe<PersonPersonPhonesFieldInput>;
  personPlatforms?: InputMaybe<PersonPersonPlatformsFieldInput>;
  personPrimaryEmail: Scalars['String']['input'];
  personPrimaryPhone: Scalars['String']['input'];
  personRelations?: InputMaybe<PersonPersonRelationsFieldInput>;
  personSpheres?: InputMaybe<PersonPersonSpheresFieldInput>;
  personTasks?: InputMaybe<PersonPersonTasksFieldInput>;
  personUpdatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type PersonDeleteInput = {
  personCreatedBy?: InputMaybe<PersonPersonCreatedByDeleteFieldInput>;
  personEmails?: InputMaybe<Array<PersonPersonEmailsDeleteFieldInput>>;
  personPhones?: InputMaybe<Array<PersonPersonPhonesDeleteFieldInput>>;
  personPlatforms?: InputMaybe<Array<PersonPersonPlatformsDeleteFieldInput>>;
  personRelations?: InputMaybe<Array<PersonPersonRelationsDeleteFieldInput>>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresDeleteFieldInput>>;
  personTasks?: InputMaybe<Array<PersonPersonTasksDeleteFieldInput>>;
};

export type PersonDisconnectInput = {
  personCreatedBy?: InputMaybe<PersonPersonCreatedByDisconnectFieldInput>;
  personEmails?: InputMaybe<Array<PersonPersonEmailsDisconnectFieldInput>>;
  personPhones?: InputMaybe<Array<PersonPersonPhonesDisconnectFieldInput>>;
  personPlatforms?: InputMaybe<Array<PersonPersonPlatformsDisconnectFieldInput>>;
  personRelations?: InputMaybe<Array<PersonPersonRelationsDisconnectFieldInput>>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresDisconnectFieldInput>>;
  personTasks?: InputMaybe<Array<PersonPersonTasksDisconnectFieldInput>>;
};

export type PersonEdge = {
  __typename?: 'PersonEdge';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type PersonEmailPersonEmailsAggregationSelection = {
  __typename?: 'PersonEmailPersonEmailsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonEmailPersonEmailsNodeAggregateSelection>;
};

export type PersonEmailPersonEmailsNodeAggregateSelection = {
  __typename?: 'PersonEmailPersonEmailsNodeAggregateSelection';
  emailAddress: StringAggregateSelection;
  emailId: IdAggregateSelection;
};

export type PersonOnCreateInput = {
  personAreaCode?: InputMaybe<Scalars['String']['input']>;
  personBirthDate?: InputMaybe<Scalars['String']['input']>;
  personCountryCode?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt?: InputMaybe<Scalars['String']['input']>;
  personCreatedById: Scalars['ID']['input'];
  personDescription?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
  personIsoCode?: InputMaybe<Scalars['String']['input']>;
  personJobTitle?: InputMaybe<Scalars['String']['input']>;
  personName: Scalars['String']['input'];
  personPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  personPrimaryEmail: Scalars['String']['input'];
  personPrimaryPhone: Scalars['String']['input'];
  personUpdatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type PersonOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more PersonSort objects to sort People by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PersonSort>>;
};

export type PersonPersonCreatedByAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonCreatedByAggregateInput>>;
  NOT?: InputMaybe<PersonPersonCreatedByAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonCreatedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonCreatedByNodeAggregationWhereInput>;
};

export type PersonPersonCreatedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type PersonPersonCreatedByConnectOrCreateFieldInput = {
  onCreate: PersonPersonCreatedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type PersonPersonCreatedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type PersonPersonCreatedByConnection = {
  __typename?: 'PersonPersonCreatedByConnection';
  edges: Array<PersonPersonCreatedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonCreatedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type PersonPersonCreatedByConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonCreatedByConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonCreatedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type PersonPersonCreatedByCreateFieldInput = {
  node: UserCreateInput;
};

export type PersonPersonCreatedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
};

export type PersonPersonCreatedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
};

export type PersonPersonCreatedByFieldInput = {
  connect?: InputMaybe<PersonPersonCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonPersonCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonPersonCreatedByCreateFieldInput>;
};

export type PersonPersonCreatedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonCreatedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonCreatedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonCreatedByNodeAggregationWhereInput>>;
  userAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonCreatedByRelationship = {
  __typename?: 'PersonPersonCreatedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type PersonPersonCreatedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type PersonPersonCreatedByUpdateFieldInput = {
  connect?: InputMaybe<PersonPersonCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonPersonCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonPersonCreatedByCreateFieldInput>;
  delete?: InputMaybe<PersonPersonCreatedByDeleteFieldInput>;
  disconnect?: InputMaybe<PersonPersonCreatedByDisconnectFieldInput>;
  update?: InputMaybe<PersonPersonCreatedByUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
};

export type PersonPersonEmailsAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonEmailsAggregateInput>>;
  NOT?: InputMaybe<PersonPersonEmailsAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonEmailsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonEmailsNodeAggregationWhereInput>;
};

export type PersonPersonEmailsConnectFieldInput = {
  connect?: InputMaybe<Array<EmailConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EmailConnectWhere>;
};

export type PersonPersonEmailsConnection = {
  __typename?: 'PersonPersonEmailsConnection';
  edges: Array<PersonPersonEmailsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonEmailsConnectionSort = {
  node?: InputMaybe<EmailSort>;
};

export type PersonPersonEmailsConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonEmailsConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonEmailsConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonEmailsConnectionWhere>>;
  node?: InputMaybe<EmailWhere>;
};

export type PersonPersonEmailsCreateFieldInput = {
  node: EmailCreateInput;
};

export type PersonPersonEmailsDeleteFieldInput = {
  delete?: InputMaybe<EmailDeleteInput>;
  where?: InputMaybe<PersonPersonEmailsConnectionWhere>;
};

export type PersonPersonEmailsDisconnectFieldInput = {
  disconnect?: InputMaybe<EmailDisconnectInput>;
  where?: InputMaybe<PersonPersonEmailsConnectionWhere>;
};

export type PersonPersonEmailsFieldInput = {
  connect?: InputMaybe<Array<PersonPersonEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonEmailsCreateFieldInput>>;
};

export type PersonPersonEmailsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonEmailsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonEmailsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonEmailsNodeAggregationWhereInput>>;
  emailAddress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonEmailsRelationship = {
  __typename?: 'PersonPersonEmailsRelationship';
  cursor: Scalars['String']['output'];
  node: Email;
};

export type PersonPersonEmailsUpdateConnectionInput = {
  node?: InputMaybe<EmailUpdateInput>;
};

export type PersonPersonEmailsUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonEmailsCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonEmailsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonEmailsDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonEmailsUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonEmailsConnectionWhere>;
};

export type PersonPersonPhonesAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonPhonesAggregateInput>>;
  NOT?: InputMaybe<PersonPersonPhonesAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonPhonesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonPhonesNodeAggregationWhereInput>;
};

export type PersonPersonPhonesConnectFieldInput = {
  connect?: InputMaybe<Array<PhoneConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PhoneConnectWhere>;
};

export type PersonPersonPhonesConnection = {
  __typename?: 'PersonPersonPhonesConnection';
  edges: Array<PersonPersonPhonesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonPhonesConnectionSort = {
  node?: InputMaybe<PhoneSort>;
};

export type PersonPersonPhonesConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonPhonesConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonPhonesConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonPhonesConnectionWhere>>;
  node?: InputMaybe<PhoneWhere>;
};

export type PersonPersonPhonesCreateFieldInput = {
  node: PhoneCreateInput;
};

export type PersonPersonPhonesDeleteFieldInput = {
  delete?: InputMaybe<PhoneDeleteInput>;
  where?: InputMaybe<PersonPersonPhonesConnectionWhere>;
};

export type PersonPersonPhonesDisconnectFieldInput = {
  disconnect?: InputMaybe<PhoneDisconnectInput>;
  where?: InputMaybe<PersonPersonPhonesConnectionWhere>;
};

export type PersonPersonPhonesFieldInput = {
  connect?: InputMaybe<Array<PersonPersonPhonesConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonPhonesCreateFieldInput>>;
};

export type PersonPersonPhonesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonPhonesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonPhonesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonPhonesNodeAggregationWhereInput>>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonPhonesRelationship = {
  __typename?: 'PersonPersonPhonesRelationship';
  cursor: Scalars['String']['output'];
  node: Phone;
};

export type PersonPersonPhonesUpdateConnectionInput = {
  node?: InputMaybe<PhoneUpdateInput>;
};

export type PersonPersonPhonesUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonPhonesConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonPhonesCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonPhonesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonPhonesDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonPhonesUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonPhonesConnectionWhere>;
};

export type PersonPersonPlatformsAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonPlatformsAggregateInput>>;
  NOT?: InputMaybe<PersonPersonPlatformsAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonPlatformsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonPlatformsNodeAggregationWhereInput>;
};

export type PersonPersonPlatformsConnectFieldInput = {
  connect?: InputMaybe<Array<PlatformConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PlatformConnectWhere>;
};

export type PersonPersonPlatformsConnection = {
  __typename?: 'PersonPersonPlatformsConnection';
  edges: Array<PersonPersonPlatformsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonPlatformsConnectionSort = {
  node?: InputMaybe<PlatformSort>;
};

export type PersonPersonPlatformsConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonPlatformsConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonPlatformsConnectionWhere>>;
  node?: InputMaybe<PlatformWhere>;
};

export type PersonPersonPlatformsCreateFieldInput = {
  node: PlatformCreateInput;
};

export type PersonPersonPlatformsDeleteFieldInput = {
  delete?: InputMaybe<PlatformDeleteInput>;
  where?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
};

export type PersonPersonPlatformsDisconnectFieldInput = {
  disconnect?: InputMaybe<PlatformDisconnectInput>;
  where?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
};

export type PersonPersonPlatformsFieldInput = {
  connect?: InputMaybe<Array<PersonPersonPlatformsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonPlatformsCreateFieldInput>>;
};

export type PersonPersonPlatformsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonPlatformsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonPlatformsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonPlatformsNodeAggregationWhereInput>>;
  platformUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  platformUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  platformUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  platformUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  platformUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  platformUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  platformUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonPlatformsRelationship = {
  __typename?: 'PersonPersonPlatformsRelationship';
  cursor: Scalars['String']['output'];
  node: Platform;
};

export type PersonPersonPlatformsUpdateConnectionInput = {
  node?: InputMaybe<PlatformUpdateInput>;
};

export type PersonPersonPlatformsUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonPlatformsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonPlatformsCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonPlatformsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonPlatformsDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonPlatformsUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
};

export type PersonPersonRelationPersonRelationsAggregationSelection = {
  __typename?: 'PersonPersonRelationPersonRelationsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonPersonRelationPersonRelationsNodeAggregateSelection>;
};

export type PersonPersonRelationPersonRelationsNodeAggregateSelection = {
  __typename?: 'PersonPersonRelationPersonRelationsNodeAggregateSelection';
  relationCreatedAt: StringAggregateSelection;
  relationId: IdAggregateSelection;
  relationUpdatedAt: StringAggregateSelection;
};

export type PersonPersonRelationsAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonRelationsAggregateInput>>;
  NOT?: InputMaybe<PersonPersonRelationsAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonRelationsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonRelationsNodeAggregationWhereInput>;
};

export type PersonPersonRelationsConnectFieldInput = {
  connect?: InputMaybe<Array<PersonRelationConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonRelationConnectWhere>;
};

export type PersonPersonRelationsConnection = {
  __typename?: 'PersonPersonRelationsConnection';
  edges: Array<PersonPersonRelationsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonRelationsConnectionSort = {
  node?: InputMaybe<PersonRelationSort>;
};

export type PersonPersonRelationsConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonRelationsConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonRelationsConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonRelationsConnectionWhere>>;
  node?: InputMaybe<PersonRelationWhere>;
};

export type PersonPersonRelationsCreateFieldInput = {
  node: PersonRelationCreateInput;
};

export type PersonPersonRelationsDeleteFieldInput = {
  delete?: InputMaybe<PersonRelationDeleteInput>;
  where?: InputMaybe<PersonPersonRelationsConnectionWhere>;
};

export type PersonPersonRelationsDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonRelationDisconnectInput>;
  where?: InputMaybe<PersonPersonRelationsConnectionWhere>;
};

export type PersonPersonRelationsFieldInput = {
  connect?: InputMaybe<Array<PersonPersonRelationsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonRelationsCreateFieldInput>>;
};

export type PersonPersonRelationsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonRelationsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonRelationsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonRelationsNodeAggregationWhereInput>>;
  relationCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  relationCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  relationCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  relationCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  relationCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  relationCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  relationCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  relationUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  relationUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  relationUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  relationUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  relationUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  relationUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonRelationsRelationship = {
  __typename?: 'PersonPersonRelationsRelationship';
  cursor: Scalars['String']['output'];
  node: PersonRelation;
};

export type PersonPersonRelationsUpdateConnectionInput = {
  node?: InputMaybe<PersonRelationUpdateInput>;
};

export type PersonPersonRelationsUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonRelationsConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonRelationsCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonRelationsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonRelationsDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonRelationsUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonRelationsConnectionWhere>;
};

export type PersonPersonSpheresAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonSpheresAggregateInput>>;
  NOT?: InputMaybe<PersonPersonSpheresAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonSpheresAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonSpheresNodeAggregationWhereInput>;
};

export type PersonPersonSpheresConnectFieldInput = {
  connect?: InputMaybe<Array<SphereConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<SphereConnectWhere>;
};

export type PersonPersonSpheresConnectOrCreateFieldInput = {
  onCreate: PersonPersonSpheresConnectOrCreateFieldInputOnCreate;
  where: SphereConnectOrCreateWhere;
};

export type PersonPersonSpheresConnectOrCreateFieldInputOnCreate = {
  node: SphereOnCreateInput;
};

export type PersonPersonSpheresConnection = {
  __typename?: 'PersonPersonSpheresConnection';
  edges: Array<PersonPersonSpheresRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonSpheresConnectionSort = {
  node?: InputMaybe<SphereSort>;
};

export type PersonPersonSpheresConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonSpheresConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonSpheresConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonSpheresConnectionWhere>>;
  node?: InputMaybe<SphereWhere>;
};

export type PersonPersonSpheresCreateFieldInput = {
  node: SphereCreateInput;
};

export type PersonPersonSpheresDeleteFieldInput = {
  delete?: InputMaybe<SphereDeleteInput>;
  where?: InputMaybe<PersonPersonSpheresConnectionWhere>;
};

export type PersonPersonSpheresDisconnectFieldInput = {
  disconnect?: InputMaybe<SphereDisconnectInput>;
  where?: InputMaybe<PersonPersonSpheresConnectionWhere>;
};

export type PersonPersonSpheresFieldInput = {
  connect?: InputMaybe<Array<PersonPersonSpheresConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PersonPersonSpheresConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PersonPersonSpheresCreateFieldInput>>;
};

export type PersonPersonSpheresNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonSpheresNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonSpheresNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonSpheresNodeAggregationWhereInput>>;
  sphereColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonSpheresRelationship = {
  __typename?: 'PersonPersonSpheresRelationship';
  cursor: Scalars['String']['output'];
  node: Sphere;
};

export type PersonPersonSpheresUpdateConnectionInput = {
  node?: InputMaybe<SphereUpdateInput>;
};

export type PersonPersonSpheresUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonSpheresConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PersonPersonSpheresConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PersonPersonSpheresCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonSpheresDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonSpheresDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonSpheresUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonSpheresConnectionWhere>;
};

export type PersonPersonTasksAggregateInput = {
  AND?: InputMaybe<Array<PersonPersonTasksAggregateInput>>;
  NOT?: InputMaybe<PersonPersonTasksAggregateInput>;
  OR?: InputMaybe<Array<PersonPersonTasksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonPersonTasksNodeAggregationWhereInput>;
};

export type PersonPersonTasksConnectFieldInput = {
  connect?: InputMaybe<Array<TaskConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TaskConnectWhere>;
};

export type PersonPersonTasksConnection = {
  __typename?: 'PersonPersonTasksConnection';
  edges: Array<PersonPersonTasksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonPersonTasksConnectionSort = {
  node?: InputMaybe<TaskSort>;
};

export type PersonPersonTasksConnectionWhere = {
  AND?: InputMaybe<Array<PersonPersonTasksConnectionWhere>>;
  NOT?: InputMaybe<PersonPersonTasksConnectionWhere>;
  OR?: InputMaybe<Array<PersonPersonTasksConnectionWhere>>;
  node?: InputMaybe<TaskWhere>;
};

export type PersonPersonTasksCreateFieldInput = {
  node: TaskCreateInput;
};

export type PersonPersonTasksDeleteFieldInput = {
  delete?: InputMaybe<TaskDeleteInput>;
  where?: InputMaybe<PersonPersonTasksConnectionWhere>;
};

export type PersonPersonTasksDisconnectFieldInput = {
  disconnect?: InputMaybe<TaskDisconnectInput>;
  where?: InputMaybe<PersonPersonTasksConnectionWhere>;
};

export type PersonPersonTasksFieldInput = {
  connect?: InputMaybe<Array<PersonPersonTasksConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonTasksCreateFieldInput>>;
};

export type PersonPersonTasksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonPersonTasksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonPersonTasksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonPersonTasksNodeAggregationWhereInput>>;
  taskCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPersonTasksRelationship = {
  __typename?: 'PersonPersonTasksRelationship';
  cursor: Scalars['String']['output'];
  node: Task;
};

export type PersonPersonTasksUpdateConnectionInput = {
  node?: InputMaybe<TaskUpdateInput>;
};

export type PersonPersonTasksUpdateFieldInput = {
  connect?: InputMaybe<Array<PersonPersonTasksConnectFieldInput>>;
  create?: InputMaybe<Array<PersonPersonTasksCreateFieldInput>>;
  delete?: InputMaybe<Array<PersonPersonTasksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PersonPersonTasksDisconnectFieldInput>>;
  update?: InputMaybe<PersonPersonTasksUpdateConnectionInput>;
  where?: InputMaybe<PersonPersonTasksConnectionWhere>;
};

export type PersonPhonePersonPhonesAggregationSelection = {
  __typename?: 'PersonPhonePersonPhonesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonPhonePersonPhonesNodeAggregateSelection>;
};

export type PersonPhonePersonPhonesNodeAggregateSelection = {
  __typename?: 'PersonPhonePersonPhonesNodeAggregateSelection';
  phoneId: IdAggregateSelection;
  phoneNumber: StringAggregateSelection;
};

export type PersonPlatformPersonPlatformsAggregationSelection = {
  __typename?: 'PersonPlatformPersonPlatformsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonPlatformPersonPlatformsNodeAggregateSelection>;
};

export type PersonPlatformPersonPlatformsNodeAggregateSelection = {
  __typename?: 'PersonPlatformPersonPlatformsNodeAggregateSelection';
  platformId: IdAggregateSelection;
  platformUrl: StringAggregateSelection;
};

export type PersonRelation = {
  __typename?: 'PersonRelation';
  fromPerson: Person;
  fromPersonAggregate?: Maybe<PersonRelationPersonFromPersonAggregationSelection>;
  fromPersonConnection: PersonRelationFromPersonConnection;
  relationCreatedAt?: Maybe<Scalars['String']['output']>;
  relationId: Scalars['ID']['output'];
  relationType: RelationshipType;
  relationUpdatedAt?: Maybe<Scalars['String']['output']>;
  toPerson: Person;
  toPersonAggregate?: Maybe<PersonRelationPersonToPersonAggregationSelection>;
  toPersonConnection: PersonRelationToPersonConnection;
};


export type PersonRelationFromPersonArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type PersonRelationFromPersonAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type PersonRelationFromPersonConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonRelationFromPersonConnectionSort>>;
  where?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
};


export type PersonRelationToPersonArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type PersonRelationToPersonAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type PersonRelationToPersonConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PersonRelationToPersonConnectionSort>>;
  where?: InputMaybe<PersonRelationToPersonConnectionWhere>;
};

export type PersonRelationAggregateSelection = {
  __typename?: 'PersonRelationAggregateSelection';
  count: Scalars['Int']['output'];
  relationCreatedAt: StringAggregateSelection;
  relationId: IdAggregateSelection;
  relationUpdatedAt: StringAggregateSelection;
};

export type PersonRelationConnectInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonConnectFieldInput>;
  toPerson?: InputMaybe<PersonRelationToPersonConnectFieldInput>;
};

export type PersonRelationConnectOrCreateInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonConnectOrCreateFieldInput>;
  toPerson?: InputMaybe<PersonRelationToPersonConnectOrCreateFieldInput>;
};

export type PersonRelationConnectWhere = {
  node: PersonRelationWhere;
};

export type PersonRelationCreateInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonFieldInput>;
  relationCreatedAt?: InputMaybe<Scalars['String']['input']>;
  relationId: Scalars['ID']['input'];
  relationType: RelationshipType;
  relationUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  toPerson?: InputMaybe<PersonRelationToPersonFieldInput>;
};

export type PersonRelationDeleteInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonDeleteFieldInput>;
  toPerson?: InputMaybe<PersonRelationToPersonDeleteFieldInput>;
};

export type PersonRelationDisconnectInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonDisconnectFieldInput>;
  toPerson?: InputMaybe<PersonRelationToPersonDisconnectFieldInput>;
};

export type PersonRelationEdge = {
  __typename?: 'PersonRelationEdge';
  cursor: Scalars['String']['output'];
  node: PersonRelation;
};

export type PersonRelationFromPersonAggregateInput = {
  AND?: InputMaybe<Array<PersonRelationFromPersonAggregateInput>>;
  NOT?: InputMaybe<PersonRelationFromPersonAggregateInput>;
  OR?: InputMaybe<Array<PersonRelationFromPersonAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonRelationFromPersonNodeAggregationWhereInput>;
};

export type PersonRelationFromPersonConnectFieldInput = {
  connect?: InputMaybe<PersonConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type PersonRelationFromPersonConnectOrCreateFieldInput = {
  onCreate: PersonRelationFromPersonConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type PersonRelationFromPersonConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type PersonRelationFromPersonConnection = {
  __typename?: 'PersonRelationFromPersonConnection';
  edges: Array<PersonRelationFromPersonRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonRelationFromPersonConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type PersonRelationFromPersonConnectionWhere = {
  AND?: InputMaybe<Array<PersonRelationFromPersonConnectionWhere>>;
  NOT?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
  OR?: InputMaybe<Array<PersonRelationFromPersonConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type PersonRelationFromPersonCreateFieldInput = {
  node: PersonCreateInput;
};

export type PersonRelationFromPersonDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
};

export type PersonRelationFromPersonDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
};

export type PersonRelationFromPersonFieldInput = {
  connect?: InputMaybe<PersonRelationFromPersonConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonRelationFromPersonConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonRelationFromPersonCreateFieldInput>;
};

export type PersonRelationFromPersonNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonRelationFromPersonNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonRelationFromPersonNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonRelationFromPersonNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonRelationFromPersonRelationship = {
  __typename?: 'PersonRelationFromPersonRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type PersonRelationFromPersonUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type PersonRelationFromPersonUpdateFieldInput = {
  connect?: InputMaybe<PersonRelationFromPersonConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonRelationFromPersonConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonRelationFromPersonCreateFieldInput>;
  delete?: InputMaybe<PersonRelationFromPersonDeleteFieldInput>;
  disconnect?: InputMaybe<PersonRelationFromPersonDisconnectFieldInput>;
  update?: InputMaybe<PersonRelationFromPersonUpdateConnectionInput>;
  where?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
};

export type PersonRelationInput = {
  personCreatedBy?: InputMaybe<PersonPersonCreatedByCreateFieldInput>;
  personEmails?: InputMaybe<Array<PersonPersonEmailsCreateFieldInput>>;
  personPhones?: InputMaybe<Array<PersonPersonPhonesCreateFieldInput>>;
  personPlatforms?: InputMaybe<Array<PersonPersonPlatformsCreateFieldInput>>;
  personRelations?: InputMaybe<Array<PersonPersonRelationsCreateFieldInput>>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresCreateFieldInput>>;
  personTasks?: InputMaybe<Array<PersonPersonTasksCreateFieldInput>>;
};

export type PersonRelationOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more PersonRelationSort objects to sort PersonRelations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PersonRelationSort>>;
};

export type PersonRelationPersonFromPersonAggregationSelection = {
  __typename?: 'PersonRelationPersonFromPersonAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonRelationPersonFromPersonNodeAggregateSelection>;
};

export type PersonRelationPersonFromPersonNodeAggregateSelection = {
  __typename?: 'PersonRelationPersonFromPersonNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type PersonRelationPersonToPersonAggregationSelection = {
  __typename?: 'PersonRelationPersonToPersonAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonRelationPersonToPersonNodeAggregateSelection>;
};

export type PersonRelationPersonToPersonNodeAggregateSelection = {
  __typename?: 'PersonRelationPersonToPersonNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type PersonRelationRelationInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonCreateFieldInput>;
  toPerson?: InputMaybe<PersonRelationToPersonCreateFieldInput>;
};

/** Fields to sort PersonRelations by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonRelationSort object. */
export type PersonRelationSort = {
  relationCreatedAt?: InputMaybe<SortDirection>;
  relationId?: InputMaybe<SortDirection>;
  relationType?: InputMaybe<SortDirection>;
  relationUpdatedAt?: InputMaybe<SortDirection>;
};

export type PersonRelationToPersonAggregateInput = {
  AND?: InputMaybe<Array<PersonRelationToPersonAggregateInput>>;
  NOT?: InputMaybe<PersonRelationToPersonAggregateInput>;
  OR?: InputMaybe<Array<PersonRelationToPersonAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PersonRelationToPersonNodeAggregationWhereInput>;
};

export type PersonRelationToPersonConnectFieldInput = {
  connect?: InputMaybe<PersonConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type PersonRelationToPersonConnectOrCreateFieldInput = {
  onCreate: PersonRelationToPersonConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type PersonRelationToPersonConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type PersonRelationToPersonConnection = {
  __typename?: 'PersonRelationToPersonConnection';
  edges: Array<PersonRelationToPersonRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PersonRelationToPersonConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type PersonRelationToPersonConnectionWhere = {
  AND?: InputMaybe<Array<PersonRelationToPersonConnectionWhere>>;
  NOT?: InputMaybe<PersonRelationToPersonConnectionWhere>;
  OR?: InputMaybe<Array<PersonRelationToPersonConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type PersonRelationToPersonCreateFieldInput = {
  node: PersonCreateInput;
};

export type PersonRelationToPersonDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<PersonRelationToPersonConnectionWhere>;
};

export type PersonRelationToPersonDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<PersonRelationToPersonConnectionWhere>;
};

export type PersonRelationToPersonFieldInput = {
  connect?: InputMaybe<PersonRelationToPersonConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonRelationToPersonConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonRelationToPersonCreateFieldInput>;
};

export type PersonRelationToPersonNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PersonRelationToPersonNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PersonRelationToPersonNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PersonRelationToPersonNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonRelationToPersonRelationship = {
  __typename?: 'PersonRelationToPersonRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type PersonRelationToPersonUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type PersonRelationToPersonUpdateFieldInput = {
  connect?: InputMaybe<PersonRelationToPersonConnectFieldInput>;
  connectOrCreate?: InputMaybe<PersonRelationToPersonConnectOrCreateFieldInput>;
  create?: InputMaybe<PersonRelationToPersonCreateFieldInput>;
  delete?: InputMaybe<PersonRelationToPersonDeleteFieldInput>;
  disconnect?: InputMaybe<PersonRelationToPersonDisconnectFieldInput>;
  update?: InputMaybe<PersonRelationToPersonUpdateConnectionInput>;
  where?: InputMaybe<PersonRelationToPersonConnectionWhere>;
};

export type PersonRelationUpdateInput = {
  fromPerson?: InputMaybe<PersonRelationFromPersonUpdateFieldInput>;
  relationCreatedAt?: InputMaybe<Scalars['String']['input']>;
  relationId?: InputMaybe<Scalars['ID']['input']>;
  relationType?: InputMaybe<RelationshipType>;
  relationUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  toPerson?: InputMaybe<PersonRelationToPersonUpdateFieldInput>;
};

export type PersonRelationWhere = {
  AND?: InputMaybe<Array<PersonRelationWhere>>;
  NOT?: InputMaybe<PersonRelationWhere>;
  OR?: InputMaybe<Array<PersonRelationWhere>>;
  fromPerson?: InputMaybe<PersonWhere>;
  fromPersonAggregate?: InputMaybe<PersonRelationFromPersonAggregateInput>;
  fromPersonConnection?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
  fromPersonConnection_NOT?: InputMaybe<PersonRelationFromPersonConnectionWhere>;
  fromPerson_NOT?: InputMaybe<PersonWhere>;
  relationCreatedAt?: InputMaybe<Scalars['String']['input']>;
  relationCreatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  relationCreatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  relationCreatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relationCreatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  relationId?: InputMaybe<Scalars['ID']['input']>;
  relationId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  relationId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  relationId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  relationId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  relationType?: InputMaybe<RelationshipType>;
  relationType_IN?: InputMaybe<Array<RelationshipType>>;
  relationUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  relationUpdatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  relationUpdatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  relationUpdatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relationUpdatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  toPerson?: InputMaybe<PersonWhere>;
  toPersonAggregate?: InputMaybe<PersonRelationToPersonAggregateInput>;
  toPersonConnection?: InputMaybe<PersonRelationToPersonConnectionWhere>;
  toPersonConnection_NOT?: InputMaybe<PersonRelationToPersonConnectionWhere>;
  toPerson_NOT?: InputMaybe<PersonWhere>;
};

export type PersonRelationsConnection = {
  __typename?: 'PersonRelationsConnection';
  edges: Array<PersonRelationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Fields to sort People by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonSort object. */
export type PersonSort = {
  personAreaCode?: InputMaybe<SortDirection>;
  personBirthDate?: InputMaybe<SortDirection>;
  personCountryCode?: InputMaybe<SortDirection>;
  personCreatedAt?: InputMaybe<SortDirection>;
  personCreatedById?: InputMaybe<SortDirection>;
  personDescription?: InputMaybe<SortDirection>;
  personId?: InputMaybe<SortDirection>;
  personIsoCode?: InputMaybe<SortDirection>;
  personJobTitle?: InputMaybe<SortDirection>;
  personName?: InputMaybe<SortDirection>;
  personPhoneNumber?: InputMaybe<SortDirection>;
  personPrimaryEmail?: InputMaybe<SortDirection>;
  personPrimaryPhone?: InputMaybe<SortDirection>;
  personUpdatedAt?: InputMaybe<SortDirection>;
};

export type PersonSpherePersonSpheresAggregationSelection = {
  __typename?: 'PersonSpherePersonSpheresAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonSpherePersonSpheresNodeAggregateSelection>;
};

export type PersonSpherePersonSpheresNodeAggregateSelection = {
  __typename?: 'PersonSpherePersonSpheresNodeAggregateSelection';
  sphereColor: StringAggregateSelection;
  sphereCreatedAt: StringAggregateSelection;
  sphereId: IdAggregateSelection;
  sphereName: StringAggregateSelection;
  sphereUpdatedAt: StringAggregateSelection;
  sphereUserOwnerId: IdAggregateSelection;
};

export type PersonTaskPersonTasksAggregationSelection = {
  __typename?: 'PersonTaskPersonTasksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonTaskPersonTasksNodeAggregateSelection>;
};

export type PersonTaskPersonTasksNodeAggregateSelection = {
  __typename?: 'PersonTaskPersonTasksNodeAggregateSelection';
  taskCreatedAt: StringAggregateSelection;
  taskDescription: StringAggregateSelection;
  taskDueDate: StringAggregateSelection;
  taskId: IdAggregateSelection;
  taskName: StringAggregateSelection;
  taskUpdatedAt: StringAggregateSelection;
};

export type PersonUniqueWhere = {
  personPrimaryEmail?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']['input']>;
};

export type PersonUpdateInput = {
  personAreaCode?: InputMaybe<Scalars['String']['input']>;
  personBirthDate?: InputMaybe<Scalars['String']['input']>;
  personCountryCode?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt?: InputMaybe<Scalars['String']['input']>;
  personCreatedBy?: InputMaybe<PersonPersonCreatedByUpdateFieldInput>;
  personCreatedById?: InputMaybe<Scalars['ID']['input']>;
  personDescription?: InputMaybe<Scalars['String']['input']>;
  personEmails?: InputMaybe<Array<PersonPersonEmailsUpdateFieldInput>>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  personIsoCode?: InputMaybe<Scalars['String']['input']>;
  personJobTitle?: InputMaybe<Scalars['String']['input']>;
  personName?: InputMaybe<Scalars['String']['input']>;
  personPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  personPhones?: InputMaybe<Array<PersonPersonPhonesUpdateFieldInput>>;
  personPlatforms?: InputMaybe<Array<PersonPersonPlatformsUpdateFieldInput>>;
  personPrimaryEmail?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']['input']>;
  personRelations?: InputMaybe<Array<PersonPersonRelationsUpdateFieldInput>>;
  personSpheres?: InputMaybe<Array<PersonPersonSpheresUpdateFieldInput>>;
  personTasks?: InputMaybe<Array<PersonPersonTasksUpdateFieldInput>>;
  personUpdatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type PersonUserPersonCreatedByAggregationSelection = {
  __typename?: 'PersonUserPersonCreatedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PersonUserPersonCreatedByNodeAggregateSelection>;
};

export type PersonUserPersonCreatedByNodeAggregateSelection = {
  __typename?: 'PersonUserPersonCreatedByNodeAggregateSelection';
  userAreaCode: StringAggregateSelection;
  userBirthDate: StringAggregateSelection;
  userCountryCode: IntAggregateSelection;
  userEmail: StringAggregateSelection;
  userId: IdAggregateSelection;
  userIsoCode: StringAggregateSelection;
  userName: StringAggregateSelection;
  userPassword: StringAggregateSelection;
  userPhoneNumber: StringAggregateSelection;
};

export type PersonWhere = {
  AND?: InputMaybe<Array<PersonWhere>>;
  NOT?: InputMaybe<PersonWhere>;
  OR?: InputMaybe<Array<PersonWhere>>;
  personAreaCode?: InputMaybe<Scalars['String']['input']>;
  personAreaCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personAreaCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personAreaCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personAreaCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personBirthDate?: InputMaybe<Scalars['String']['input']>;
  personBirthDate_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personBirthDate_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personBirthDate_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personBirthDate_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personCountryCode?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  personCountryCode_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt?: InputMaybe<Scalars['String']['input']>;
  personCreatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personCreatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personCreatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personCreatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personCreatedBy?: InputMaybe<UserWhere>;
  personCreatedByAggregate?: InputMaybe<PersonPersonCreatedByAggregateInput>;
  personCreatedByConnection?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
  personCreatedByConnection_NOT?: InputMaybe<PersonPersonCreatedByConnectionWhere>;
  personCreatedById?: InputMaybe<Scalars['ID']['input']>;
  personCreatedById_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  personCreatedById_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  personCreatedById_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  personCreatedById_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  personCreatedBy_NOT?: InputMaybe<UserWhere>;
  personDescription?: InputMaybe<Scalars['String']['input']>;
  personDescription_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personDescription_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personDescription_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personDescription_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personEmailsAggregate?: InputMaybe<PersonPersonEmailsAggregateInput>;
  /** Return People where all of the related PersonPersonEmailsConnections match this filter */
  personEmailsConnection_ALL?: InputMaybe<PersonPersonEmailsConnectionWhere>;
  /** Return People where none of the related PersonPersonEmailsConnections match this filter */
  personEmailsConnection_NONE?: InputMaybe<PersonPersonEmailsConnectionWhere>;
  /** Return People where one of the related PersonPersonEmailsConnections match this filter */
  personEmailsConnection_SINGLE?: InputMaybe<PersonPersonEmailsConnectionWhere>;
  /** Return People where some of the related PersonPersonEmailsConnections match this filter */
  personEmailsConnection_SOME?: InputMaybe<PersonPersonEmailsConnectionWhere>;
  /** Return People where all of the related Emails match this filter */
  personEmails_ALL?: InputMaybe<EmailWhere>;
  /** Return People where none of the related Emails match this filter */
  personEmails_NONE?: InputMaybe<EmailWhere>;
  /** Return People where one of the related Emails match this filter */
  personEmails_SINGLE?: InputMaybe<EmailWhere>;
  /** Return People where some of the related Emails match this filter */
  personEmails_SOME?: InputMaybe<EmailWhere>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  personId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  personId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  personId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  personId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  personIsoCode?: InputMaybe<Scalars['String']['input']>;
  personIsoCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personIsoCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personIsoCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personIsoCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personJobTitle?: InputMaybe<Scalars['String']['input']>;
  personJobTitle_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personJobTitle_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personJobTitle_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personJobTitle_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personName?: InputMaybe<Scalars['String']['input']>;
  personName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personName_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  personName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  personPhoneNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personPhoneNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personPhoneNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPhonesAggregate?: InputMaybe<PersonPersonPhonesAggregateInput>;
  /** Return People where all of the related PersonPersonPhonesConnections match this filter */
  personPhonesConnection_ALL?: InputMaybe<PersonPersonPhonesConnectionWhere>;
  /** Return People where none of the related PersonPersonPhonesConnections match this filter */
  personPhonesConnection_NONE?: InputMaybe<PersonPersonPhonesConnectionWhere>;
  /** Return People where one of the related PersonPersonPhonesConnections match this filter */
  personPhonesConnection_SINGLE?: InputMaybe<PersonPersonPhonesConnectionWhere>;
  /** Return People where some of the related PersonPersonPhonesConnections match this filter */
  personPhonesConnection_SOME?: InputMaybe<PersonPersonPhonesConnectionWhere>;
  /** Return People where all of the related Phones match this filter */
  personPhones_ALL?: InputMaybe<PhoneWhere>;
  /** Return People where none of the related Phones match this filter */
  personPhones_NONE?: InputMaybe<PhoneWhere>;
  /** Return People where one of the related Phones match this filter */
  personPhones_SINGLE?: InputMaybe<PhoneWhere>;
  /** Return People where some of the related Phones match this filter */
  personPhones_SOME?: InputMaybe<PhoneWhere>;
  personPlatformsAggregate?: InputMaybe<PersonPersonPlatformsAggregateInput>;
  /** Return People where all of the related PersonPersonPlatformsConnections match this filter */
  personPlatformsConnection_ALL?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
  /** Return People where none of the related PersonPersonPlatformsConnections match this filter */
  personPlatformsConnection_NONE?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
  /** Return People where one of the related PersonPersonPlatformsConnections match this filter */
  personPlatformsConnection_SINGLE?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
  /** Return People where some of the related PersonPersonPlatformsConnections match this filter */
  personPlatformsConnection_SOME?: InputMaybe<PersonPersonPlatformsConnectionWhere>;
  /** Return People where all of the related Platforms match this filter */
  personPlatforms_ALL?: InputMaybe<PlatformWhere>;
  /** Return People where none of the related Platforms match this filter */
  personPlatforms_NONE?: InputMaybe<PlatformWhere>;
  /** Return People where one of the related Platforms match this filter */
  personPlatforms_SINGLE?: InputMaybe<PlatformWhere>;
  /** Return People where some of the related Platforms match this filter */
  personPlatforms_SOME?: InputMaybe<PlatformWhere>;
  personPrimaryEmail?: InputMaybe<Scalars['String']['input']>;
  personPrimaryEmail_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personPrimaryEmail_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPrimaryEmail_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  personPrimaryEmail_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personPrimaryPhone_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  personPrimaryPhone_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  personRelationsAggregate?: InputMaybe<PersonPersonRelationsAggregateInput>;
  /** Return People where all of the related PersonPersonRelationsConnections match this filter */
  personRelationsConnection_ALL?: InputMaybe<PersonPersonRelationsConnectionWhere>;
  /** Return People where none of the related PersonPersonRelationsConnections match this filter */
  personRelationsConnection_NONE?: InputMaybe<PersonPersonRelationsConnectionWhere>;
  /** Return People where one of the related PersonPersonRelationsConnections match this filter */
  personRelationsConnection_SINGLE?: InputMaybe<PersonPersonRelationsConnectionWhere>;
  /** Return People where some of the related PersonPersonRelationsConnections match this filter */
  personRelationsConnection_SOME?: InputMaybe<PersonPersonRelationsConnectionWhere>;
  /** Return People where all of the related PersonRelations match this filter */
  personRelations_ALL?: InputMaybe<PersonRelationWhere>;
  /** Return People where none of the related PersonRelations match this filter */
  personRelations_NONE?: InputMaybe<PersonRelationWhere>;
  /** Return People where one of the related PersonRelations match this filter */
  personRelations_SINGLE?: InputMaybe<PersonRelationWhere>;
  /** Return People where some of the related PersonRelations match this filter */
  personRelations_SOME?: InputMaybe<PersonRelationWhere>;
  personSpheresAggregate?: InputMaybe<PersonPersonSpheresAggregateInput>;
  /** Return People where all of the related PersonPersonSpheresConnections match this filter */
  personSpheresConnection_ALL?: InputMaybe<PersonPersonSpheresConnectionWhere>;
  /** Return People where none of the related PersonPersonSpheresConnections match this filter */
  personSpheresConnection_NONE?: InputMaybe<PersonPersonSpheresConnectionWhere>;
  /** Return People where one of the related PersonPersonSpheresConnections match this filter */
  personSpheresConnection_SINGLE?: InputMaybe<PersonPersonSpheresConnectionWhere>;
  /** Return People where some of the related PersonPersonSpheresConnections match this filter */
  personSpheresConnection_SOME?: InputMaybe<PersonPersonSpheresConnectionWhere>;
  /** Return People where all of the related Spheres match this filter */
  personSpheres_ALL?: InputMaybe<SphereWhere>;
  /** Return People where none of the related Spheres match this filter */
  personSpheres_NONE?: InputMaybe<SphereWhere>;
  /** Return People where one of the related Spheres match this filter */
  personSpheres_SINGLE?: InputMaybe<SphereWhere>;
  /** Return People where some of the related Spheres match this filter */
  personSpheres_SOME?: InputMaybe<SphereWhere>;
  personTasksAggregate?: InputMaybe<PersonPersonTasksAggregateInput>;
  /** Return People where all of the related PersonPersonTasksConnections match this filter */
  personTasksConnection_ALL?: InputMaybe<PersonPersonTasksConnectionWhere>;
  /** Return People where none of the related PersonPersonTasksConnections match this filter */
  personTasksConnection_NONE?: InputMaybe<PersonPersonTasksConnectionWhere>;
  /** Return People where one of the related PersonPersonTasksConnections match this filter */
  personTasksConnection_SINGLE?: InputMaybe<PersonPersonTasksConnectionWhere>;
  /** Return People where some of the related PersonPersonTasksConnections match this filter */
  personTasksConnection_SOME?: InputMaybe<PersonPersonTasksConnectionWhere>;
  /** Return People where all of the related Tasks match this filter */
  personTasks_ALL?: InputMaybe<TaskWhere>;
  /** Return People where none of the related Tasks match this filter */
  personTasks_NONE?: InputMaybe<TaskWhere>;
  /** Return People where one of the related Tasks match this filter */
  personTasks_SINGLE?: InputMaybe<TaskWhere>;
  /** Return People where some of the related Tasks match this filter */
  personTasks_SOME?: InputMaybe<TaskWhere>;
  personUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  personUpdatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  personUpdatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  personUpdatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  personUpdatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type Phone = {
  __typename?: 'Phone';
  phoneId: Scalars['ID']['output'];
  phoneNumber: Scalars['String']['output'];
  phoneOwner: Person;
  phoneOwnerAggregate?: Maybe<PhonePersonPhoneOwnerAggregationSelection>;
  phoneOwnerConnection: PhonePhoneOwnerConnection;
  phoneType: PhoneNumberType;
};


export type PhonePhoneOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type PhonePhoneOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type PhonePhoneOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PhonePhoneOwnerConnectionSort>>;
  where?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
};

export type PhoneAggregateSelection = {
  __typename?: 'PhoneAggregateSelection';
  count: Scalars['Int']['output'];
  phoneId: IdAggregateSelection;
  phoneNumber: StringAggregateSelection;
};

export type PhoneConnectInput = {
  phoneOwner?: InputMaybe<PhonePhoneOwnerConnectFieldInput>;
};

export type PhoneConnectOrCreateInput = {
  phoneOwner?: InputMaybe<PhonePhoneOwnerConnectOrCreateFieldInput>;
};

export type PhoneConnectWhere = {
  node: PhoneWhere;
};

export type PhoneCreateInput = {
  phoneId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
  phoneOwner?: InputMaybe<PhonePhoneOwnerFieldInput>;
  phoneType: PhoneNumberType;
};

export type PhoneDeleteInput = {
  phoneOwner?: InputMaybe<PhonePhoneOwnerDeleteFieldInput>;
};

export type PhoneDisconnectInput = {
  phoneOwner?: InputMaybe<PhonePhoneOwnerDisconnectFieldInput>;
};

export type PhoneEdge = {
  __typename?: 'PhoneEdge';
  cursor: Scalars['String']['output'];
  node: Phone;
};

export enum PhoneNumberType {
  Business = 'BUSINESS',
  Car = 'CAR',
  Cell = 'CELL',
  FaxHome = 'FAX_HOME',
  FaxWork = 'FAX_WORK',
  Home = 'HOME',
  HomeCell = 'HOME_CELL',
  HomeFax = 'HOME_FAX',
  Iphone = 'IPHONE',
  Main = 'MAIN',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Pager = 'PAGER',
  Personal = 'PERSONAL',
  School = 'SCHOOL',
  Tty = 'TTY',
  Work = 'WORK',
  WorkCell = 'WORK_CELL',
  WorkFax = 'WORK_FAX',
  WorkPager = 'WORK_PAGER'
}

export type PhoneOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more PhoneSort objects to sort Phones by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PhoneSort>>;
};

export type PhonePersonPhoneOwnerAggregationSelection = {
  __typename?: 'PhonePersonPhoneOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PhonePersonPhoneOwnerNodeAggregateSelection>;
};

export type PhonePersonPhoneOwnerNodeAggregateSelection = {
  __typename?: 'PhonePersonPhoneOwnerNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type PhonePhoneOwnerAggregateInput = {
  AND?: InputMaybe<Array<PhonePhoneOwnerAggregateInput>>;
  NOT?: InputMaybe<PhonePhoneOwnerAggregateInput>;
  OR?: InputMaybe<Array<PhonePhoneOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PhonePhoneOwnerNodeAggregationWhereInput>;
};

export type PhonePhoneOwnerConnectFieldInput = {
  connect?: InputMaybe<PersonConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type PhonePhoneOwnerConnectOrCreateFieldInput = {
  onCreate: PhonePhoneOwnerConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type PhonePhoneOwnerConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type PhonePhoneOwnerConnection = {
  __typename?: 'PhonePhoneOwnerConnection';
  edges: Array<PhonePhoneOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PhonePhoneOwnerConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type PhonePhoneOwnerConnectionWhere = {
  AND?: InputMaybe<Array<PhonePhoneOwnerConnectionWhere>>;
  NOT?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
  OR?: InputMaybe<Array<PhonePhoneOwnerConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type PhonePhoneOwnerCreateFieldInput = {
  node: PersonCreateInput;
};

export type PhonePhoneOwnerDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
};

export type PhonePhoneOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
};

export type PhonePhoneOwnerFieldInput = {
  connect?: InputMaybe<PhonePhoneOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<PhonePhoneOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<PhonePhoneOwnerCreateFieldInput>;
};

export type PhonePhoneOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PhonePhoneOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PhonePhoneOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PhonePhoneOwnerNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PhonePhoneOwnerRelationship = {
  __typename?: 'PhonePhoneOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type PhonePhoneOwnerUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type PhonePhoneOwnerUpdateFieldInput = {
  connect?: InputMaybe<PhonePhoneOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<PhonePhoneOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<PhonePhoneOwnerCreateFieldInput>;
  delete?: InputMaybe<PhonePhoneOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<PhonePhoneOwnerDisconnectFieldInput>;
  update?: InputMaybe<PhonePhoneOwnerUpdateConnectionInput>;
  where?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
};

export type PhoneRelationInput = {
  phoneOwner?: InputMaybe<PhonePhoneOwnerCreateFieldInput>;
};

/** Fields to sort Phones by. The order in which sorts are applied is not guaranteed when specifying many fields in one PhoneSort object. */
export type PhoneSort = {
  phoneId?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  phoneType?: InputMaybe<SortDirection>;
};

export type PhoneUpdateInput = {
  phoneId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneOwner?: InputMaybe<PhonePhoneOwnerUpdateFieldInput>;
  phoneType?: InputMaybe<PhoneNumberType>;
};

export type PhoneWhere = {
  AND?: InputMaybe<Array<PhoneWhere>>;
  NOT?: InputMaybe<PhoneWhere>;
  OR?: InputMaybe<Array<PhoneWhere>>;
  phoneId?: InputMaybe<Scalars['ID']['input']>;
  phoneId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  phoneId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  phoneId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  phoneId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  phoneOwner?: InputMaybe<PersonWhere>;
  phoneOwnerAggregate?: InputMaybe<PhonePhoneOwnerAggregateInput>;
  phoneOwnerConnection?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
  phoneOwnerConnection_NOT?: InputMaybe<PhonePhoneOwnerConnectionWhere>;
  phoneOwner_NOT?: InputMaybe<PersonWhere>;
  phoneType?: InputMaybe<PhoneNumberType>;
  phoneType_IN?: InputMaybe<Array<PhoneNumberType>>;
};

export type PhonesConnection = {
  __typename?: 'PhonesConnection';
  edges: Array<PhoneEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Platform = {
  __typename?: 'Platform';
  platformFavorite?: Maybe<Scalars['Boolean']['output']>;
  platformId: Scalars['ID']['output'];
  platformName: SocialMediaPlatform;
  platformOwner: Person;
  platformOwnerAggregate?: Maybe<PlatformPersonPlatformOwnerAggregationSelection>;
  platformOwnerConnection: PlatformPlatformOwnerConnection;
  platformUrl?: Maybe<Scalars['String']['output']>;
};


export type PlatformPlatformOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type PlatformPlatformOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type PlatformPlatformOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PlatformPlatformOwnerConnectionSort>>;
  where?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
};

export type PlatformAggregateSelection = {
  __typename?: 'PlatformAggregateSelection';
  count: Scalars['Int']['output'];
  platformId: IdAggregateSelection;
  platformUrl: StringAggregateSelection;
};

export type PlatformConnectInput = {
  platformOwner?: InputMaybe<PlatformPlatformOwnerConnectFieldInput>;
};

export type PlatformConnectOrCreateInput = {
  platformOwner?: InputMaybe<PlatformPlatformOwnerConnectOrCreateFieldInput>;
};

export type PlatformConnectWhere = {
  node: PlatformWhere;
};

export type PlatformCreateInput = {
  platformFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  platformId: Scalars['ID']['input'];
  platformName: SocialMediaPlatform;
  platformOwner?: InputMaybe<PlatformPlatformOwnerFieldInput>;
  platformUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PlatformDeleteInput = {
  platformOwner?: InputMaybe<PlatformPlatformOwnerDeleteFieldInput>;
};

export type PlatformDisconnectInput = {
  platformOwner?: InputMaybe<PlatformPlatformOwnerDisconnectFieldInput>;
};

export type PlatformEdge = {
  __typename?: 'PlatformEdge';
  cursor: Scalars['String']['output'];
  node: Platform;
};

export type PlatformOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more PlatformSort objects to sort Platforms by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PlatformSort>>;
};

export type PlatformPersonPlatformOwnerAggregationSelection = {
  __typename?: 'PlatformPersonPlatformOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<PlatformPersonPlatformOwnerNodeAggregateSelection>;
};

export type PlatformPersonPlatformOwnerNodeAggregateSelection = {
  __typename?: 'PlatformPersonPlatformOwnerNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type PlatformPlatformOwnerAggregateInput = {
  AND?: InputMaybe<Array<PlatformPlatformOwnerAggregateInput>>;
  NOT?: InputMaybe<PlatformPlatformOwnerAggregateInput>;
  OR?: InputMaybe<Array<PlatformPlatformOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<PlatformPlatformOwnerNodeAggregationWhereInput>;
};

export type PlatformPlatformOwnerConnectFieldInput = {
  connect?: InputMaybe<PersonConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type PlatformPlatformOwnerConnectOrCreateFieldInput = {
  onCreate: PlatformPlatformOwnerConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type PlatformPlatformOwnerConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type PlatformPlatformOwnerConnection = {
  __typename?: 'PlatformPlatformOwnerConnection';
  edges: Array<PlatformPlatformOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PlatformPlatformOwnerConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type PlatformPlatformOwnerConnectionWhere = {
  AND?: InputMaybe<Array<PlatformPlatformOwnerConnectionWhere>>;
  NOT?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
  OR?: InputMaybe<Array<PlatformPlatformOwnerConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type PlatformPlatformOwnerCreateFieldInput = {
  node: PersonCreateInput;
};

export type PlatformPlatformOwnerDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
};

export type PlatformPlatformOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
};

export type PlatformPlatformOwnerFieldInput = {
  connect?: InputMaybe<PlatformPlatformOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<PlatformPlatformOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<PlatformPlatformOwnerCreateFieldInput>;
};

export type PlatformPlatformOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PlatformPlatformOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PlatformPlatformOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PlatformPlatformOwnerNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type PlatformPlatformOwnerRelationship = {
  __typename?: 'PlatformPlatformOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type PlatformPlatformOwnerUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type PlatformPlatformOwnerUpdateFieldInput = {
  connect?: InputMaybe<PlatformPlatformOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<PlatformPlatformOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<PlatformPlatformOwnerCreateFieldInput>;
  delete?: InputMaybe<PlatformPlatformOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<PlatformPlatformOwnerDisconnectFieldInput>;
  update?: InputMaybe<PlatformPlatformOwnerUpdateConnectionInput>;
  where?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
};

export type PlatformRelationInput = {
  platformOwner?: InputMaybe<PlatformPlatformOwnerCreateFieldInput>;
};

/** Fields to sort Platforms by. The order in which sorts are applied is not guaranteed when specifying many fields in one PlatformSort object. */
export type PlatformSort = {
  platformFavorite?: InputMaybe<SortDirection>;
  platformId?: InputMaybe<SortDirection>;
  platformName?: InputMaybe<SortDirection>;
  platformUrl?: InputMaybe<SortDirection>;
};

export type PlatformUpdateInput = {
  platformFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  platformId?: InputMaybe<Scalars['ID']['input']>;
  platformName?: InputMaybe<SocialMediaPlatform>;
  platformOwner?: InputMaybe<PlatformPlatformOwnerUpdateFieldInput>;
  platformUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PlatformWhere = {
  AND?: InputMaybe<Array<PlatformWhere>>;
  NOT?: InputMaybe<PlatformWhere>;
  OR?: InputMaybe<Array<PlatformWhere>>;
  platformFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  platformId?: InputMaybe<Scalars['ID']['input']>;
  platformId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  platformId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  platformId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  platformId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  platformName?: InputMaybe<SocialMediaPlatform>;
  platformName_IN?: InputMaybe<Array<SocialMediaPlatform>>;
  platformOwner?: InputMaybe<PersonWhere>;
  platformOwnerAggregate?: InputMaybe<PlatformPlatformOwnerAggregateInput>;
  platformOwnerConnection?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
  platformOwnerConnection_NOT?: InputMaybe<PlatformPlatformOwnerConnectionWhere>;
  platformOwner_NOT?: InputMaybe<PersonWhere>;
  platformUrl?: InputMaybe<Scalars['String']['input']>;
  platformUrl_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  platformUrl_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  platformUrl_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platformUrl_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type PlatformsConnection = {
  __typename?: 'PlatformsConnection';
  edges: Array<PlatformEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  authPayloads: Array<AuthPayload>;
  authPayloadsAggregate: AuthPayloadAggregateSelection;
  authPayloadsConnection: AuthPayloadsConnection;
  emails: Array<Email>;
  emailsAggregate: EmailAggregateSelection;
  emailsConnection: EmailsConnection;
  people: Array<Person>;
  peopleAggregate: PersonAggregateSelection;
  peopleConnection: PeopleConnection;
  personRelations: Array<PersonRelation>;
  personRelationsAggregate: PersonRelationAggregateSelection;
  personRelationsConnection: PersonRelationsConnection;
  phones: Array<Phone>;
  phonesAggregate: PhoneAggregateSelection;
  phonesConnection: PhonesConnection;
  platforms: Array<Platform>;
  platformsAggregate: PlatformAggregateSelection;
  platformsConnection: PlatformsConnection;
  spheres: Array<Sphere>;
  spheresAggregate: SphereAggregateSelection;
  spheresConnection: SpheresConnection;
  tasks: Array<Task>;
  tasksAggregate: TaskAggregateSelection;
  tasksConnection: TasksConnection;
  users: Array<User>;
  usersAggregate: UserAggregateSelection;
  usersConnection: UsersConnection;
};


export type QueryAuthPayloadsArgs = {
  options?: InputMaybe<AuthPayloadOptions>;
  where?: InputMaybe<AuthPayloadWhere>;
};


export type QueryAuthPayloadsAggregateArgs = {
  where?: InputMaybe<AuthPayloadWhere>;
};


export type QueryAuthPayloadsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadSort>>>;
  where?: InputMaybe<AuthPayloadWhere>;
};


export type QueryEmailsArgs = {
  options?: InputMaybe<EmailOptions>;
  where?: InputMaybe<EmailWhere>;
};


export type QueryEmailsAggregateArgs = {
  where?: InputMaybe<EmailWhere>;
};


export type QueryEmailsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<EmailSort>>>;
  where?: InputMaybe<EmailWhere>;
};


export type QueryPeopleArgs = {
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type QueryPeopleAggregateArgs = {
  where?: InputMaybe<PersonWhere>;
};


export type QueryPeopleConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<PersonSort>>>;
  where?: InputMaybe<PersonWhere>;
};


export type QueryPersonRelationsArgs = {
  options?: InputMaybe<PersonRelationOptions>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type QueryPersonRelationsAggregateArgs = {
  where?: InputMaybe<PersonRelationWhere>;
};


export type QueryPersonRelationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<PersonRelationSort>>>;
  where?: InputMaybe<PersonRelationWhere>;
};


export type QueryPhonesArgs = {
  options?: InputMaybe<PhoneOptions>;
  where?: InputMaybe<PhoneWhere>;
};


export type QueryPhonesAggregateArgs = {
  where?: InputMaybe<PhoneWhere>;
};


export type QueryPhonesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<PhoneSort>>>;
  where?: InputMaybe<PhoneWhere>;
};


export type QueryPlatformsArgs = {
  options?: InputMaybe<PlatformOptions>;
  where?: InputMaybe<PlatformWhere>;
};


export type QueryPlatformsAggregateArgs = {
  where?: InputMaybe<PlatformWhere>;
};


export type QueryPlatformsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<PlatformSort>>>;
  where?: InputMaybe<PlatformWhere>;
};


export type QuerySpheresArgs = {
  options?: InputMaybe<SphereOptions>;
  where?: InputMaybe<SphereWhere>;
};


export type QuerySpheresAggregateArgs = {
  where?: InputMaybe<SphereWhere>;
};


export type QuerySpheresConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SphereSort>>>;
  where?: InputMaybe<SphereWhere>;
};


export type QueryTasksArgs = {
  options?: InputMaybe<TaskOptions>;
  where?: InputMaybe<TaskWhere>;
};


export type QueryTasksAggregateArgs = {
  where?: InputMaybe<TaskWhere>;
};


export type QueryTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<TaskSort>>>;
  where?: InputMaybe<TaskWhere>;
};


export type QueryUsersArgs = {
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


export type QueryUsersAggregateArgs = {
  where?: InputMaybe<UserWhere>;
};


export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<UserSort>>>;
  where?: InputMaybe<UserWhere>;
};

export enum RelationshipType {
  Acquaintance = 'ACQUAINTANCE',
  Colleague = 'COLLEAGUE',
  Family = 'FAMILY',
  Friend = 'FRIEND',
  Mentor = 'MENTOR',
  Partner = 'PARTNER',
  Student = 'STUDENT'
}

export enum SocialMediaPlatform {
  Adobe = 'ADOBE',
  Amazon = 'AMAZON',
  Android = 'ANDROID',
  Apple = 'APPLE',
  Clubhouse = 'CLUBHOUSE',
  Discord = 'DISCORD',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Imessage = 'IMESSAGE',
  Instagram = 'INSTAGRAM',
  Kik = 'KIK',
  Line = 'LINE',
  Linkedin = 'LINKEDIN',
  Medium = 'MEDIUM',
  Meetup = 'MEETUP',
  Outlook = 'OUTLOOK',
  Pinterest = 'PINTEREST',
  Reddit = 'REDDIT',
  Signal = 'SIGNAL',
  Skype = 'SKYPE',
  Slack = 'SLACK',
  Snapchat = 'SNAPCHAT',
  Teams = 'TEAMS',
  Telegram = 'TELEGRAM',
  Threads = 'THREADS',
  Tiktok = 'TIKTOK',
  Tumblr = 'TUMBLR',
  Twitch = 'TWITCH',
  Twitter = 'TWITTER',
  Viber = 'VIBER',
  Vimeo = 'VIMEO',
  Wechat = 'WECHAT',
  Whatsapp = 'WHATSAPP',
  Youtube = 'YOUTUBE',
  Zoho = 'ZOHO',
  Zoom = 'ZOOM'
}

/** An enum for sorting in either ascending or descending order. */
export enum SortDirection {
  /** Sort by field values in ascending order. */
  Asc = 'ASC',
  /** Sort by field values in descending order. */
  Desc = 'DESC'
}

export type Sphere = {
  __typename?: 'Sphere';
  sphereColor: Scalars['String']['output'];
  sphereCreatedAt?: Maybe<Scalars['String']['output']>;
  sphereId: Scalars['ID']['output'];
  sphereName: Scalars['String']['output'];
  spherePersons: Array<Person>;
  spherePersonsAggregate?: Maybe<SpherePersonSpherePersonsAggregationSelection>;
  spherePersonsConnection: SphereSpherePersonsConnection;
  sphereUpdatedAt?: Maybe<Scalars['String']['output']>;
  sphereUserOwner: User;
  sphereUserOwnerAggregate?: Maybe<SphereUserSphereUserOwnerAggregationSelection>;
  sphereUserOwnerConnection: SphereSphereUserOwnerConnection;
  sphereUserOwnerId: Scalars['ID']['output'];
};


export type SphereSpherePersonsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type SphereSpherePersonsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type SphereSpherePersonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SphereSpherePersonsConnectionSort>>;
  where?: InputMaybe<SphereSpherePersonsConnectionWhere>;
};


export type SphereSphereUserOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


export type SphereSphereUserOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type SphereSphereUserOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SphereSphereUserOwnerConnectionSort>>;
  where?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
};

export type SphereAggregateSelection = {
  __typename?: 'SphereAggregateSelection';
  count: Scalars['Int']['output'];
  sphereColor: StringAggregateSelection;
  sphereCreatedAt: StringAggregateSelection;
  sphereId: IdAggregateSelection;
  sphereName: StringAggregateSelection;
  sphereUpdatedAt: StringAggregateSelection;
  sphereUserOwnerId: IdAggregateSelection;
};

export type SphereConnectInput = {
  spherePersons?: InputMaybe<Array<SphereSpherePersonsConnectFieldInput>>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerConnectFieldInput>;
};

export type SphereConnectOrCreateInput = {
  spherePersons?: InputMaybe<Array<SphereSpherePersonsConnectOrCreateFieldInput>>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerConnectOrCreateFieldInput>;
};

export type SphereConnectOrCreateWhere = {
  node: SphereUniqueWhere;
};

export type SphereConnectWhere = {
  node: SphereWhere;
};

export type SphereCreateInput = {
  sphereColor: Scalars['String']['input'];
  sphereCreatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereId: Scalars['ID']['input'];
  sphereName: Scalars['String']['input'];
  spherePersons?: InputMaybe<SphereSpherePersonsFieldInput>;
  sphereUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerFieldInput>;
  sphereUserOwnerId: Scalars['ID']['input'];
};

export type SphereDeleteInput = {
  spherePersons?: InputMaybe<Array<SphereSpherePersonsDeleteFieldInput>>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerDeleteFieldInput>;
};

export type SphereDisconnectInput = {
  spherePersons?: InputMaybe<Array<SphereSpherePersonsDisconnectFieldInput>>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerDisconnectFieldInput>;
};

export type SphereEdge = {
  __typename?: 'SphereEdge';
  cursor: Scalars['String']['output'];
  node: Sphere;
};

export type SphereOnCreateInput = {
  sphereColor: Scalars['String']['input'];
  sphereCreatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereId: Scalars['ID']['input'];
  sphereName: Scalars['String']['input'];
  sphereUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereUserOwnerId: Scalars['ID']['input'];
};

export type SphereOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more SphereSort objects to sort Spheres by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SphereSort>>;
};

export type SpherePersonSpherePersonsAggregationSelection = {
  __typename?: 'SpherePersonSpherePersonsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<SpherePersonSpherePersonsNodeAggregateSelection>;
};

export type SpherePersonSpherePersonsNodeAggregateSelection = {
  __typename?: 'SpherePersonSpherePersonsNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type SphereRelationInput = {
  spherePersons?: InputMaybe<Array<SphereSpherePersonsCreateFieldInput>>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerCreateFieldInput>;
};

/** Fields to sort Spheres by. The order in which sorts are applied is not guaranteed when specifying many fields in one SphereSort object. */
export type SphereSort = {
  sphereColor?: InputMaybe<SortDirection>;
  sphereCreatedAt?: InputMaybe<SortDirection>;
  sphereId?: InputMaybe<SortDirection>;
  sphereName?: InputMaybe<SortDirection>;
  sphereUpdatedAt?: InputMaybe<SortDirection>;
  sphereUserOwnerId?: InputMaybe<SortDirection>;
};

export type SphereSpherePersonsAggregateInput = {
  AND?: InputMaybe<Array<SphereSpherePersonsAggregateInput>>;
  NOT?: InputMaybe<SphereSpherePersonsAggregateInput>;
  OR?: InputMaybe<Array<SphereSpherePersonsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<SphereSpherePersonsNodeAggregationWhereInput>;
};

export type SphereSpherePersonsConnectFieldInput = {
  connect?: InputMaybe<Array<PersonConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type SphereSpherePersonsConnectOrCreateFieldInput = {
  onCreate: SphereSpherePersonsConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type SphereSpherePersonsConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type SphereSpherePersonsConnection = {
  __typename?: 'SphereSpherePersonsConnection';
  edges: Array<SphereSpherePersonsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SphereSpherePersonsConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type SphereSpherePersonsConnectionWhere = {
  AND?: InputMaybe<Array<SphereSpherePersonsConnectionWhere>>;
  NOT?: InputMaybe<SphereSpherePersonsConnectionWhere>;
  OR?: InputMaybe<Array<SphereSpherePersonsConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type SphereSpherePersonsCreateFieldInput = {
  node: PersonCreateInput;
};

export type SphereSpherePersonsDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<SphereSpherePersonsConnectionWhere>;
};

export type SphereSpherePersonsDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<SphereSpherePersonsConnectionWhere>;
};

export type SphereSpherePersonsFieldInput = {
  connect?: InputMaybe<Array<SphereSpherePersonsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SphereSpherePersonsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SphereSpherePersonsCreateFieldInput>>;
};

export type SphereSpherePersonsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SphereSpherePersonsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SphereSpherePersonsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SphereSpherePersonsNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type SphereSpherePersonsRelationship = {
  __typename?: 'SphereSpherePersonsRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type SphereSpherePersonsUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type SphereSpherePersonsUpdateFieldInput = {
  connect?: InputMaybe<Array<SphereSpherePersonsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SphereSpherePersonsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SphereSpherePersonsCreateFieldInput>>;
  delete?: InputMaybe<Array<SphereSpherePersonsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SphereSpherePersonsDisconnectFieldInput>>;
  update?: InputMaybe<SphereSpherePersonsUpdateConnectionInput>;
  where?: InputMaybe<SphereSpherePersonsConnectionWhere>;
};

export type SphereSphereUserOwnerAggregateInput = {
  AND?: InputMaybe<Array<SphereSphereUserOwnerAggregateInput>>;
  NOT?: InputMaybe<SphereSphereUserOwnerAggregateInput>;
  OR?: InputMaybe<Array<SphereSphereUserOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<SphereSphereUserOwnerNodeAggregationWhereInput>;
};

export type SphereSphereUserOwnerConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type SphereSphereUserOwnerConnectOrCreateFieldInput = {
  onCreate: SphereSphereUserOwnerConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type SphereSphereUserOwnerConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type SphereSphereUserOwnerConnection = {
  __typename?: 'SphereSphereUserOwnerConnection';
  edges: Array<SphereSphereUserOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SphereSphereUserOwnerConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type SphereSphereUserOwnerConnectionWhere = {
  AND?: InputMaybe<Array<SphereSphereUserOwnerConnectionWhere>>;
  NOT?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
  OR?: InputMaybe<Array<SphereSphereUserOwnerConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type SphereSphereUserOwnerCreateFieldInput = {
  node: UserCreateInput;
};

export type SphereSphereUserOwnerDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
};

export type SphereSphereUserOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
};

export type SphereSphereUserOwnerFieldInput = {
  connect?: InputMaybe<SphereSphereUserOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<SphereSphereUserOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<SphereSphereUserOwnerCreateFieldInput>;
};

export type SphereSphereUserOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SphereSphereUserOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SphereSphereUserOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SphereSphereUserOwnerNodeAggregationWhereInput>>;
  userAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type SphereSphereUserOwnerRelationship = {
  __typename?: 'SphereSphereUserOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type SphereSphereUserOwnerUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type SphereSphereUserOwnerUpdateFieldInput = {
  connect?: InputMaybe<SphereSphereUserOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<SphereSphereUserOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<SphereSphereUserOwnerCreateFieldInput>;
  delete?: InputMaybe<SphereSphereUserOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<SphereSphereUserOwnerDisconnectFieldInput>;
  update?: InputMaybe<SphereSphereUserOwnerUpdateConnectionInput>;
  where?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
};

export type SphereUniqueWhere = {
  sphereName?: InputMaybe<Scalars['String']['input']>;
};

export type SphereUpdateInput = {
  sphereColor?: InputMaybe<Scalars['String']['input']>;
  sphereCreatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereId?: InputMaybe<Scalars['ID']['input']>;
  sphereName?: InputMaybe<Scalars['String']['input']>;
  spherePersons?: InputMaybe<Array<SphereSpherePersonsUpdateFieldInput>>;
  sphereUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereUserOwner?: InputMaybe<SphereSphereUserOwnerUpdateFieldInput>;
  sphereUserOwnerId?: InputMaybe<Scalars['ID']['input']>;
};

export type SphereUserSphereUserOwnerAggregationSelection = {
  __typename?: 'SphereUserSphereUserOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<SphereUserSphereUserOwnerNodeAggregateSelection>;
};

export type SphereUserSphereUserOwnerNodeAggregateSelection = {
  __typename?: 'SphereUserSphereUserOwnerNodeAggregateSelection';
  userAreaCode: StringAggregateSelection;
  userBirthDate: StringAggregateSelection;
  userCountryCode: IntAggregateSelection;
  userEmail: StringAggregateSelection;
  userId: IdAggregateSelection;
  userIsoCode: StringAggregateSelection;
  userName: StringAggregateSelection;
  userPassword: StringAggregateSelection;
  userPhoneNumber: StringAggregateSelection;
};

export type SphereWhere = {
  AND?: InputMaybe<Array<SphereWhere>>;
  NOT?: InputMaybe<SphereWhere>;
  OR?: InputMaybe<Array<SphereWhere>>;
  sphereColor?: InputMaybe<Scalars['String']['input']>;
  sphereColor_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  sphereColor_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereColor_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  sphereColor_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereCreatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereCreatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  sphereCreatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereCreatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sphereCreatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereId?: InputMaybe<Scalars['ID']['input']>;
  sphereId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  sphereId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  sphereId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  sphereId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  sphereName?: InputMaybe<Scalars['String']['input']>;
  sphereName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  sphereName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereName_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  sphereName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  spherePersonsAggregate?: InputMaybe<SphereSpherePersonsAggregateInput>;
  /** Return Spheres where all of the related SphereSpherePersonsConnections match this filter */
  spherePersonsConnection_ALL?: InputMaybe<SphereSpherePersonsConnectionWhere>;
  /** Return Spheres where none of the related SphereSpherePersonsConnections match this filter */
  spherePersonsConnection_NONE?: InputMaybe<SphereSpherePersonsConnectionWhere>;
  /** Return Spheres where one of the related SphereSpherePersonsConnections match this filter */
  spherePersonsConnection_SINGLE?: InputMaybe<SphereSpherePersonsConnectionWhere>;
  /** Return Spheres where some of the related SphereSpherePersonsConnections match this filter */
  spherePersonsConnection_SOME?: InputMaybe<SphereSpherePersonsConnectionWhere>;
  /** Return Spheres where all of the related People match this filter */
  spherePersons_ALL?: InputMaybe<PersonWhere>;
  /** Return Spheres where none of the related People match this filter */
  spherePersons_NONE?: InputMaybe<PersonWhere>;
  /** Return Spheres where one of the related People match this filter */
  spherePersons_SINGLE?: InputMaybe<PersonWhere>;
  /** Return Spheres where some of the related People match this filter */
  spherePersons_SOME?: InputMaybe<PersonWhere>;
  sphereUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  sphereUpdatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  sphereUpdatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereUpdatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sphereUpdatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  sphereUserOwner?: InputMaybe<UserWhere>;
  sphereUserOwnerAggregate?: InputMaybe<SphereSphereUserOwnerAggregateInput>;
  sphereUserOwnerConnection?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
  sphereUserOwnerConnection_NOT?: InputMaybe<SphereSphereUserOwnerConnectionWhere>;
  sphereUserOwnerId?: InputMaybe<Scalars['ID']['input']>;
  sphereUserOwnerId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  sphereUserOwnerId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  sphereUserOwnerId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  sphereUserOwnerId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  sphereUserOwner_NOT?: InputMaybe<UserWhere>;
};

export type SpheresConnection = {
  __typename?: 'SpheresConnection';
  edges: Array<SphereEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StringAggregateSelection = {
  __typename?: 'StringAggregateSelection';
  longest?: Maybe<Scalars['String']['output']>;
  shortest?: Maybe<Scalars['String']['output']>;
};

export type Task = {
  __typename?: 'Task';
  taskCreatedAt?: Maybe<Scalars['String']['output']>;
  taskDescription?: Maybe<Scalars['String']['output']>;
  taskDueDate?: Maybe<Scalars['String']['output']>;
  taskId: Scalars['ID']['output'];
  taskName: Scalars['String']['output'];
  taskOwner: User;
  taskOwnerAggregate?: Maybe<TaskUserTaskOwnerAggregationSelection>;
  taskOwnerConnection: TaskTaskOwnerConnection;
  taskPerson: Array<Person>;
  taskPersonAggregate?: Maybe<TaskPersonTaskPersonAggregationSelection>;
  taskPersonConnection: TaskTaskPersonConnection;
  taskStatus: TaskStatus;
  taskUpdatedAt?: Maybe<Scalars['String']['output']>;
};


export type TaskTaskOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


export type TaskTaskOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type TaskTaskOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TaskTaskOwnerConnectionSort>>;
  where?: InputMaybe<TaskTaskOwnerConnectionWhere>;
};


export type TaskTaskPersonArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type TaskTaskPersonAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type TaskTaskPersonConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TaskTaskPersonConnectionSort>>;
  where?: InputMaybe<TaskTaskPersonConnectionWhere>;
};

export type TaskAggregateSelection = {
  __typename?: 'TaskAggregateSelection';
  count: Scalars['Int']['output'];
  taskCreatedAt: StringAggregateSelection;
  taskDescription: StringAggregateSelection;
  taskDueDate: StringAggregateSelection;
  taskId: IdAggregateSelection;
  taskName: StringAggregateSelection;
  taskUpdatedAt: StringAggregateSelection;
};

export type TaskConnectInput = {
  taskOwner?: InputMaybe<TaskTaskOwnerConnectFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonConnectFieldInput>>;
};

export type TaskConnectOrCreateInput = {
  taskOwner?: InputMaybe<TaskTaskOwnerConnectOrCreateFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonConnectOrCreateFieldInput>>;
};

export type TaskConnectWhere = {
  node: TaskWhere;
};

export type TaskCreateInput = {
  taskCreatedAt?: InputMaybe<Scalars['String']['input']>;
  taskDescription?: InputMaybe<Scalars['String']['input']>;
  taskDueDate?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
  taskName: Scalars['String']['input'];
  taskOwner?: InputMaybe<TaskTaskOwnerFieldInput>;
  taskPerson?: InputMaybe<TaskTaskPersonFieldInput>;
  taskStatus: TaskStatus;
  taskUpdatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type TaskDeleteInput = {
  taskOwner?: InputMaybe<TaskTaskOwnerDeleteFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonDeleteFieldInput>>;
};

export type TaskDisconnectInput = {
  taskOwner?: InputMaybe<TaskTaskOwnerDisconnectFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonDisconnectFieldInput>>;
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['String']['output'];
  node: Task;
};

export type TaskOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more TaskSort objects to sort Tasks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TaskSort>>;
};

export type TaskPersonTaskPersonAggregationSelection = {
  __typename?: 'TaskPersonTaskPersonAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TaskPersonTaskPersonNodeAggregateSelection>;
};

export type TaskPersonTaskPersonNodeAggregateSelection = {
  __typename?: 'TaskPersonTaskPersonNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type TaskRelationInput = {
  taskOwner?: InputMaybe<TaskTaskOwnerCreateFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonCreateFieldInput>>;
};

/** Fields to sort Tasks by. The order in which sorts are applied is not guaranteed when specifying many fields in one TaskSort object. */
export type TaskSort = {
  taskCreatedAt?: InputMaybe<SortDirection>;
  taskDescription?: InputMaybe<SortDirection>;
  taskDueDate?: InputMaybe<SortDirection>;
  taskId?: InputMaybe<SortDirection>;
  taskName?: InputMaybe<SortDirection>;
  taskStatus?: InputMaybe<SortDirection>;
  taskUpdatedAt?: InputMaybe<SortDirection>;
};

export enum TaskStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type TaskTaskOwnerAggregateInput = {
  AND?: InputMaybe<Array<TaskTaskOwnerAggregateInput>>;
  NOT?: InputMaybe<TaskTaskOwnerAggregateInput>;
  OR?: InputMaybe<Array<TaskTaskOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TaskTaskOwnerNodeAggregationWhereInput>;
};

export type TaskTaskOwnerConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type TaskTaskOwnerConnectOrCreateFieldInput = {
  onCreate: TaskTaskOwnerConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type TaskTaskOwnerConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type TaskTaskOwnerConnection = {
  __typename?: 'TaskTaskOwnerConnection';
  edges: Array<TaskTaskOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TaskTaskOwnerConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type TaskTaskOwnerConnectionWhere = {
  AND?: InputMaybe<Array<TaskTaskOwnerConnectionWhere>>;
  NOT?: InputMaybe<TaskTaskOwnerConnectionWhere>;
  OR?: InputMaybe<Array<TaskTaskOwnerConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type TaskTaskOwnerCreateFieldInput = {
  node: UserCreateInput;
};

export type TaskTaskOwnerDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TaskTaskOwnerConnectionWhere>;
};

export type TaskTaskOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TaskTaskOwnerConnectionWhere>;
};

export type TaskTaskOwnerFieldInput = {
  connect?: InputMaybe<TaskTaskOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<TaskTaskOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<TaskTaskOwnerCreateFieldInput>;
};

export type TaskTaskOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TaskTaskOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TaskTaskOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TaskTaskOwnerNodeAggregationWhereInput>>;
  userAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  userCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  userPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  userPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskTaskOwnerRelationship = {
  __typename?: 'TaskTaskOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type TaskTaskOwnerUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type TaskTaskOwnerUpdateFieldInput = {
  connect?: InputMaybe<TaskTaskOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<TaskTaskOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<TaskTaskOwnerCreateFieldInput>;
  delete?: InputMaybe<TaskTaskOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<TaskTaskOwnerDisconnectFieldInput>;
  update?: InputMaybe<TaskTaskOwnerUpdateConnectionInput>;
  where?: InputMaybe<TaskTaskOwnerConnectionWhere>;
};

export type TaskTaskPersonAggregateInput = {
  AND?: InputMaybe<Array<TaskTaskPersonAggregateInput>>;
  NOT?: InputMaybe<TaskTaskPersonAggregateInput>;
  OR?: InputMaybe<Array<TaskTaskPersonAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TaskTaskPersonNodeAggregationWhereInput>;
};

export type TaskTaskPersonConnectFieldInput = {
  connect?: InputMaybe<Array<PersonConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type TaskTaskPersonConnectOrCreateFieldInput = {
  onCreate: TaskTaskPersonConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type TaskTaskPersonConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type TaskTaskPersonConnection = {
  __typename?: 'TaskTaskPersonConnection';
  edges: Array<TaskTaskPersonRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TaskTaskPersonConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type TaskTaskPersonConnectionWhere = {
  AND?: InputMaybe<Array<TaskTaskPersonConnectionWhere>>;
  NOT?: InputMaybe<TaskTaskPersonConnectionWhere>;
  OR?: InputMaybe<Array<TaskTaskPersonConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type TaskTaskPersonCreateFieldInput = {
  node: PersonCreateInput;
};

export type TaskTaskPersonDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<TaskTaskPersonConnectionWhere>;
};

export type TaskTaskPersonDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<TaskTaskPersonConnectionWhere>;
};

export type TaskTaskPersonFieldInput = {
  connect?: InputMaybe<Array<TaskTaskPersonConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TaskTaskPersonConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TaskTaskPersonCreateFieldInput>>;
};

export type TaskTaskPersonNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TaskTaskPersonNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TaskTaskPersonNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TaskTaskPersonNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskTaskPersonRelationship = {
  __typename?: 'TaskTaskPersonRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type TaskTaskPersonUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type TaskTaskPersonUpdateFieldInput = {
  connect?: InputMaybe<Array<TaskTaskPersonConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TaskTaskPersonConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TaskTaskPersonCreateFieldInput>>;
  delete?: InputMaybe<Array<TaskTaskPersonDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TaskTaskPersonDisconnectFieldInput>>;
  update?: InputMaybe<TaskTaskPersonUpdateConnectionInput>;
  where?: InputMaybe<TaskTaskPersonConnectionWhere>;
};

export type TaskUpdateInput = {
  taskCreatedAt?: InputMaybe<Scalars['String']['input']>;
  taskDescription?: InputMaybe<Scalars['String']['input']>;
  taskDueDate?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  taskName?: InputMaybe<Scalars['String']['input']>;
  taskOwner?: InputMaybe<TaskTaskOwnerUpdateFieldInput>;
  taskPerson?: InputMaybe<Array<TaskTaskPersonUpdateFieldInput>>;
  taskStatus?: InputMaybe<TaskStatus>;
  taskUpdatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type TaskUserTaskOwnerAggregationSelection = {
  __typename?: 'TaskUserTaskOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TaskUserTaskOwnerNodeAggregateSelection>;
};

export type TaskUserTaskOwnerNodeAggregateSelection = {
  __typename?: 'TaskUserTaskOwnerNodeAggregateSelection';
  userAreaCode: StringAggregateSelection;
  userBirthDate: StringAggregateSelection;
  userCountryCode: IntAggregateSelection;
  userEmail: StringAggregateSelection;
  userId: IdAggregateSelection;
  userIsoCode: StringAggregateSelection;
  userName: StringAggregateSelection;
  userPassword: StringAggregateSelection;
  userPhoneNumber: StringAggregateSelection;
};

export type TaskWhere = {
  AND?: InputMaybe<Array<TaskWhere>>;
  NOT?: InputMaybe<TaskWhere>;
  OR?: InputMaybe<Array<TaskWhere>>;
  taskCreatedAt?: InputMaybe<Scalars['String']['input']>;
  taskCreatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taskCreatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskCreatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskCreatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskDescription?: InputMaybe<Scalars['String']['input']>;
  taskDescription_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taskDescription_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskDescription_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskDescription_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskDueDate?: InputMaybe<Scalars['String']['input']>;
  taskDueDate_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taskDueDate_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskDueDate_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskDueDate_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  taskId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  taskId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  taskId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  taskId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  taskName?: InputMaybe<Scalars['String']['input']>;
  taskName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taskName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskName_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  taskName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskOwner?: InputMaybe<UserWhere>;
  taskOwnerAggregate?: InputMaybe<TaskTaskOwnerAggregateInput>;
  taskOwnerConnection?: InputMaybe<TaskTaskOwnerConnectionWhere>;
  taskOwnerConnection_NOT?: InputMaybe<TaskTaskOwnerConnectionWhere>;
  taskOwner_NOT?: InputMaybe<UserWhere>;
  taskPersonAggregate?: InputMaybe<TaskTaskPersonAggregateInput>;
  /** Return Tasks where all of the related TaskTaskPersonConnections match this filter */
  taskPersonConnection_ALL?: InputMaybe<TaskTaskPersonConnectionWhere>;
  /** Return Tasks where none of the related TaskTaskPersonConnections match this filter */
  taskPersonConnection_NONE?: InputMaybe<TaskTaskPersonConnectionWhere>;
  /** Return Tasks where one of the related TaskTaskPersonConnections match this filter */
  taskPersonConnection_SINGLE?: InputMaybe<TaskTaskPersonConnectionWhere>;
  /** Return Tasks where some of the related TaskTaskPersonConnections match this filter */
  taskPersonConnection_SOME?: InputMaybe<TaskTaskPersonConnectionWhere>;
  /** Return Tasks where all of the related People match this filter */
  taskPerson_ALL?: InputMaybe<PersonWhere>;
  /** Return Tasks where none of the related People match this filter */
  taskPerson_NONE?: InputMaybe<PersonWhere>;
  /** Return Tasks where one of the related People match this filter */
  taskPerson_SINGLE?: InputMaybe<PersonWhere>;
  /** Return Tasks where some of the related People match this filter */
  taskPerson_SOME?: InputMaybe<PersonWhere>;
  taskStatus?: InputMaybe<TaskStatus>;
  taskStatus_IN?: InputMaybe<Array<TaskStatus>>;
  taskUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  taskUpdatedAt_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taskUpdatedAt_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taskUpdatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskUpdatedAt_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type TasksConnection = {
  __typename?: 'TasksConnection';
  edges: Array<TaskEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateAuthPayloadsMutationResponse = {
  __typename?: 'UpdateAuthPayloadsMutationResponse';
  authPayloads: Array<AuthPayload>;
  info: UpdateInfo;
};

export type UpdateEmailsMutationResponse = {
  __typename?: 'UpdateEmailsMutationResponse';
  emails: Array<Email>;
  info: UpdateInfo;
};

/** Information about the number of nodes and relationships created and deleted during an update mutation */
export type UpdateInfo = {
  __typename?: 'UpdateInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesCreated: Scalars['Int']['output'];
  nodesDeleted: Scalars['Int']['output'];
  relationshipsCreated: Scalars['Int']['output'];
  relationshipsDeleted: Scalars['Int']['output'];
};

export type UpdatePeopleMutationResponse = {
  __typename?: 'UpdatePeopleMutationResponse';
  info: UpdateInfo;
  people: Array<Person>;
};

export type UpdatePersonRelationsMutationResponse = {
  __typename?: 'UpdatePersonRelationsMutationResponse';
  info: UpdateInfo;
  personRelations: Array<PersonRelation>;
};

export type UpdatePhonesMutationResponse = {
  __typename?: 'UpdatePhonesMutationResponse';
  info: UpdateInfo;
  phones: Array<Phone>;
};

export type UpdatePlatformsMutationResponse = {
  __typename?: 'UpdatePlatformsMutationResponse';
  info: UpdateInfo;
  platforms: Array<Platform>;
};

export type UpdateSpheresMutationResponse = {
  __typename?: 'UpdateSpheresMutationResponse';
  info: UpdateInfo;
  spheres: Array<Sphere>;
};

export type UpdateTasksMutationResponse = {
  __typename?: 'UpdateTasksMutationResponse';
  info: UpdateInfo;
  tasks: Array<Task>;
};

export type UpdateUsersMutationResponse = {
  __typename?: 'UpdateUsersMutationResponse';
  info: UpdateInfo;
  users: Array<User>;
};

export type User = {
  __typename?: 'User';
  userAreaCode?: Maybe<Scalars['String']['output']>;
  userBirthDate: Scalars['String']['output'];
  userCountryCode?: Maybe<Scalars['Int']['output']>;
  userCreatedPeople: Array<Person>;
  userCreatedPeopleAggregate?: Maybe<UserPersonUserCreatedPeopleAggregationSelection>;
  userCreatedPeopleConnection: UserUserCreatedPeopleConnection;
  userEmail: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  userIsoCode?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
  userPassword: Scalars['String']['output'];
  userPhoneNumber?: Maybe<Scalars['String']['output']>;
  userPolicyAccepted: Scalars['Boolean']['output'];
  userSpheres: Array<Sphere>;
  userSpheresAggregate?: Maybe<UserSphereUserSpheresAggregationSelection>;
  userSpheresConnection: UserUserSpheresConnection;
  userTasks: Array<Task>;
  userTasksAggregate?: Maybe<UserTaskUserTasksAggregationSelection>;
  userTasksConnection: UserUserTasksConnection;
};


export type UserUserCreatedPeopleArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<PersonOptions>;
  where?: InputMaybe<PersonWhere>;
};


export type UserUserCreatedPeopleAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PersonWhere>;
};


export type UserUserCreatedPeopleConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserUserCreatedPeopleConnectionSort>>;
  where?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
};


export type UserUserSpheresArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<SphereOptions>;
  where?: InputMaybe<SphereWhere>;
};


export type UserUserSpheresAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SphereWhere>;
};


export type UserUserSpheresConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserUserSpheresConnectionSort>>;
  where?: InputMaybe<UserUserSpheresConnectionWhere>;
};


export type UserUserTasksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TaskOptions>;
  where?: InputMaybe<TaskWhere>;
};


export type UserUserTasksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TaskWhere>;
};


export type UserUserTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserUserTasksConnectionSort>>;
  where?: InputMaybe<UserUserTasksConnectionWhere>;
};

export type UserAggregateSelection = {
  __typename?: 'UserAggregateSelection';
  count: Scalars['Int']['output'];
  userAreaCode: StringAggregateSelection;
  userBirthDate: StringAggregateSelection;
  userCountryCode: IntAggregateSelection;
  userEmail: StringAggregateSelection;
  userId: IdAggregateSelection;
  userIsoCode: StringAggregateSelection;
  userName: StringAggregateSelection;
  userPassword: StringAggregateSelection;
  userPhoneNumber: StringAggregateSelection;
};

export type UserConnectInput = {
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleConnectFieldInput>>;
  userSpheres?: InputMaybe<Array<UserUserSpheresConnectFieldInput>>;
  userTasks?: InputMaybe<Array<UserUserTasksConnectFieldInput>>;
};

export type UserConnectOrCreateInput = {
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleConnectOrCreateFieldInput>>;
  userSpheres?: InputMaybe<Array<UserUserSpheresConnectOrCreateFieldInput>>;
};

export type UserConnectOrCreateWhere = {
  node: UserUniqueWhere;
};

export type UserConnectWhere = {
  node: UserWhere;
};

export type UserCreateInput = {
  userAreaCode?: InputMaybe<Scalars['String']['input']>;
  userBirthDate: Scalars['String']['input'];
  userCountryCode?: InputMaybe<Scalars['Int']['input']>;
  userCreatedPeople?: InputMaybe<UserUserCreatedPeopleFieldInput>;
  userEmail: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userIsoCode?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
  userPassword: Scalars['String']['input'];
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  userPolicyAccepted: Scalars['Boolean']['input'];
  userSpheres?: InputMaybe<UserUserSpheresFieldInput>;
  userTasks?: InputMaybe<UserUserTasksFieldInput>;
};

export type UserDeleteInput = {
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleDeleteFieldInput>>;
  userSpheres?: InputMaybe<Array<UserUserSpheresDeleteFieldInput>>;
  userTasks?: InputMaybe<Array<UserUserTasksDeleteFieldInput>>;
};

export type UserDisconnectInput = {
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleDisconnectFieldInput>>;
  userSpheres?: InputMaybe<Array<UserUserSpheresDisconnectFieldInput>>;
  userTasks?: InputMaybe<Array<UserUserTasksDisconnectFieldInput>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserOnCreateInput = {
  userAreaCode?: InputMaybe<Scalars['String']['input']>;
  userBirthDate: Scalars['String']['input'];
  userCountryCode?: InputMaybe<Scalars['Int']['input']>;
  userEmail: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userIsoCode?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
  userPassword: Scalars['String']['input'];
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  userPolicyAccepted: Scalars['Boolean']['input'];
};

export type UserOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more UserSort objects to sort Users by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserSort>>;
};

export type UserPersonUserCreatedPeopleAggregationSelection = {
  __typename?: 'UserPersonUserCreatedPeopleAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserPersonUserCreatedPeopleNodeAggregateSelection>;
};

export type UserPersonUserCreatedPeopleNodeAggregateSelection = {
  __typename?: 'UserPersonUserCreatedPeopleNodeAggregateSelection';
  personAreaCode: StringAggregateSelection;
  personBirthDate: StringAggregateSelection;
  personCountryCode: IntAggregateSelection;
  personCreatedAt: StringAggregateSelection;
  personCreatedById: IdAggregateSelection;
  personDescription: StringAggregateSelection;
  personId: IdAggregateSelection;
  personIsoCode: StringAggregateSelection;
  personJobTitle: StringAggregateSelection;
  personName: StringAggregateSelection;
  personPhoneNumber: StringAggregateSelection;
  personPrimaryEmail: StringAggregateSelection;
  personPrimaryPhone: StringAggregateSelection;
  personUpdatedAt: StringAggregateSelection;
};

export type UserRelationInput = {
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleCreateFieldInput>>;
  userSpheres?: InputMaybe<Array<UserUserSpheresCreateFieldInput>>;
  userTasks?: InputMaybe<Array<UserUserTasksCreateFieldInput>>;
};

/** Fields to sort Users by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserSort object. */
export type UserSort = {
  userAreaCode?: InputMaybe<SortDirection>;
  userBirthDate?: InputMaybe<SortDirection>;
  userCountryCode?: InputMaybe<SortDirection>;
  userEmail?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
  userIsoCode?: InputMaybe<SortDirection>;
  userName?: InputMaybe<SortDirection>;
  userPassword?: InputMaybe<SortDirection>;
  userPhoneNumber?: InputMaybe<SortDirection>;
  userPolicyAccepted?: InputMaybe<SortDirection>;
};

export type UserSphereUserSpheresAggregationSelection = {
  __typename?: 'UserSphereUserSpheresAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserSphereUserSpheresNodeAggregateSelection>;
};

export type UserSphereUserSpheresNodeAggregateSelection = {
  __typename?: 'UserSphereUserSpheresNodeAggregateSelection';
  sphereColor: StringAggregateSelection;
  sphereCreatedAt: StringAggregateSelection;
  sphereId: IdAggregateSelection;
  sphereName: StringAggregateSelection;
  sphereUpdatedAt: StringAggregateSelection;
  sphereUserOwnerId: IdAggregateSelection;
};

export type UserTaskUserTasksAggregationSelection = {
  __typename?: 'UserTaskUserTasksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserTaskUserTasksNodeAggregateSelection>;
};

export type UserTaskUserTasksNodeAggregateSelection = {
  __typename?: 'UserTaskUserTasksNodeAggregateSelection';
  taskCreatedAt: StringAggregateSelection;
  taskDescription: StringAggregateSelection;
  taskDueDate: StringAggregateSelection;
  taskId: IdAggregateSelection;
  taskName: StringAggregateSelection;
  taskUpdatedAt: StringAggregateSelection;
};

export type UserUniqueWhere = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  userAreaCode?: InputMaybe<Scalars['String']['input']>;
  userBirthDate?: InputMaybe<Scalars['String']['input']>;
  userCountryCode?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  userCreatedPeople?: InputMaybe<Array<UserUserCreatedPeopleUpdateFieldInput>>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userIsoCode?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  userPassword?: InputMaybe<Scalars['String']['input']>;
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  userPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  userSpheres?: InputMaybe<Array<UserUserSpheresUpdateFieldInput>>;
  userTasks?: InputMaybe<Array<UserUserTasksUpdateFieldInput>>;
};

export type UserUserCreatedPeopleAggregateInput = {
  AND?: InputMaybe<Array<UserUserCreatedPeopleAggregateInput>>;
  NOT?: InputMaybe<UserUserCreatedPeopleAggregateInput>;
  OR?: InputMaybe<Array<UserUserCreatedPeopleAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserUserCreatedPeopleNodeAggregationWhereInput>;
};

export type UserUserCreatedPeopleConnectFieldInput = {
  connect?: InputMaybe<Array<PersonConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<PersonConnectWhere>;
};

export type UserUserCreatedPeopleConnectOrCreateFieldInput = {
  onCreate: UserUserCreatedPeopleConnectOrCreateFieldInputOnCreate;
  where: PersonConnectOrCreateWhere;
};

export type UserUserCreatedPeopleConnectOrCreateFieldInputOnCreate = {
  node: PersonOnCreateInput;
};

export type UserUserCreatedPeopleConnection = {
  __typename?: 'UserUserCreatedPeopleConnection';
  edges: Array<UserUserCreatedPeopleRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserUserCreatedPeopleConnectionSort = {
  node?: InputMaybe<PersonSort>;
};

export type UserUserCreatedPeopleConnectionWhere = {
  AND?: InputMaybe<Array<UserUserCreatedPeopleConnectionWhere>>;
  NOT?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
  OR?: InputMaybe<Array<UserUserCreatedPeopleConnectionWhere>>;
  node?: InputMaybe<PersonWhere>;
};

export type UserUserCreatedPeopleCreateFieldInput = {
  node: PersonCreateInput;
};

export type UserUserCreatedPeopleDeleteFieldInput = {
  delete?: InputMaybe<PersonDeleteInput>;
  where?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
};

export type UserUserCreatedPeopleDisconnectFieldInput = {
  disconnect?: InputMaybe<PersonDisconnectInput>;
  where?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
};

export type UserUserCreatedPeopleFieldInput = {
  connect?: InputMaybe<Array<UserUserCreatedPeopleConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserUserCreatedPeopleConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserUserCreatedPeopleCreateFieldInput>>;
};

export type UserUserCreatedPeopleNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUserCreatedPeopleNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUserCreatedPeopleNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUserCreatedPeopleNodeAggregationWhereInput>>;
  personAreaCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personAreaCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personAreaCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personBirthDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personBirthDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCountryCode_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  personCountryCode_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personIsoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personIsoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personJobTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personJobTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personPrimaryPhone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  personUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  personUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserUserCreatedPeopleRelationship = {
  __typename?: 'UserUserCreatedPeopleRelationship';
  cursor: Scalars['String']['output'];
  node: Person;
};

export type UserUserCreatedPeopleUpdateConnectionInput = {
  node?: InputMaybe<PersonUpdateInput>;
};

export type UserUserCreatedPeopleUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUserCreatedPeopleConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserUserCreatedPeopleConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserUserCreatedPeopleCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUserCreatedPeopleDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUserCreatedPeopleDisconnectFieldInput>>;
  update?: InputMaybe<UserUserCreatedPeopleUpdateConnectionInput>;
  where?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
};

export type UserUserSpheresAggregateInput = {
  AND?: InputMaybe<Array<UserUserSpheresAggregateInput>>;
  NOT?: InputMaybe<UserUserSpheresAggregateInput>;
  OR?: InputMaybe<Array<UserUserSpheresAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserUserSpheresNodeAggregationWhereInput>;
};

export type UserUserSpheresConnectFieldInput = {
  connect?: InputMaybe<Array<SphereConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<SphereConnectWhere>;
};

export type UserUserSpheresConnectOrCreateFieldInput = {
  onCreate: UserUserSpheresConnectOrCreateFieldInputOnCreate;
  where: SphereConnectOrCreateWhere;
};

export type UserUserSpheresConnectOrCreateFieldInputOnCreate = {
  node: SphereOnCreateInput;
};

export type UserUserSpheresConnection = {
  __typename?: 'UserUserSpheresConnection';
  edges: Array<UserUserSpheresRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserUserSpheresConnectionSort = {
  node?: InputMaybe<SphereSort>;
};

export type UserUserSpheresConnectionWhere = {
  AND?: InputMaybe<Array<UserUserSpheresConnectionWhere>>;
  NOT?: InputMaybe<UserUserSpheresConnectionWhere>;
  OR?: InputMaybe<Array<UserUserSpheresConnectionWhere>>;
  node?: InputMaybe<SphereWhere>;
};

export type UserUserSpheresCreateFieldInput = {
  node: SphereCreateInput;
};

export type UserUserSpheresDeleteFieldInput = {
  delete?: InputMaybe<SphereDeleteInput>;
  where?: InputMaybe<UserUserSpheresConnectionWhere>;
};

export type UserUserSpheresDisconnectFieldInput = {
  disconnect?: InputMaybe<SphereDisconnectInput>;
  where?: InputMaybe<UserUserSpheresConnectionWhere>;
};

export type UserUserSpheresFieldInput = {
  connect?: InputMaybe<Array<UserUserSpheresConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserUserSpheresConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserUserSpheresCreateFieldInput>>;
};

export type UserUserSpheresNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUserSpheresNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUserSpheresNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUserSpheresNodeAggregationWhereInput>>;
  sphereColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  sphereUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserUserSpheresRelationship = {
  __typename?: 'UserUserSpheresRelationship';
  cursor: Scalars['String']['output'];
  node: Sphere;
};

export type UserUserSpheresUpdateConnectionInput = {
  node?: InputMaybe<SphereUpdateInput>;
};

export type UserUserSpheresUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUserSpheresConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserUserSpheresConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserUserSpheresCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUserSpheresDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUserSpheresDisconnectFieldInput>>;
  update?: InputMaybe<UserUserSpheresUpdateConnectionInput>;
  where?: InputMaybe<UserUserSpheresConnectionWhere>;
};

export type UserUserTasksAggregateInput = {
  AND?: InputMaybe<Array<UserUserTasksAggregateInput>>;
  NOT?: InputMaybe<UserUserTasksAggregateInput>;
  OR?: InputMaybe<Array<UserUserTasksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserUserTasksNodeAggregationWhereInput>;
};

export type UserUserTasksConnectFieldInput = {
  connect?: InputMaybe<Array<TaskConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TaskConnectWhere>;
};

export type UserUserTasksConnection = {
  __typename?: 'UserUserTasksConnection';
  edges: Array<UserUserTasksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserUserTasksConnectionSort = {
  node?: InputMaybe<TaskSort>;
};

export type UserUserTasksConnectionWhere = {
  AND?: InputMaybe<Array<UserUserTasksConnectionWhere>>;
  NOT?: InputMaybe<UserUserTasksConnectionWhere>;
  OR?: InputMaybe<Array<UserUserTasksConnectionWhere>>;
  node?: InputMaybe<TaskWhere>;
};

export type UserUserTasksCreateFieldInput = {
  node: TaskCreateInput;
};

export type UserUserTasksDeleteFieldInput = {
  delete?: InputMaybe<TaskDeleteInput>;
  where?: InputMaybe<UserUserTasksConnectionWhere>;
};

export type UserUserTasksDisconnectFieldInput = {
  disconnect?: InputMaybe<TaskDisconnectInput>;
  where?: InputMaybe<UserUserTasksConnectionWhere>;
};

export type UserUserTasksFieldInput = {
  connect?: InputMaybe<Array<UserUserTasksConnectFieldInput>>;
  create?: InputMaybe<Array<UserUserTasksCreateFieldInput>>;
};

export type UserUserTasksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUserTasksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUserTasksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUserTasksNodeAggregationWhereInput>>;
  taskCreatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskCreatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskCreatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskDueDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskDueDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taskUpdatedAt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserUserTasksRelationship = {
  __typename?: 'UserUserTasksRelationship';
  cursor: Scalars['String']['output'];
  node: Task;
};

export type UserUserTasksUpdateConnectionInput = {
  node?: InputMaybe<TaskUpdateInput>;
};

export type UserUserTasksUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUserTasksConnectFieldInput>>;
  create?: InputMaybe<Array<UserUserTasksCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUserTasksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUserTasksDisconnectFieldInput>>;
  update?: InputMaybe<UserUserTasksUpdateConnectionInput>;
  where?: InputMaybe<UserUserTasksConnectionWhere>;
};

export type UserWhere = {
  AND?: InputMaybe<Array<UserWhere>>;
  NOT?: InputMaybe<UserWhere>;
  OR?: InputMaybe<Array<UserWhere>>;
  userAreaCode?: InputMaybe<Scalars['String']['input']>;
  userAreaCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userAreaCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userAreaCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userAreaCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userBirthDate?: InputMaybe<Scalars['String']['input']>;
  userBirthDate_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userBirthDate_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userBirthDate_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  userBirthDate_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userCountryCode?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_GT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_GTE?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  userCountryCode_LT?: InputMaybe<Scalars['Int']['input']>;
  userCountryCode_LTE?: InputMaybe<Scalars['Int']['input']>;
  userCreatedPeopleAggregate?: InputMaybe<UserUserCreatedPeopleAggregateInput>;
  /** Return Users where all of the related UserUserCreatedPeopleConnections match this filter */
  userCreatedPeopleConnection_ALL?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
  /** Return Users where none of the related UserUserCreatedPeopleConnections match this filter */
  userCreatedPeopleConnection_NONE?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
  /** Return Users where one of the related UserUserCreatedPeopleConnections match this filter */
  userCreatedPeopleConnection_SINGLE?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
  /** Return Users where some of the related UserUserCreatedPeopleConnections match this filter */
  userCreatedPeopleConnection_SOME?: InputMaybe<UserUserCreatedPeopleConnectionWhere>;
  /** Return Users where all of the related People match this filter */
  userCreatedPeople_ALL?: InputMaybe<PersonWhere>;
  /** Return Users where none of the related People match this filter */
  userCreatedPeople_NONE?: InputMaybe<PersonWhere>;
  /** Return Users where one of the related People match this filter */
  userCreatedPeople_SINGLE?: InputMaybe<PersonWhere>;
  /** Return Users where some of the related People match this filter */
  userCreatedPeople_SOME?: InputMaybe<PersonWhere>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userEmail_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userEmail_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userEmail_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  userEmail_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  userId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  userId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  userIsoCode?: InputMaybe<Scalars['String']['input']>;
  userIsoCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userIsoCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userIsoCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIsoCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  userName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userName_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  userName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userPassword?: InputMaybe<Scalars['String']['input']>;
  userPassword_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userPassword_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userPassword_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  userPassword_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  userPhoneNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  userPhoneNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  userPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userPhoneNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  userPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  userSpheresAggregate?: InputMaybe<UserUserSpheresAggregateInput>;
  /** Return Users where all of the related UserUserSpheresConnections match this filter */
  userSpheresConnection_ALL?: InputMaybe<UserUserSpheresConnectionWhere>;
  /** Return Users where none of the related UserUserSpheresConnections match this filter */
  userSpheresConnection_NONE?: InputMaybe<UserUserSpheresConnectionWhere>;
  /** Return Users where one of the related UserUserSpheresConnections match this filter */
  userSpheresConnection_SINGLE?: InputMaybe<UserUserSpheresConnectionWhere>;
  /** Return Users where some of the related UserUserSpheresConnections match this filter */
  userSpheresConnection_SOME?: InputMaybe<UserUserSpheresConnectionWhere>;
  /** Return Users where all of the related Spheres match this filter */
  userSpheres_ALL?: InputMaybe<SphereWhere>;
  /** Return Users where none of the related Spheres match this filter */
  userSpheres_NONE?: InputMaybe<SphereWhere>;
  /** Return Users where one of the related Spheres match this filter */
  userSpheres_SINGLE?: InputMaybe<SphereWhere>;
  /** Return Users where some of the related Spheres match this filter */
  userSpheres_SOME?: InputMaybe<SphereWhere>;
  userTasksAggregate?: InputMaybe<UserUserTasksAggregateInput>;
  /** Return Users where all of the related UserUserTasksConnections match this filter */
  userTasksConnection_ALL?: InputMaybe<UserUserTasksConnectionWhere>;
  /** Return Users where none of the related UserUserTasksConnections match this filter */
  userTasksConnection_NONE?: InputMaybe<UserUserTasksConnectionWhere>;
  /** Return Users where one of the related UserUserTasksConnections match this filter */
  userTasksConnection_SINGLE?: InputMaybe<UserUserTasksConnectionWhere>;
  /** Return Users where some of the related UserUserTasksConnections match this filter */
  userTasksConnection_SOME?: InputMaybe<UserUserTasksConnectionWhere>;
  /** Return Users where all of the related Tasks match this filter */
  userTasks_ALL?: InputMaybe<TaskWhere>;
  /** Return Users where none of the related Tasks match this filter */
  userTasks_NONE?: InputMaybe<TaskWhere>;
  /** Return Users where one of the related Tasks match this filter */
  userTasks_SINGLE?: InputMaybe<TaskWhere>;
  /** Return Users where some of the related Tasks match this filter */
  userTasks_SOME?: InputMaybe<TaskWhere>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  specifiedByURL?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

export type GetTaskStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskStatusQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null } | null };

export type GetPersonTasksQueryVariables = Exact<{
  where?: InputMaybe<TaskWhere>;
  options?: InputMaybe<TaskOptions>;
}>;


export type GetPersonTasksQuery = { __typename?: 'Query', tasks: Array<{ __typename?: 'Task', taskId: string, taskName: string, taskDescription?: string | null, taskCreatedAt?: string | null, taskUpdatedAt?: string | null, taskStatus: TaskStatus, taskDueDate?: string | null }> };

export type CreateTasksMutationVariables = Exact<{
  input: Array<TaskCreateInput> | TaskCreateInput;
}>;


export type CreateTasksMutation = { __typename?: 'Mutation', createTasks: { __typename?: 'CreateTasksMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, tasks: Array<{ __typename?: 'Task', taskId: string, taskName: string, taskStatus: TaskStatus }> } };

export type UpdateTasksMutationVariables = Exact<{
  where?: InputMaybe<TaskWhere>;
  update?: InputMaybe<TaskUpdateInput>;
}>;


export type UpdateTasksMutation = { __typename?: 'Mutation', updateTasks: { __typename?: 'UpdateTasksMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, tasks: Array<{ __typename?: 'Task', taskId: string, taskName: string, taskStatus: TaskStatus }> } };

export type GetEmailTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailTypesQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null } | null };

export type GetEmailsQueryVariables = Exact<{
  where?: InputMaybe<EmailWhere>;
  options?: InputMaybe<EmailOptions>;
}>;


export type GetEmailsQuery = { __typename?: 'Query', emails: Array<{ __typename?: 'Email', emailId: string, emailType: EmailType, emailAddress: string, emailOwner: { __typename?: 'Person', personId: string, personName: string } }> };

export type CreateEmailsMutationVariables = Exact<{
  input: Array<EmailCreateInput> | EmailCreateInput;
}>;


export type CreateEmailsMutation = { __typename?: 'Mutation', createEmails: { __typename?: 'CreateEmailsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, emails: Array<{ __typename?: 'Email', emailId: string, emailType: EmailType, emailAddress: string, emailOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type UpdateEmailsMutationVariables = Exact<{
  where?: InputMaybe<EmailWhere>;
  update?: InputMaybe<EmailUpdateInput>;
}>;


export type UpdateEmailsMutation = { __typename?: 'Mutation', updateEmails: { __typename?: 'UpdateEmailsMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, emails: Array<{ __typename?: 'Email', emailId: string, emailType: EmailType, emailAddress: string, emailOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type DeleteEmailsMutationVariables = Exact<{
  where?: InputMaybe<EmailWhere>;
}>;


export type DeleteEmailsMutation = { __typename?: 'Mutation', deleteEmails: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type GetPeopleQueryVariables = Exact<{
  where?: InputMaybe<PersonWhere>;
  options?: InputMaybe<PersonOptions>;
}>;


export type GetPeopleQuery = { __typename?: 'Query', people: Array<{ __typename?: 'Person', personCreatedAt?: string | null, personBirthDate?: string | null, personPrimaryEmail: string, personId: string, personDescription?: string | null, personName: string, personJobTitle?: string | null, personUpdatedAt?: string | null, personAreaCode?: string | null, personCountryCode?: number | null, personIsoCode?: string | null, personPhoneNumber?: string | null, personPrimaryPhone: string, personCreatedBy: { __typename?: 'User', userId: string, userName: string }, personSpheres: Array<{ __typename?: 'Sphere', sphereId: string, sphereColor: string, sphereName: string, sphereUserOwner: { __typename?: 'User', userId: string, userName: string } }>, personPlatforms: Array<{ __typename?: 'Platform', platformId: string, platformName: SocialMediaPlatform, platformUrl?: string | null, platformFavorite?: boolean | null }>, personPhones: Array<{ __typename?: 'Phone', phoneId: string, phoneNumber: string, phoneType: PhoneNumberType }>, personEmails: Array<{ __typename?: 'Email', emailId: string, emailType: EmailType, emailAddress: string }> }> };

export type CreatePeopleMutationVariables = Exact<{
  input: Array<PersonCreateInput> | PersonCreateInput;
}>;


export type CreatePeopleMutation = { __typename?: 'Mutation', createPeople: { __typename?: 'CreatePeopleMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, people: Array<{ __typename?: 'Person', personCreatedAt?: string | null, personBirthDate?: string | null, personPrimaryEmail: string, personId: string, personDescription?: string | null, personName: string, personUpdatedAt?: string | null, personAreaCode?: string | null, personCountryCode?: number | null, personIsoCode?: string | null, personPhoneNumber?: string | null, personCreatedBy: { __typename?: 'User', userId: string, userName: string } }> } };

export type UpdatePeopleMutationVariables = Exact<{
  where?: InputMaybe<PersonWhere>;
  update?: InputMaybe<PersonUpdateInput>;
}>;


export type UpdatePeopleMutation = { __typename?: 'Mutation', updatePeople: { __typename?: 'UpdatePeopleMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, people: Array<{ __typename?: 'Person', personCreatedAt?: string | null, personBirthDate?: string | null, personPrimaryEmail: string, personId: string, personDescription?: string | null, personName: string, personUpdatedAt?: string | null, personAreaCode?: string | null, personCountryCode?: number | null, personIsoCode?: string | null, personPhoneNumber?: string | null, personCreatedBy: { __typename?: 'User', userId: string, userName: string } }> } };

export type DeletePeopleMutationVariables = Exact<{
  where?: InputMaybe<PersonWhere>;
}>;


export type DeletePeopleMutation = { __typename?: 'Mutation', deletePeople: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type GetPhoneTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhoneTypesQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null } | null };

export type GetPhonesQueryVariables = Exact<{
  where?: InputMaybe<PhoneWhere>;
  options?: InputMaybe<PhoneOptions>;
}>;


export type GetPhonesQuery = { __typename?: 'Query', phones: Array<{ __typename?: 'Phone', phoneId: string, phoneNumber: string, phoneType: PhoneNumberType, phoneOwner: { __typename?: 'Person', personId: string, personName: string } }> };

export type CreatePhonesMutationVariables = Exact<{
  input: Array<PhoneCreateInput> | PhoneCreateInput;
}>;


export type CreatePhonesMutation = { __typename?: 'Mutation', createPhones: { __typename?: 'CreatePhonesMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, phones: Array<{ __typename?: 'Phone', phoneId: string, phoneNumber: string, phoneType: PhoneNumberType, phoneOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type UpdatePhonesMutationVariables = Exact<{
  where?: InputMaybe<PhoneWhere>;
  update?: InputMaybe<PhoneUpdateInput>;
}>;


export type UpdatePhonesMutation = { __typename?: 'Mutation', updatePhones: { __typename?: 'UpdatePhonesMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, phones: Array<{ __typename?: 'Phone', phoneId: string, phoneNumber: string, phoneType: PhoneNumberType, phoneOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type DeletePhonesMutationVariables = Exact<{
  where?: InputMaybe<PhoneWhere>;
}>;


export type DeletePhonesMutation = { __typename?: 'Mutation', deletePhones: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type GetSocialMediaPlatformsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSocialMediaPlatformsQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', enumValues?: Array<{ __typename?: '__EnumValue', name: string }> | null } | null };

export type GetPlatformQueryVariables = Exact<{
  where?: InputMaybe<PlatformWhere>;
  options?: InputMaybe<PlatformOptions>;
}>;


export type GetPlatformQuery = { __typename?: 'Query', platforms: Array<{ __typename?: 'Platform', platformId: string, platformName: SocialMediaPlatform, platformUrl?: string | null, platformFavorite?: boolean | null, platformOwner: { __typename?: 'Person', personId: string, personName: string } }> };

export type CreatePlatformMutationVariables = Exact<{
  input: Array<PlatformCreateInput> | PlatformCreateInput;
}>;


export type CreatePlatformMutation = { __typename?: 'Mutation', createPlatforms: { __typename?: 'CreatePlatformsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, platforms: Array<{ __typename?: 'Platform', platformId: string, platformName: SocialMediaPlatform, platformUrl?: string | null, platformFavorite?: boolean | null, platformOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type UpdatePlatformMutationVariables = Exact<{
  where?: InputMaybe<PlatformWhere>;
  update?: InputMaybe<PlatformUpdateInput>;
}>;


export type UpdatePlatformMutation = { __typename?: 'Mutation', updatePlatforms: { __typename?: 'UpdatePlatformsMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, platforms: Array<{ __typename?: 'Platform', platformId: string, platformName: SocialMediaPlatform, platformUrl?: string | null, platformFavorite?: boolean | null, platformOwner: { __typename?: 'Person', personId: string, personName: string } }> } };

export type DeletePlatformMutationVariables = Exact<{
  where?: InputMaybe<PlatformWhere>;
}>;


export type DeletePlatformMutation = { __typename?: 'Mutation', deletePlatforms: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type GetSpheresQueryVariables = Exact<{
  where?: InputMaybe<SphereWhere>;
  options?: InputMaybe<SphereOptions>;
}>;


export type GetSpheresQuery = { __typename?: 'Query', spheres: Array<{ __typename?: 'Sphere', sphereId: string, sphereColor: string, sphereName: string, sphereCreatedAt?: string | null, sphereUpdatedAt?: string | null, sphereUserOwner: { __typename?: 'User', userId: string, userName: string }, spherePersons: Array<{ __typename?: 'Person', personCreatedAt?: string | null, personPrimaryEmail: string, personId: string, personDescription?: string | null, personName: string, personUpdatedAt?: string | null, personAreaCode?: string | null, personCountryCode?: number | null, personIsoCode?: string | null, personPhoneNumber?: string | null, personCreatedBy: { __typename?: 'User', userId: string, userName: string } }> }> };

export type CreateSpheresMutationVariables = Exact<{
  input: Array<SphereCreateInput> | SphereCreateInput;
}>;


export type CreateSpheresMutation = { __typename?: 'Mutation', createSpheres: { __typename?: 'CreateSpheresMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, spheres: Array<{ __typename?: 'Sphere', sphereId: string, sphereColor: string, sphereName: string, sphereCreatedAt?: string | null, sphereUpdatedAt?: string | null, sphereUserOwner: { __typename?: 'User', userId: string, userName: string }, spherePersons: Array<{ __typename?: 'Person', personName: string }> }> } };

export type UpdateSpheresMutationVariables = Exact<{
  where?: InputMaybe<SphereWhere>;
  update?: InputMaybe<SphereUpdateInput>;
  connect?: InputMaybe<SphereConnectInput>;
  disconnect?: InputMaybe<SphereDisconnectInput>;
  create?: InputMaybe<SphereRelationInput>;
  delete?: InputMaybe<SphereDeleteInput>;
}>;


export type UpdateSpheresMutation = { __typename?: 'Mutation', updateSpheres: { __typename?: 'UpdateSpheresMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, spheres: Array<{ __typename?: 'Sphere', sphereId: string, sphereColor: string, sphereName: string, sphereCreatedAt?: string | null, sphereUpdatedAt?: string | null, sphereUserOwner: { __typename?: 'User', userId: string, userName: string }, spherePersons: Array<{ __typename?: 'Person', personName: string }> }> } };

export type DeleteSpheresMutationVariables = Exact<{
  where?: InputMaybe<SphereWhere>;
  delete?: InputMaybe<SphereDeleteInput>;
}>;


export type DeleteSpheresMutation = { __typename?: 'Mutation', deleteSpheres: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type GetUsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhere>;
  options?: InputMaybe<UserOptions>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', userEmail: string, userId: string, userName: string, userAreaCode?: string | null, userCountryCode?: number | null, userIsoCode?: string | null, userPhoneNumber?: string | null, userBirthDate: string, userPassword: string, userPolicyAccepted: boolean }> };

export type GetUsersSpheresQueryVariables = Exact<{
  where?: InputMaybe<UserWhere>;
  options?: InputMaybe<UserOptions>;
}>;


export type GetUsersSpheresQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', userEmail: string, userId: string, userSpheres: Array<{ __typename?: 'Sphere', sphereId: string, sphereColor: string, sphereName: string, sphereCreatedAt?: string | null, sphereUpdatedAt?: string | null }> }> };

export type GetUsersTasksQueryVariables = Exact<{
  where?: InputMaybe<UserWhere>;
  options?: InputMaybe<UserOptions>;
}>;


export type GetUsersTasksQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', userId: string, userTasks: Array<{ __typename?: 'Task', taskId: string, taskName: string, taskDescription?: string | null, taskCreatedAt?: string | null, taskUpdatedAt?: string | null, taskStatus: TaskStatus, taskDueDate?: string | null, taskPerson: Array<{ __typename?: 'Person', personId: string, personName: string }> }> }> };

export type CreateUsersMutationVariables = Exact<{
  input: Array<UserCreateInput> | UserCreateInput;
}>;


export type CreateUsersMutation = { __typename?: 'Mutation', createUsers: { __typename?: 'CreateUsersMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, users: Array<{ __typename?: 'User', userEmail: string, userId: string, userName: string, userAreaCode?: string | null, userCountryCode?: number | null, userIsoCode?: string | null, userPhoneNumber?: string | null, userBirthDate: string, userPassword: string, userPolicyAccepted: boolean }> } };

export type UpdateUsersMutationVariables = Exact<{
  where?: InputMaybe<UserWhere>;
  update?: InputMaybe<UserUpdateInput>;
}>;


export type UpdateUsersMutation = { __typename?: 'Mutation', updateUsers: { __typename?: 'UpdateUsersMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, nodesDeleted: number, relationshipsCreated: number, relationshipsDeleted: number }, users: Array<{ __typename?: 'User', userEmail: string, userId: string, userName: string, userAreaCode?: string | null, userCountryCode?: number | null, userIsoCode?: string | null, userPhoneNumber?: string | null, userBirthDate: string, userPassword: string, userPolicyAccepted: boolean }> } };

export type DeleteUsersMutationVariables = Exact<{
  where?: InputMaybe<UserWhere>;
}>;


export type DeleteUsersMutation = { __typename?: 'Mutation', deleteUsers: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'AuthPayload', token?: string | null, user?: { __typename?: 'User', userId: string, userName: string } | null } | null };


export const GetTaskStatusDocument = gql`
    query GetTaskStatus {
  __type(name: "TaskStatus") {
    enumValues {
      name
    }
  }
}
    `;

/**
 * __useGetTaskStatusQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, options);
      }
export function useGetTaskStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, options);
        }
export function useGetTaskStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTaskStatusQuery, GetTaskStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTaskStatusQuery, GetTaskStatusQueryVariables>(GetTaskStatusDocument, options);
        }
export type GetTaskStatusQueryHookResult = ReturnType<typeof useGetTaskStatusQuery>;
export type GetTaskStatusLazyQueryHookResult = ReturnType<typeof useGetTaskStatusLazyQuery>;
export type GetTaskStatusSuspenseQueryHookResult = ReturnType<typeof useGetTaskStatusSuspenseQuery>;
export type GetTaskStatusQueryResult = Apollo.QueryResult<GetTaskStatusQuery, GetTaskStatusQueryVariables>;
export const GetPersonTasksDocument = gql`
    query GetPersonTasks($where: TaskWhere, $options: TaskOptions) {
  tasks(where: $where, options: $options) {
    taskId
    taskName
    taskDescription
    taskCreatedAt
    taskUpdatedAt
    taskStatus
    taskDueDate
  }
}
    `;

/**
 * __useGetPersonTasksQuery__
 *
 * To run a query within a React component, call `useGetPersonTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPersonTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetPersonTasksQuery, GetPersonTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonTasksQuery, GetPersonTasksQueryVariables>(GetPersonTasksDocument, options);
      }
export function useGetPersonTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonTasksQuery, GetPersonTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonTasksQuery, GetPersonTasksQueryVariables>(GetPersonTasksDocument, options);
        }
export function useGetPersonTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPersonTasksQuery, GetPersonTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPersonTasksQuery, GetPersonTasksQueryVariables>(GetPersonTasksDocument, options);
        }
export type GetPersonTasksQueryHookResult = ReturnType<typeof useGetPersonTasksQuery>;
export type GetPersonTasksLazyQueryHookResult = ReturnType<typeof useGetPersonTasksLazyQuery>;
export type GetPersonTasksSuspenseQueryHookResult = ReturnType<typeof useGetPersonTasksSuspenseQuery>;
export type GetPersonTasksQueryResult = Apollo.QueryResult<GetPersonTasksQuery, GetPersonTasksQueryVariables>;
export const CreateTasksDocument = gql`
    mutation CreateTasks($input: [TaskCreateInput!]!) {
  createTasks(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    tasks {
      taskId
      taskName
      taskStatus
    }
  }
}
    `;
export type CreateTasksMutationFn = Apollo.MutationFunction<CreateTasksMutation, CreateTasksMutationVariables>;

/**
 * __useCreateTasksMutation__
 *
 * To run a mutation, you first call `useCreateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTasksMutation, { data, loading, error }] = useCreateTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreateTasksMutation, CreateTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTasksMutation, CreateTasksMutationVariables>(CreateTasksDocument, options);
      }
export type CreateTasksMutationHookResult = ReturnType<typeof useCreateTasksMutation>;
export type CreateTasksMutationResult = Apollo.MutationResult<CreateTasksMutation>;
export type CreateTasksMutationOptions = Apollo.BaseMutationOptions<CreateTasksMutation, CreateTasksMutationVariables>;
export const UpdateTasksDocument = gql`
    mutation UpdateTasks($where: TaskWhere, $update: TaskUpdateInput) {
  updateTasks(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    tasks {
      taskId
      taskName
      taskStatus
    }
  }
}
    `;
export type UpdateTasksMutationFn = Apollo.MutationFunction<UpdateTasksMutation, UpdateTasksMutationVariables>;

/**
 * __useUpdateTasksMutation__
 *
 * To run a mutation, you first call `useUpdateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTasksMutation, { data, loading, error }] = useUpdateTasksMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTasksMutation, UpdateTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTasksMutation, UpdateTasksMutationVariables>(UpdateTasksDocument, options);
      }
export type UpdateTasksMutationHookResult = ReturnType<typeof useUpdateTasksMutation>;
export type UpdateTasksMutationResult = Apollo.MutationResult<UpdateTasksMutation>;
export type UpdateTasksMutationOptions = Apollo.BaseMutationOptions<UpdateTasksMutation, UpdateTasksMutationVariables>;
export const GetEmailTypesDocument = gql`
    query GetEmailTypes {
  __type(name: "EmailType") {
    enumValues {
      name
    }
  }
}
    `;

/**
 * __useGetEmailTypesQuery__
 *
 * To run a query within a React component, call `useGetEmailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailTypesQuery, GetEmailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailTypesQuery, GetEmailTypesQueryVariables>(GetEmailTypesDocument, options);
      }
export function useGetEmailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTypesQuery, GetEmailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailTypesQuery, GetEmailTypesQueryVariables>(GetEmailTypesDocument, options);
        }
export function useGetEmailTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmailTypesQuery, GetEmailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmailTypesQuery, GetEmailTypesQueryVariables>(GetEmailTypesDocument, options);
        }
export type GetEmailTypesQueryHookResult = ReturnType<typeof useGetEmailTypesQuery>;
export type GetEmailTypesLazyQueryHookResult = ReturnType<typeof useGetEmailTypesLazyQuery>;
export type GetEmailTypesSuspenseQueryHookResult = ReturnType<typeof useGetEmailTypesSuspenseQuery>;
export type GetEmailTypesQueryResult = Apollo.QueryResult<GetEmailTypesQuery, GetEmailTypesQueryVariables>;
export const GetEmailsDocument = gql`
    query getEmails($where: EmailWhere, $options: EmailOptions) {
  emails(where: $where, options: $options) {
    emailId
    emailType
    emailAddress
    emailOwner {
      personId
      personName
    }
  }
}
    `;

/**
 * __useGetEmailsQuery__
 *
 * To run a query within a React component, call `useGetEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options);
      }
export function useGetEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options);
        }
export function useGetEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options);
        }
export type GetEmailsQueryHookResult = ReturnType<typeof useGetEmailsQuery>;
export type GetEmailsLazyQueryHookResult = ReturnType<typeof useGetEmailsLazyQuery>;
export type GetEmailsSuspenseQueryHookResult = ReturnType<typeof useGetEmailsSuspenseQuery>;
export type GetEmailsQueryResult = Apollo.QueryResult<GetEmailsQuery, GetEmailsQueryVariables>;
export const CreateEmailsDocument = gql`
    mutation CreateEmails($input: [EmailCreateInput!]!) {
  createEmails(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    emails {
      emailId
      emailType
      emailAddress
      emailOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type CreateEmailsMutationFn = Apollo.MutationFunction<CreateEmailsMutation, CreateEmailsMutationVariables>;

/**
 * __useCreateEmailsMutation__
 *
 * To run a mutation, you first call `useCreateEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailsMutation, { data, loading, error }] = useCreateEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailsMutation, CreateEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailsMutation, CreateEmailsMutationVariables>(CreateEmailsDocument, options);
      }
export type CreateEmailsMutationHookResult = ReturnType<typeof useCreateEmailsMutation>;
export type CreateEmailsMutationResult = Apollo.MutationResult<CreateEmailsMutation>;
export type CreateEmailsMutationOptions = Apollo.BaseMutationOptions<CreateEmailsMutation, CreateEmailsMutationVariables>;
export const UpdateEmailsDocument = gql`
    mutation UpdateEmails($where: EmailWhere, $update: EmailUpdateInput) {
  updateEmails(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    emails {
      emailId
      emailType
      emailAddress
      emailOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type UpdateEmailsMutationFn = Apollo.MutationFunction<UpdateEmailsMutation, UpdateEmailsMutationVariables>;

/**
 * __useUpdateEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailsMutation, { data, loading, error }] = useUpdateEmailsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailsMutation, UpdateEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailsMutation, UpdateEmailsMutationVariables>(UpdateEmailsDocument, options);
      }
export type UpdateEmailsMutationHookResult = ReturnType<typeof useUpdateEmailsMutation>;
export type UpdateEmailsMutationResult = Apollo.MutationResult<UpdateEmailsMutation>;
export type UpdateEmailsMutationOptions = Apollo.BaseMutationOptions<UpdateEmailsMutation, UpdateEmailsMutationVariables>;
export const DeleteEmailsDocument = gql`
    mutation DeleteEmails($where: EmailWhere) {
  deleteEmails(where: $where) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteEmailsMutationFn = Apollo.MutationFunction<DeleteEmailsMutation, DeleteEmailsMutationVariables>;

/**
 * __useDeleteEmailsMutation__
 *
 * To run a mutation, you first call `useDeleteEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailsMutation, { data, loading, error }] = useDeleteEmailsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteEmailsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailsMutation, DeleteEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailsMutation, DeleteEmailsMutationVariables>(DeleteEmailsDocument, options);
      }
export type DeleteEmailsMutationHookResult = ReturnType<typeof useDeleteEmailsMutation>;
export type DeleteEmailsMutationResult = Apollo.MutationResult<DeleteEmailsMutation>;
export type DeleteEmailsMutationOptions = Apollo.BaseMutationOptions<DeleteEmailsMutation, DeleteEmailsMutationVariables>;
export const GetPeopleDocument = gql`
    query GetPeople($where: PersonWhere, $options: PersonOptions) {
  people(where: $where, options: $options) {
    personCreatedAt
    personCreatedBy {
      userId
      userName
    }
    personBirthDate
    personPrimaryEmail
    personId
    personDescription
    personName
    personJobTitle
    personUpdatedAt
    personAreaCode
    personCountryCode
    personIsoCode
    personPhoneNumber
    personPrimaryPhone
    personSpheres {
      sphereId
      sphereColor
      sphereName
      sphereUserOwner {
        userId
        userName
      }
    }
    personPlatforms {
      platformId
      platformName
      platformUrl
      platformFavorite
    }
    personPhones {
      phoneId
      phoneNumber
      phoneType
    }
    personEmails {
      emailId
      emailType
      emailAddress
    }
  }
}
    `;

/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPeopleQuery(baseOptions?: Apollo.QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
      }
export function useGetPeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
        }
export function useGetPeopleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
        }
export type GetPeopleQueryHookResult = ReturnType<typeof useGetPeopleQuery>;
export type GetPeopleLazyQueryHookResult = ReturnType<typeof useGetPeopleLazyQuery>;
export type GetPeopleSuspenseQueryHookResult = ReturnType<typeof useGetPeopleSuspenseQuery>;
export type GetPeopleQueryResult = Apollo.QueryResult<GetPeopleQuery, GetPeopleQueryVariables>;
export const CreatePeopleDocument = gql`
    mutation CreatePeople($input: [PersonCreateInput!]!) {
  createPeople(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    people {
      personCreatedAt
      personCreatedBy {
        userId
        userName
      }
      personBirthDate
      personPrimaryEmail
      personId
      personDescription
      personName
      personUpdatedAt
      personAreaCode
      personCountryCode
      personIsoCode
      personPhoneNumber
    }
  }
}
    `;
export type CreatePeopleMutationFn = Apollo.MutationFunction<CreatePeopleMutation, CreatePeopleMutationVariables>;

/**
 * __useCreatePeopleMutation__
 *
 * To run a mutation, you first call `useCreatePeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeopleMutation, { data, loading, error }] = useCreatePeopleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeopleMutation(baseOptions?: Apollo.MutationHookOptions<CreatePeopleMutation, CreatePeopleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePeopleMutation, CreatePeopleMutationVariables>(CreatePeopleDocument, options);
      }
export type CreatePeopleMutationHookResult = ReturnType<typeof useCreatePeopleMutation>;
export type CreatePeopleMutationResult = Apollo.MutationResult<CreatePeopleMutation>;
export type CreatePeopleMutationOptions = Apollo.BaseMutationOptions<CreatePeopleMutation, CreatePeopleMutationVariables>;
export const UpdatePeopleDocument = gql`
    mutation UpdatePeople($where: PersonWhere, $update: PersonUpdateInput) {
  updatePeople(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    people {
      personCreatedAt
      personCreatedBy {
        userId
        userName
      }
      personBirthDate
      personPrimaryEmail
      personId
      personDescription
      personName
      personUpdatedAt
      personAreaCode
      personCountryCode
      personIsoCode
      personPhoneNumber
    }
  }
}
    `;
export type UpdatePeopleMutationFn = Apollo.MutationFunction<UpdatePeopleMutation, UpdatePeopleMutationVariables>;

/**
 * __useUpdatePeopleMutation__
 *
 * To run a mutation, you first call `useUpdatePeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeopleMutation, { data, loading, error }] = useUpdatePeopleMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePeopleMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeopleMutation, UpdatePeopleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeopleMutation, UpdatePeopleMutationVariables>(UpdatePeopleDocument, options);
      }
export type UpdatePeopleMutationHookResult = ReturnType<typeof useUpdatePeopleMutation>;
export type UpdatePeopleMutationResult = Apollo.MutationResult<UpdatePeopleMutation>;
export type UpdatePeopleMutationOptions = Apollo.BaseMutationOptions<UpdatePeopleMutation, UpdatePeopleMutationVariables>;
export const DeletePeopleDocument = gql`
    mutation DeletePeople($where: PersonWhere) {
  deletePeople(where: $where) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeletePeopleMutationFn = Apollo.MutationFunction<DeletePeopleMutation, DeletePeopleMutationVariables>;

/**
 * __useDeletePeopleMutation__
 *
 * To run a mutation, you first call `useDeletePeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeopleMutation, { data, loading, error }] = useDeletePeopleMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePeopleMutation(baseOptions?: Apollo.MutationHookOptions<DeletePeopleMutation, DeletePeopleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePeopleMutation, DeletePeopleMutationVariables>(DeletePeopleDocument, options);
      }
export type DeletePeopleMutationHookResult = ReturnType<typeof useDeletePeopleMutation>;
export type DeletePeopleMutationResult = Apollo.MutationResult<DeletePeopleMutation>;
export type DeletePeopleMutationOptions = Apollo.BaseMutationOptions<DeletePeopleMutation, DeletePeopleMutationVariables>;
export const GetPhoneTypesDocument = gql`
    query GetPhoneTypes {
  __type(name: "PhoneNumberType") {
    enumValues {
      name
    }
  }
}
    `;

/**
 * __useGetPhoneTypesQuery__
 *
 * To run a query within a React component, call `useGetPhoneTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhoneTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>(GetPhoneTypesDocument, options);
      }
export function useGetPhoneTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>(GetPhoneTypesDocument, options);
        }
export function useGetPhoneTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>(GetPhoneTypesDocument, options);
        }
export type GetPhoneTypesQueryHookResult = ReturnType<typeof useGetPhoneTypesQuery>;
export type GetPhoneTypesLazyQueryHookResult = ReturnType<typeof useGetPhoneTypesLazyQuery>;
export type GetPhoneTypesSuspenseQueryHookResult = ReturnType<typeof useGetPhoneTypesSuspenseQuery>;
export type GetPhoneTypesQueryResult = Apollo.QueryResult<GetPhoneTypesQuery, GetPhoneTypesQueryVariables>;
export const GetPhonesDocument = gql`
    query getPhones($where: PhoneWhere, $options: PhoneOptions) {
  phones(where: $where, options: $options) {
    phoneId
    phoneNumber
    phoneType
    phoneOwner {
      personId
      personName
    }
  }
}
    `;

/**
 * __useGetPhonesQuery__
 *
 * To run a query within a React component, call `useGetPhonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhonesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPhonesQuery(baseOptions?: Apollo.QueryHookOptions<GetPhonesQuery, GetPhonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhonesQuery, GetPhonesQueryVariables>(GetPhonesDocument, options);
      }
export function useGetPhonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhonesQuery, GetPhonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhonesQuery, GetPhonesQueryVariables>(GetPhonesDocument, options);
        }
export function useGetPhonesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPhonesQuery, GetPhonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhonesQuery, GetPhonesQueryVariables>(GetPhonesDocument, options);
        }
export type GetPhonesQueryHookResult = ReturnType<typeof useGetPhonesQuery>;
export type GetPhonesLazyQueryHookResult = ReturnType<typeof useGetPhonesLazyQuery>;
export type GetPhonesSuspenseQueryHookResult = ReturnType<typeof useGetPhonesSuspenseQuery>;
export type GetPhonesQueryResult = Apollo.QueryResult<GetPhonesQuery, GetPhonesQueryVariables>;
export const CreatePhonesDocument = gql`
    mutation CreatePhones($input: [PhoneCreateInput!]!) {
  createPhones(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    phones {
      phoneId
      phoneNumber
      phoneType
      phoneOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type CreatePhonesMutationFn = Apollo.MutationFunction<CreatePhonesMutation, CreatePhonesMutationVariables>;

/**
 * __useCreatePhonesMutation__
 *
 * To run a mutation, you first call `useCreatePhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhonesMutation, { data, loading, error }] = useCreatePhonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePhonesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhonesMutation, CreatePhonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhonesMutation, CreatePhonesMutationVariables>(CreatePhonesDocument, options);
      }
export type CreatePhonesMutationHookResult = ReturnType<typeof useCreatePhonesMutation>;
export type CreatePhonesMutationResult = Apollo.MutationResult<CreatePhonesMutation>;
export type CreatePhonesMutationOptions = Apollo.BaseMutationOptions<CreatePhonesMutation, CreatePhonesMutationVariables>;
export const UpdatePhonesDocument = gql`
    mutation UpdatePhones($where: PhoneWhere, $update: PhoneUpdateInput) {
  updatePhones(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    phones {
      phoneId
      phoneNumber
      phoneType
      phoneOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type UpdatePhonesMutationFn = Apollo.MutationFunction<UpdatePhonesMutation, UpdatePhonesMutationVariables>;

/**
 * __useUpdatePhonesMutation__
 *
 * To run a mutation, you first call `useUpdatePhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhonesMutation, { data, loading, error }] = useUpdatePhonesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePhonesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhonesMutation, UpdatePhonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhonesMutation, UpdatePhonesMutationVariables>(UpdatePhonesDocument, options);
      }
export type UpdatePhonesMutationHookResult = ReturnType<typeof useUpdatePhonesMutation>;
export type UpdatePhonesMutationResult = Apollo.MutationResult<UpdatePhonesMutation>;
export type UpdatePhonesMutationOptions = Apollo.BaseMutationOptions<UpdatePhonesMutation, UpdatePhonesMutationVariables>;
export const DeletePhonesDocument = gql`
    mutation DeletePhones($where: PhoneWhere) {
  deletePhones(where: $where) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeletePhonesMutationFn = Apollo.MutationFunction<DeletePhonesMutation, DeletePhonesMutationVariables>;

/**
 * __useDeletePhonesMutation__
 *
 * To run a mutation, you first call `useDeletePhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhonesMutation, { data, loading, error }] = useDeletePhonesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePhonesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhonesMutation, DeletePhonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhonesMutation, DeletePhonesMutationVariables>(DeletePhonesDocument, options);
      }
export type DeletePhonesMutationHookResult = ReturnType<typeof useDeletePhonesMutation>;
export type DeletePhonesMutationResult = Apollo.MutationResult<DeletePhonesMutation>;
export type DeletePhonesMutationOptions = Apollo.BaseMutationOptions<DeletePhonesMutation, DeletePhonesMutationVariables>;
export const GetSocialMediaPlatformsDocument = gql`
    query GetSocialMediaPlatforms {
  __type(name: "SocialMediaPlatform") {
    enumValues {
      name
    }
  }
}
    `;

/**
 * __useGetSocialMediaPlatformsQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaPlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSocialMediaPlatformsQuery(baseOptions?: Apollo.QueryHookOptions<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>(GetSocialMediaPlatformsDocument, options);
      }
export function useGetSocialMediaPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>(GetSocialMediaPlatformsDocument, options);
        }
export function useGetSocialMediaPlatformsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>(GetSocialMediaPlatformsDocument, options);
        }
export type GetSocialMediaPlatformsQueryHookResult = ReturnType<typeof useGetSocialMediaPlatformsQuery>;
export type GetSocialMediaPlatformsLazyQueryHookResult = ReturnType<typeof useGetSocialMediaPlatformsLazyQuery>;
export type GetSocialMediaPlatformsSuspenseQueryHookResult = ReturnType<typeof useGetSocialMediaPlatformsSuspenseQuery>;
export type GetSocialMediaPlatformsQueryResult = Apollo.QueryResult<GetSocialMediaPlatformsQuery, GetSocialMediaPlatformsQueryVariables>;
export const GetPlatformDocument = gql`
    query getPlatform($where: PlatformWhere, $options: PlatformOptions) {
  platforms(where: $where, options: $options) {
    platformId
    platformName
    platformUrl
    platformFavorite
    platformOwner {
      personId
      personName
    }
  }
}
    `;

/**
 * __useGetPlatformQuery__
 *
 * To run a query within a React component, call `useGetPlatformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPlatformQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformQuery, GetPlatformQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlatformQuery, GetPlatformQueryVariables>(GetPlatformDocument, options);
      }
export function useGetPlatformLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformQuery, GetPlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlatformQuery, GetPlatformQueryVariables>(GetPlatformDocument, options);
        }
export function useGetPlatformSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPlatformQuery, GetPlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPlatformQuery, GetPlatformQueryVariables>(GetPlatformDocument, options);
        }
export type GetPlatformQueryHookResult = ReturnType<typeof useGetPlatformQuery>;
export type GetPlatformLazyQueryHookResult = ReturnType<typeof useGetPlatformLazyQuery>;
export type GetPlatformSuspenseQueryHookResult = ReturnType<typeof useGetPlatformSuspenseQuery>;
export type GetPlatformQueryResult = Apollo.QueryResult<GetPlatformQuery, GetPlatformQueryVariables>;
export const CreatePlatformDocument = gql`
    mutation CreatePlatform($input: [PlatformCreateInput!]!) {
  createPlatforms(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    platforms {
      platformId
      platformName
      platformUrl
      platformFavorite
      platformOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type CreatePlatformMutationFn = Apollo.MutationFunction<CreatePlatformMutation, CreatePlatformMutationVariables>;

/**
 * __useCreatePlatformMutation__
 *
 * To run a mutation, you first call `useCreatePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformMutation, { data, loading, error }] = useCreatePlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlatformMutation, CreatePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlatformMutation, CreatePlatformMutationVariables>(CreatePlatformDocument, options);
      }
export type CreatePlatformMutationHookResult = ReturnType<typeof useCreatePlatformMutation>;
export type CreatePlatformMutationResult = Apollo.MutationResult<CreatePlatformMutation>;
export type CreatePlatformMutationOptions = Apollo.BaseMutationOptions<CreatePlatformMutation, CreatePlatformMutationVariables>;
export const UpdatePlatformDocument = gql`
    mutation UpdatePlatform($where: PlatformWhere, $update: PlatformUpdateInput) {
  updatePlatforms(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    platforms {
      platformId
      platformName
      platformUrl
      platformFavorite
      platformOwner {
        personId
        personName
      }
    }
  }
}
    `;
export type UpdatePlatformMutationFn = Apollo.MutationFunction<UpdatePlatformMutation, UpdatePlatformMutationVariables>;

/**
 * __useUpdatePlatformMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformMutation, { data, loading, error }] = useUpdatePlatformMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePlatformMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlatformMutation, UpdatePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlatformMutation, UpdatePlatformMutationVariables>(UpdatePlatformDocument, options);
      }
export type UpdatePlatformMutationHookResult = ReturnType<typeof useUpdatePlatformMutation>;
export type UpdatePlatformMutationResult = Apollo.MutationResult<UpdatePlatformMutation>;
export type UpdatePlatformMutationOptions = Apollo.BaseMutationOptions<UpdatePlatformMutation, UpdatePlatformMutationVariables>;
export const DeletePlatformDocument = gql`
    mutation DeletePlatform($where: PlatformWhere) {
  deletePlatforms(where: $where) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeletePlatformMutationFn = Apollo.MutationFunction<DeletePlatformMutation, DeletePlatformMutationVariables>;

/**
 * __useDeletePlatformMutation__
 *
 * To run a mutation, you first call `useDeletePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlatformMutation, { data, loading, error }] = useDeletePlatformMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePlatformMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlatformMutation, DeletePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlatformMutation, DeletePlatformMutationVariables>(DeletePlatformDocument, options);
      }
export type DeletePlatformMutationHookResult = ReturnType<typeof useDeletePlatformMutation>;
export type DeletePlatformMutationResult = Apollo.MutationResult<DeletePlatformMutation>;
export type DeletePlatformMutationOptions = Apollo.BaseMutationOptions<DeletePlatformMutation, DeletePlatformMutationVariables>;
export const GetSpheresDocument = gql`
    query GetSpheres($where: SphereWhere, $options: SphereOptions) {
  spheres(where: $where, options: $options) {
    sphereId
    sphereColor
    sphereName
    sphereCreatedAt
    sphereUpdatedAt
    sphereUserOwner {
      userId
      userName
    }
    spherePersons {
      personCreatedAt
      personCreatedBy {
        userId
        userName
      }
      personPrimaryEmail
      personId
      personDescription
      personName
      personUpdatedAt
      personAreaCode
      personCountryCode
      personIsoCode
      personPhoneNumber
    }
  }
}
    `;

/**
 * __useGetSpheresQuery__
 *
 * To run a query within a React component, call `useGetSpheresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpheresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpheresQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSpheresQuery(baseOptions?: Apollo.QueryHookOptions<GetSpheresQuery, GetSpheresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpheresQuery, GetSpheresQueryVariables>(GetSpheresDocument, options);
      }
export function useGetSpheresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpheresQuery, GetSpheresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpheresQuery, GetSpheresQueryVariables>(GetSpheresDocument, options);
        }
export function useGetSpheresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpheresQuery, GetSpheresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpheresQuery, GetSpheresQueryVariables>(GetSpheresDocument, options);
        }
export type GetSpheresQueryHookResult = ReturnType<typeof useGetSpheresQuery>;
export type GetSpheresLazyQueryHookResult = ReturnType<typeof useGetSpheresLazyQuery>;
export type GetSpheresSuspenseQueryHookResult = ReturnType<typeof useGetSpheresSuspenseQuery>;
export type GetSpheresQueryResult = Apollo.QueryResult<GetSpheresQuery, GetSpheresQueryVariables>;
export const CreateSpheresDocument = gql`
    mutation CreateSpheres($input: [SphereCreateInput!]!) {
  createSpheres(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    spheres {
      sphereId
      sphereColor
      sphereName
      sphereCreatedAt
      sphereUpdatedAt
      sphereUserOwner {
        userId
        userName
      }
      spherePersons {
        personName
      }
    }
  }
}
    `;
export type CreateSpheresMutationFn = Apollo.MutationFunction<CreateSpheresMutation, CreateSpheresMutationVariables>;

/**
 * __useCreateSpheresMutation__
 *
 * To run a mutation, you first call `useCreateSpheresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpheresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpheresMutation, { data, loading, error }] = useCreateSpheresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpheresMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpheresMutation, CreateSpheresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpheresMutation, CreateSpheresMutationVariables>(CreateSpheresDocument, options);
      }
export type CreateSpheresMutationHookResult = ReturnType<typeof useCreateSpheresMutation>;
export type CreateSpheresMutationResult = Apollo.MutationResult<CreateSpheresMutation>;
export type CreateSpheresMutationOptions = Apollo.BaseMutationOptions<CreateSpheresMutation, CreateSpheresMutationVariables>;
export const UpdateSpheresDocument = gql`
    mutation UpdateSpheres($where: SphereWhere, $update: SphereUpdateInput, $connect: SphereConnectInput, $disconnect: SphereDisconnectInput, $create: SphereRelationInput, $delete: SphereDeleteInput) {
  updateSpheres(
    where: $where
    update: $update
    connect: $connect
    disconnect: $disconnect
    create: $create
    delete: $delete
  ) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    spheres {
      sphereId
      sphereColor
      sphereName
      sphereCreatedAt
      sphereUpdatedAt
      sphereUserOwner {
        userId
        userName
      }
      spherePersons {
        personName
      }
    }
  }
}
    `;
export type UpdateSpheresMutationFn = Apollo.MutationFunction<UpdateSpheresMutation, UpdateSpheresMutationVariables>;

/**
 * __useUpdateSpheresMutation__
 *
 * To run a mutation, you first call `useUpdateSpheresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpheresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpheresMutation, { data, loading, error }] = useUpdateSpheresMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *      connect: // value for 'connect'
 *      disconnect: // value for 'disconnect'
 *      create: // value for 'create'
 *      delete: // value for 'delete'
 *   },
 * });
 */
export function useUpdateSpheresMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpheresMutation, UpdateSpheresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpheresMutation, UpdateSpheresMutationVariables>(UpdateSpheresDocument, options);
      }
export type UpdateSpheresMutationHookResult = ReturnType<typeof useUpdateSpheresMutation>;
export type UpdateSpheresMutationResult = Apollo.MutationResult<UpdateSpheresMutation>;
export type UpdateSpheresMutationOptions = Apollo.BaseMutationOptions<UpdateSpheresMutation, UpdateSpheresMutationVariables>;
export const DeleteSpheresDocument = gql`
    mutation DeleteSpheres($where: SphereWhere, $delete: SphereDeleteInput) {
  deleteSpheres(where: $where, delete: $delete) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteSpheresMutationFn = Apollo.MutationFunction<DeleteSpheresMutation, DeleteSpheresMutationVariables>;

/**
 * __useDeleteSpheresMutation__
 *
 * To run a mutation, you first call `useDeleteSpheresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpheresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpheresMutation, { data, loading, error }] = useDeleteSpheresMutation({
 *   variables: {
 *      where: // value for 'where'
 *      delete: // value for 'delete'
 *   },
 * });
 */
export function useDeleteSpheresMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpheresMutation, DeleteSpheresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpheresMutation, DeleteSpheresMutationVariables>(DeleteSpheresDocument, options);
      }
export type DeleteSpheresMutationHookResult = ReturnType<typeof useDeleteSpheresMutation>;
export type DeleteSpheresMutationResult = Apollo.MutationResult<DeleteSpheresMutation>;
export type DeleteSpheresMutationOptions = Apollo.BaseMutationOptions<DeleteSpheresMutation, DeleteSpheresMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($where: UserWhere, $options: UserOptions) {
  users(where: $where, options: $options) {
    userEmail
    userId
    userName
    userAreaCode
    userCountryCode
    userIsoCode
    userPhoneNumber
    userBirthDate
    userPassword
    userPolicyAccepted
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUsersSpheresDocument = gql`
    query GetUsersSpheres($where: UserWhere, $options: UserOptions) {
  users(where: $where, options: $options) {
    userEmail
    userId
    userSpheres {
      sphereId
      sphereColor
      sphereName
      sphereCreatedAt
      sphereUpdatedAt
    }
  }
}
    `;

/**
 * __useGetUsersSpheresQuery__
 *
 * To run a query within a React component, call `useGetUsersSpheresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersSpheresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersSpheresQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUsersSpheresQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>(GetUsersSpheresDocument, options);
      }
export function useGetUsersSpheresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>(GetUsersSpheresDocument, options);
        }
export function useGetUsersSpheresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>(GetUsersSpheresDocument, options);
        }
export type GetUsersSpheresQueryHookResult = ReturnType<typeof useGetUsersSpheresQuery>;
export type GetUsersSpheresLazyQueryHookResult = ReturnType<typeof useGetUsersSpheresLazyQuery>;
export type GetUsersSpheresSuspenseQueryHookResult = ReturnType<typeof useGetUsersSpheresSuspenseQuery>;
export type GetUsersSpheresQueryResult = Apollo.QueryResult<GetUsersSpheresQuery, GetUsersSpheresQueryVariables>;
export const GetUsersTasksDocument = gql`
    query GetUsersTasks($where: UserWhere, $options: UserOptions) {
  users(where: $where, options: $options) {
    userId
    userTasks {
      taskId
      taskName
      taskDescription
      taskCreatedAt
      taskUpdatedAt
      taskStatus
      taskDueDate
      taskPerson {
        personId
        personName
      }
    }
  }
}
    `;

/**
 * __useGetUsersTasksQuery__
 *
 * To run a query within a React component, call `useGetUsersTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUsersTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersTasksQuery, GetUsersTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersTasksQuery, GetUsersTasksQueryVariables>(GetUsersTasksDocument, options);
      }
export function useGetUsersTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersTasksQuery, GetUsersTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersTasksQuery, GetUsersTasksQueryVariables>(GetUsersTasksDocument, options);
        }
export function useGetUsersTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersTasksQuery, GetUsersTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersTasksQuery, GetUsersTasksQueryVariables>(GetUsersTasksDocument, options);
        }
export type GetUsersTasksQueryHookResult = ReturnType<typeof useGetUsersTasksQuery>;
export type GetUsersTasksLazyQueryHookResult = ReturnType<typeof useGetUsersTasksLazyQuery>;
export type GetUsersTasksSuspenseQueryHookResult = ReturnType<typeof useGetUsersTasksSuspenseQuery>;
export type GetUsersTasksQueryResult = Apollo.QueryResult<GetUsersTasksQuery, GetUsersTasksQueryVariables>;
export const CreateUsersDocument = gql`
    mutation CreateUsers($input: [UserCreateInput!]!) {
  createUsers(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
    users {
      userEmail
      userId
      userName
      userAreaCode
      userCountryCode
      userIsoCode
      userPhoneNumber
      userBirthDate
      userPassword
      userPolicyAccepted
    }
  }
}
    `;
export type CreateUsersMutationFn = Apollo.MutationFunction<CreateUsersMutation, CreateUsersMutationVariables>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUsersMutation, CreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(CreateUsersDocument, options);
      }
export type CreateUsersMutationHookResult = ReturnType<typeof useCreateUsersMutation>;
export type CreateUsersMutationResult = Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<CreateUsersMutation, CreateUsersMutationVariables>;
export const UpdateUsersDocument = gql`
    mutation UpdateUsers($where: UserWhere, $update: UserUpdateInput) {
  updateUsers(where: $where, update: $update) {
    info {
      nodesCreated
      nodesDeleted
      relationshipsCreated
      relationshipsDeleted
    }
    users {
      userEmail
      userId
      userName
      userAreaCode
      userCountryCode
      userIsoCode
      userPhoneNumber
      userBirthDate
      userPassword
      userPolicyAccepted
    }
  }
}
    `;
export type UpdateUsersMutationFn = Apollo.MutationFunction<UpdateUsersMutation, UpdateUsersMutationVariables>;

/**
 * __useUpdateUsersMutation__
 *
 * To run a mutation, you first call `useUpdateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersMutation, { data, loading, error }] = useUpdateUsersMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersMutation, UpdateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersMutation, UpdateUsersMutationVariables>(UpdateUsersDocument, options);
      }
export type UpdateUsersMutationHookResult = ReturnType<typeof useUpdateUsersMutation>;
export type UpdateUsersMutationResult = Apollo.MutationResult<UpdateUsersMutation>;
export type UpdateUsersMutationOptions = Apollo.BaseMutationOptions<UpdateUsersMutation, UpdateUsersMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($where: UserWhere) {
  deleteUsers(where: $where) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      userId
      userName
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;