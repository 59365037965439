import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Popconfirm, Select } from "antd";
import React from "react";
import { PlatformInterface } from "../../../types/types";

const PlatformInputs = ({
  action,
  platforms,
  setPlatforms,
  platformList,
}: any) => {
  const handlePlatformChange = (
    id: string,
    field: keyof PlatformInterface,
    value: any
  ) => {
    const modifiedValue = (platform: any) => {
      if (!platform.newPlatform) {
        if (field === "modified" && value === "delete") {
          return "delete";
        } else {
          return "update";
        }
      } else if (
        platform.newPlatform &&
        field === "modified" &&
        value === "delete"
      ) {
        return "delete";
      }
    };
    setPlatforms((prevPlatforms: any) =>
      prevPlatforms.map((platform: PlatformInterface) =>
        platform.platformId === id
          ? {
              ...platform,
              [field]: value,
              modified: modifiedValue(platform),
            }
          : platform
      )
    );
  };

  const CheckboxComponent = ({ platform, className }: any) => {
    return (
      <Checkbox
        className={`platform-checkbox ${className}`}
        onChange={(e) => {
          handlePlatformChange(
            platform.platformId,
            "platformFavorite",
            e.target.checked
          );
        }}
        defaultChecked={action === "update" ? platform.platformFavorite : false}
      >
        Preferred
      </Checkbox>
    );
  };

  return (
    platformList?.__type?.enumValues &&
    platforms.filter(
      (platform: PlatformInterface) => platform?.modified !== "delete"
    ).length > 0 &&
    platforms
      .filter((platform: PlatformInterface) => platform?.modified !== "delete")
      .map((platform: PlatformInterface) => (
        <span className="platform-input-container" key={platform.platformId}>
          <Input
            className="platform-input"
            placeholder="Enter platform link"
            defaultValue={action === "update" ? platform.platformUrl : ""}
            onChange={(e) => {
              handlePlatformChange(
                platform.platformId,
                "platformUrl",
                e.target.value
              );
            }}
            addonBefore={
              <Select
                className="platform-select"
                placeholder="Select Platform"
                value={
                  action === "update" && platform.platformName
                    ? platform.platformName
                    : undefined
                }
                options={
                  platformList.__type?.enumValues
                    ? platformList.__type?.enumValues.map((media: any) => ({
                        value: media.name,
                        label: media.name,
                      }))
                    : []
                }
                onChange={(value) => {
                  handlePlatformChange(
                    platform.platformId,
                    "platformName",
                    value
                  );
                }}
              ></Select>
            }
            addonAfter={
              <CheckboxComponent platform={platform} className="desktop" />
            }
          />
          <CheckboxComponent platform={platform} className="mobile" />
          <Popconfirm
            title="Are you sure you want to delete this platform?"
            onConfirm={() =>
              handlePlatformChange(platform.platformId, "modified", "delete")
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </span>
      ))
  );
};

export default PlatformInputs;
