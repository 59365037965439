import { useMutation } from "@apollo/client";
import { Form, Input, Select, Button, Card } from "antd";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreateTasksDocument } from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";

const TaskForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { action } = useParams();
  const [form] = Form.useForm();
  const [createTasks] = useMutation(CreateTasksDocument);

  const handleFormSubmit = async (values: any) => {
    try {
      const createData = {
        ...values,
        taskId: uuidv4(),
        taskCreatedAt: dayjs().format("YYYY-MM-DD"),
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
        taskDueDate: dayjs().format("YYYY-MM-DD"),
        taskOwner: {
          connect: {
            where: {
              node: {
                userId: state.userId,
              },
            },
          },
        },
        taskPerson: {
          connect: {
            where: {
              node: {
                personId: state.personId,
              },
            },
          },
        },
      };

      await createTasks({
        variables: { input: { ...createData } },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          navigate(-1);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <>
      {" "}
      <div
        style={{
          width: "100%",
          background:
            "linear-gradient(145deg, rgba(144,144,144,1) 16%, rgba(109,109,109,1) 40%, rgba(114,114,114,0.9192051820728291) 72%, rgba(144,144,144,0.3981967787114846) 96%)",
          color: "white",
          padding: "16px 8px",
          margin: "0px 0px 16px 0px",
          borderRadius: "8px",
        }}
      >
        <h1>
          {action === "create"
            ? "Create Task".toUpperCase()
            : "Update Task".toUpperCase()}
        </h1>
      </div>
      <Button onClick={() => navigate(-1)} style={{ marginRight: "16px" }}>
        Cancel
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={{ maxWidth: "800px", width: "100%" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={{
              taskName: "",
              taskDescription: "",
              taskStatus: "PENDING",
            }}
          >
            <Form.Item
              label="Task Name"
              name="taskName"
              rules={[{ required: true, message: "Task Name is required" }]}
            >
              <Input placeholder="Enter task name" />
            </Form.Item>
            <Form.Item label="Task Description" name="taskDescription">
              <Input.TextArea placeholder="Enter task description" />
            </Form.Item>
            <Form.Item
              label="Task Status"
              name="taskStatus"
              rules={[{ required: true, message: "Task Status is required" }]}
            >
              <Select placeholder="Select a task status">
                {state?.taskStatuses &&
                  state?.taskStatuses.map((status: any) => (
                    <Select.Option key={status.name} value={status.name}>
                      {status.name.replace(/_/g, " ")}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default TaskForm;
