import { Layout } from "antd";
import logo from "../brijmore.png";
import { capitalizeWords } from "./hooks/formats";
import { getCookie } from "./hooks/cookie";
import { LogoutBtn } from "./reusable-components/LogoutBtn";

const { Header } = Layout;

const HeaderComponent = () => {
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const userName = userObj ? userObj.userName : null;
  const token = getCookie("token");

  return (
    <Header className="header">
      <img className="logo" src={logo} alt="Brijmore" />
      {token && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            zIndex: 1,
          }}
        >
          {userName && (
            <h3 className="title">Welcome {capitalizeWords(userName)}</h3>
          )}
          <LogoutBtn location="header" className="logout" />
        </div>
      )}
    </Header>
  );
};

export default HeaderComponent;
