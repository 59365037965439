import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Select } from "antd";
import React from "react";
import { handlePhoneFormat } from "../../../hooks/formats";
import PhoneInput from "antd-phone-input";
import { PhoneInterface } from "../../../types/types";

const PhoneInputs = ({ action, phones, setPhones, phoneTypes }: any) => {
  const handlePhoneChange = (
    id: string,
    field: keyof PhoneInterface,
    value: any
  ) => {
    const modifiedValue = (phone: any) => {
      if (!phone.newPhone) {
        if (field === "modified" && value === "delete") {
          return "delete";
        } else {
          return "update";
        }
      } else if (phone.newPhone && field === "modified" && value === "delete") {
        return "delete";
      }
    };
    setPhones((prevPhones: any) =>
      prevPhones.map((phone: PhoneInterface) =>
        phone.phoneId === id
          ? {
              ...phone,
              [field]: value,
              modified: modifiedValue(phone),
            }
          : phone
      )
    );
  };

  return (
    phoneTypes?.__type?.enumValues &&
    phones.filter((phone: PhoneInterface) => phone?.modified !== "delete")
      .length > 0 &&
    phones
      .filter((phone: PhoneInterface) => phone?.modified !== "delete")
      .map((phone: PhoneInterface) => {
        return (
          <span className="phone-input-container" key={phone.phoneId}>
            <Select
              className="phone-select"
              placeholder="Select Type"
              value={
                action === "update" && phone.phoneType
                  ? phone.phoneType
                  : undefined
              }
              options={
                phoneTypes.__type?.enumValues
                  ? phoneTypes.__type?.enumValues.map((media: any) => ({
                      value: media.name,
                      label: media.name,
                    }))
                  : []
              }
              onChange={(value) => {
                handlePhoneChange(phone.phoneId, "phoneType", value);
              }}
            ></Select>
            <PhoneInput
              className="phone-input"
              value={
                phone.phoneNumber
                  ? handlePhoneFormat(phone.phoneNumber)
                  : undefined
              }
              onChange={(value: any) => {
                handlePhoneChange(
                  phone.phoneId,
                  "phoneNumber",
                  `${value.isoCode}.${value.countryCode}.${value.areaCode}.${value.phoneNumber}`
                );
              }}
            />
            <Popconfirm
              title="Are you sure you want to delete this phone?"
              onConfirm={() => {
                handlePhoneChange(phone.phoneId, "modified", "delete");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" danger className="phone-delete-btn">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </span>
        );
      })
  );
};

export default PhoneInputs;
