import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Form,
  Input,
  Button,
  Modal,
  Popover,
  ColorPicker,
  GetProp,
} from "antd";
import {
  CreateSpheresDocument,
  DeleteSpheresDocument,
  UpdateSpheresDocument,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getCookie } from "../../hooks/cookie";
import { ColorPickerProps } from "antd/es/color-picker";

interface SphereFormProps {
  visible: boolean;
  onCancel: (param?: any) => void;
  action: "create" | "edit" | null;
  sphere?: { sphereId: string; sphereName: string; sphereColor: string } | null;
}

type Color = GetProp<ColorPickerProps, "value">;

const SphereForm: React.FC<SphereFormProps> = ({
  visible,
  onCancel,
  action,
  sphere,
}) => {
  const [form] = Form.useForm();
  const [createSphere] = useMutation(CreateSpheresDocument);
  const [updateSphere] = useMutation(UpdateSpheresDocument);
  const [deleteSphere] = useMutation(DeleteSpheresDocument);
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [colorHex, setColorHex] = useState<Color>(
    sphere?.sphereColor ? sphere?.sphereColor : "#1677ff"
  );

  const hexString = React.useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const useHandleActionSphere = async () => {
    try {
      const values = await form.validateFields();
      const dateTime = new Date().toISOString();

      if (action === "create") {
        const newSphere = await createSphere({
          variables: {
            input: {
              sphereName: values.name,
              sphereColor: hexString ? hexString : "#1677ff",
              sphereId: uuidv4(),
              sphereCreatedAt: dateTime,
              sphereUpdatedAt: dateTime,
              sphereUserOwnerId: loggedUserId,
              sphereUserOwner: {
                connect: {
                  where: {
                    node: {
                      userId: loggedUserId,
                    },
                  },
                },
              },
            },
          },
        });
        const newSphereObj = newSphere.data.createSpheres.spheres[0];
        if (newSphere.data.createSpheres.spheres[0].sphereId) {
          form.resetFields();
          onCancel({
            sphereId: newSphereObj.sphereId,
            sphereName: newSphereObj.sphereName,
            sphereColor: newSphereObj.sphereColor,
          });
        }
      } else if (action === "edit") {
        await updateSphere({
          variables: {
            where: {
              sphereId: sphere?.sphereId,
              sphereUserOwnerId: loggedUserId,
              sphereUserOwner: { userId: loggedUserId },
            },
            update: {
              sphereName: values.name,
              sphereColor: hexString ? hexString : sphere?.sphereColor,
              sphereUpdatedAt: dateTime,
            },
          },
        });
        form.resetFields();
        onCancel({
          sphereId: sphere?.sphereId,
          sphereName: values.name,
          sphereColor: hexString ? hexString : sphere?.sphereColor,
        });
      }
    } catch (error: any) {
      if (action === "create") console.error("Failed to create sphere:", error);
      if (action === "edit") console.error("Failed to update sphere:", error);
      if (error.graphQLErrors) {
        console.error(
          "[SPHERE FORM - GraphQL Error: ]" +
            error.graphQLErrors.map((e: any) => e.message).join(", ")
        );
        if (
          error.graphQLErrors[0].message.includes(
            "Constraint validation failed"
          )
        ) {
          setErrorMessage("Sphere already exists, please use another NAME");
        }
      }
      if (error.networkError) {
        console.error(
          "[SPHERE FORM - Network Error: ]" + error.networkError.message
        );
      }
    }
  };

  const handleDeleteSphere = async () => {
    try {
      await deleteSphere({
        variables: { where: { sphereId: sphere?.sphereId } },
      });
      onCancel("deleted");
    } catch (error) {
      console.error({ "error to delete sphere": error });
    }
  };

  return (
    <Modal open={visible} onCancel={() => onCancel(false)} footer={null}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ name: action === "edit" ? sphere?.sphereName : "" }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the name" }]}
        >
          <Input onChange={() => setErrorMessage("")} />
        </Form.Item>
        <Form.Item name="sphereColor" label="Sphere Color">
          <ColorPicker value={colorHex} onChange={setColorHex} />
          <span style={{ display: "none" }}>{hexString}</span>{" "}
          {/* Need span to display color in component, don't know why yet */}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Button type="primary" onClick={useHandleActionSphere}>
                {action === "create" ? "Create" : "Update"}
              </Button>
              <Button
                onClick={() => onCancel(true)}
                style={{ margin: "auto 8px" }}
              >
                Cancel
              </Button>
            </div>
            {action === "edit" && (
              <Popover
                content={
                  <Button
                    onClick={() => {
                      handleDeleteSphere();
                    }}
                  >
                    Delete
                  </Button>
                }
                title={
                  <>
                    <ExclamationCircleOutlined style={{ color: "orange" }} />{" "}
                    Are you sure?
                  </>
                }
              >
                <Button style={{ color: "red" }}>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Popover>
            )}
          </div>
        </Form.Item>
        {errorMessage && (
          <p style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
            {errorMessage}
          </p>
        )}
      </Form>
    </Modal>
  );
};

export default SphereForm;
