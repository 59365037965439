import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getCookie, removeCookie } from "./components/hooks/cookie";

/**
 * Apollo Client
 * @description Link to API with authorization permission
 */
const httpLink = createHttpLink({
  uri: "https://brijmore-api-ehdwdba8ffdfcchw.eastus-01.azurewebsites.net/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = getCookie("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  if (networkError && networkError.message.includes("401")) {
    window.location.href = "/login";
    removeCookie("token");
    removeCookie("user");
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export default client;
