import { PoweroffOutlined } from "@ant-design/icons";
import { removeCookie } from "../hooks/cookie";
import { Button } from "antd";

export const LogoutBtn = ({
  location,
  className,
}: {
  location: string;
  className: string;
}): JSX.Element => {
  const handleLogout = () => {
    removeCookie("user");
    removeCookie("token");
    window.location.href = "/login";
  };

  if (location === "header") {
    return (
      <Button
        className={className}
        onClick={() => handleLogout()}
        title="Logout"
      >
        <PoweroffOutlined />
      </Button>
    );
  }

  if (location === "navbar") {
    return (
      <Button
        className={className}
        type="text"
        onClick={() => {
          handleLogout();
        }}
      >
        Logout
      </Button>
    );
  }

  throw new Error("Invalid location");
};
