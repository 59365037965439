import { HeartFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  InputRef,
  Space,
  TableColumnType,
  Tooltip,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { tagColor } from "../../../hooks/visual";
import { renderPhone } from "../../../hooks/formats";
import { useSelectedSpheres } from "../../../../contexts/SelectedSpheresContext";
import { get } from "http";
import {
  useGetEmailTypesQuery,
  useGetPhoneTypesQuery,
  useGetUsersSpheresQuery,
} from "../../../../generated/graphql";
import { getCookie } from "../../../hooks/cookie";

type DataIndex = keyof SimplifiedPerson;

/**
 * Interface to display people in a simplified way because Person type includes too many information.
 */
interface SimplifiedPerson {
  key: number;
  personId: string;
  personName: string;
  personPrimaryEmail: string;
  personPrimaryPhone: string;
  personSpheres: any[];
  personPlatforms: any[];
  personPhones: any[];
  personEmails: any[];
  personBirthDate: string;
}

/**
 * Used by Ant Design Table component.
 * @returns List of columns to display in PeopleList and AllPeople components.
 * IMPORTANT TO NOTE:
 * - Columns are displayed in the order they are listed.
 * - Columns can be hidden or shown by changing the hidden property.
 * - If you want to add a new column, you must add it to the dataSource function present in each lists components (AllPeople.tsx and PeopleList.tsx).
 */
const { RangePicker } = DatePicker;

const useColumns = () => {
  const { addSphere } = useSelectedSpheres();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { data: phoneTypes } = useGetPhoneTypesQuery();
  const { data: emailTypes } = useGetEmailTypesQuery();
  const [columns, setColumns] = useState<TableColumnType<any>[]>([]);
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    data = {
      users: [
        { userSpheres: [{ sphereId: "", sphereName: "", sphereColor: "" }] },
      ],
    },
    refetch,
  } = useGetUsersSpheresQuery({
    variables: {
      where: { userId: loggedUserId },
    },
  });

  const handleAddSphere = (sphere: any) => {
    addSphere(sphere);
  };

  /**Used by list column filters:  getColumnSearchProps()*/
  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**List columns display filter */
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<SimplifiedPerson> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const checkIfEmailType = (email: string) => {
        let result = emailTypes?.__type?.enumValues
          ?.map((type: any) => type.name)
          .includes(email);
        return result;
      };
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          {dataIndex === "personBirthDate" ? (
            <>
              <DatePicker
                onChange={(date) => {
                  if (date) {
                    setSelectedKeys([date.format("YYYY-MM-DD") ?? ""]);
                  } else {
                    setSelectedKeys([]);
                  }
                }}
                style={{ margin: "8px 0" }}
              />
              <RangePicker
                allowEmpty={[true, true]}
                onChange={(dates) => {
                  if (dates) {
                    setSelectedKeys([
                      dates
                        .map((date) => date?.format("YYYY-MM-DD") ?? "")
                        .join(","),
                    ]);
                  } else {
                    setSelectedKeys([]);
                  }
                }}
                style={{ marginBottom: 8, display: "block" }}
              />
            </>
          ) : (
            <>
              {dataIndex === "personPhones" &&
                phoneTypes?.__type?.enumValues &&
                (phoneTypes?.__type?.enumValues !== null ||
                  phoneTypes?.__type?.enumValues !== undefined) && (
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", margin: "16px 0" }}
                    placeholder="Select Phone Types"
                    onChange={(value) => {
                      let currentKeys = [...selectedKeys][0]
                        ? ([...selectedKeys][0] as string).split(",")
                        : [];
                      if (currentKeys.length) {
                        const indexWithNumber = currentKeys.findIndex((item) =>
                          /\d/.test(item.toString())
                        );

                        if (indexWithNumber !== -1 && !value) {
                          currentKeys.filter((key) =>
                            /\d/.test(key.toString())
                          );
                          if (value) {
                            currentKeys.push(value[value.length - 1]);
                          }
                        } else {
                          currentKeys.push(value[value.length - 1]);
                        }
                        setSelectedKeys([currentKeys.join(",")]);
                      } else {
                        setSelectedKeys([value[value.length - 1]]);
                      }
                    }}
                    options={phoneTypes?.__type?.enumValues.map(
                      (type: any) => ({
                        label: type.name,
                        value: type.name,
                      })
                    )}
                  />
                )}
              {dataIndex === "personEmails" &&
                emailTypes?.__type?.enumValues &&
                (emailTypes?.__type?.enumValues !== null ||
                  emailTypes?.__type?.enumValues !== undefined) && (
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", margin: "16px 0" }}
                    placeholder="Select Email Types"
                    onChange={(value) => {
                      let currentKeys = [...selectedKeys][0]
                        ? ([...selectedKeys][0] as string).includes(",")
                          ? ([...selectedKeys][0] as string)?.split(",")
                          : [selectedKeys[0] as string]
                        : [];

                      if (currentKeys.length) {
                        const indexWithAt = currentKeys.findIndex(
                          (item) => !checkIfEmailType(item.toString())
                        );

                        if (indexWithAt !== -1 && !value) {
                          currentKeys.filter(
                            (key) => !checkIfEmailType(key.toString())
                          );
                        } else if (indexWithAt !== -1 && value) {
                          currentKeys
                            .filter((key) => !checkIfEmailType(key.toString()))
                            .push(value);
                        } else if (indexWithAt === -1 && value) {
                          currentKeys = value;
                        }
                        setSelectedKeys([currentKeys.join(",")]);
                      } else {
                        setSelectedKeys(value);
                      }
                    }}
                    options={emailTypes?.__type?.enumValues.map(
                      (type: any) => ({
                        label: type.name,
                        value: type.name,
                      })
                    )}
                  />
                )}
              {dataIndex === "personSpheres" && data?.users[0]?.userSpheres ? (
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%", margin: "16px 0" }}
                  placeholder="Select Email Types"
                  onChange={(value) => {
                    setSelectedKeys(value);
                  }}
                  options={data?.users[0]?.userSpheres
                    .slice()
                    .sort((a, b) => a.sphereName.localeCompare(b.sphereName))
                    .map((sphere: any) => ({
                      label: sphere.sphereName,
                      value: sphere.sphereName,
                    }))}
                />
              ) : (
                <Input
                  ref={searchInput}
                  placeholder={`Search ${dataIndex}`}
                  value={
                    dataIndex !== "personPhones" && dataIndex !== "personEmails"
                      ? selectedKeys[0]
                      : [...selectedKeys][0]
                      ? ([...selectedKeys][0] as string)
                          .split(",")
                          .filter((key) =>
                            dataIndex === "personPhones"
                              ? /\d/.test(key.toString())
                              : !checkIfEmailType(key.toString())
                          )
                      : ""
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      !value &&
                      dataIndex !== "personPhones" &&
                      dataIndex !== "personEmails"
                    ) {
                      setSelectedKeys([]);
                    } else if (
                      dataIndex === "personPhones" ||
                      dataIndex === "personEmails"
                    ) {
                      let currentKeys = [...selectedKeys][0]
                        ? ([...selectedKeys][0] as string).split(",")
                        : [];
                      let condition = (item: string) =>
                        dataIndex === "personPhones"
                          ? /\d/.test(item.toString())
                          : !checkIfEmailType(item.toString());
                      if (!value) {
                        if (currentKeys.length) {
                          setSelectedKeys([
                            currentKeys
                              .filter((key) => !condition(key))
                              .join(","),
                          ]);
                        } else {
                          setSelectedKeys([]);
                        }
                      } else if (value && currentKeys.length) {
                        const indexWithNumber = currentKeys.findIndex((item) =>
                          condition(item)
                        );

                        if (indexWithNumber !== -1) {
                          if (value) {
                            currentKeys[indexWithNumber] = value;
                          } else {
                            currentKeys.filter((key) => !condition(key));
                          }
                        } else {
                          currentKeys.push(value);
                        }
                        setSelectedKeys([currentKeys.join(",")]);
                      } else {
                        setSelectedKeys([value]);
                      }
                    } else {
                      setSelectedKeys([value]);
                    }
                  }}
                  onPressEnter={() =>
                    handleSearch(selectedKeys as string[], confirm, dataIndex)
                  }
                  style={{ marginBottom: 8, display: "block" }}
                />
              )}
            </>
          )}
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                  setSearchText("");
                  setSelectedKeys([]);
                  confirm();
                }
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const searchValue = (value as string).toLowerCase();

      if (dataIndex === "personBirthDate") {
        if ((value as string).split(",").length > 1) {
          const [start, end] = (value as string).split(",");
          const recordDate = dayjs(record[dataIndex]).valueOf();
          const startDate = start
            ? dayjs(start).valueOf()
            : dayjs("0000-00-00").valueOf();
          const endDate = end ? dayjs(end).valueOf() : dayjs().valueOf();
          return (recordDate >= startDate && recordDate <= endDate) ?? false;
        } else {
          const recordDate = dayjs(record[dataIndex]).valueOf();
          const searchDate = dayjs(value as string).valueOf();
          return recordDate === searchDate;
        }
      }

      if (dataIndex === "personSpheres") {
        return (
          record.personSpheres?.some((sphere: any) =>
            sphere.sphereName.toLowerCase().includes(searchValue)
          ) ?? false
        );
      }
      if (dataIndex === "personPlatforms") {
        return (
          record.personPlatforms?.some((platform: any) =>
            platform.platformName.toLowerCase().includes(searchValue)
          ) ?? false
        );
      }
      if (dataIndex === "personPrimaryPhone") {
        const sanitizedValue = (value as string)
          .replace(/\s/g, "")
          .replace(/[^0-9]/g, "");
        const sanitizedRecord = (record[dataIndex] as string)
          .replace(/\s/g, "")
          .replace(/[^0-9]/g, "");
        return sanitizedRecord.includes(sanitizedValue);
      }
      if (dataIndex === "personPhones") {
        const searchNumber = (value as string)
          .split(",")
          .filter((item) => /\d/.test(item))[0]
          ?.replace(/\s/g, "")
          .replace(/[^0-9]/g, "");
        const searchTypes = (value as string)
          .split(",")
          .filter((item) => !/\d/.test(item));
        return (
          record.personPhones?.some((phone: any) => {
            const personPhoneNumber = phone.phoneNumber
              .replace(/\s/g, "")
              .replace(/[^0-9]/g, "");

            if (searchNumber && searchTypes.length) {
              return (
                personPhoneNumber.includes(searchNumber) &&
                searchTypes.includes(phone.phoneType)
              );
            } else if (searchNumber && !searchTypes.length) {
              return personPhoneNumber.includes(searchNumber);
            } else if (!searchNumber && searchTypes.length) {
              return searchTypes.includes(phone.phoneType);
            }

            return false;
          }) ?? false
        );
      }
      if (dataIndex === "personEmails") {
        const checkIfEmailType = (email: string) => {
          let result = emailTypes?.__type?.enumValues
            ?.map((type: any) => type.name)
            .includes(email);
          return result;
        };
        const searchEmail = (value as string)
          .split(",")
          .filter((item) => !checkIfEmailType(item as string))[0];
        const searchTypes = (value as string)
          .split(",")
          .filter((item) => checkIfEmailType(item as string));
        return (
          record.personEmails?.some((email: any) => {
            const personEmailAddress = email.emailAddress;

            if (searchEmail && searchTypes.length) {
              return (
                personEmailAddress.includes(searchEmail) &&
                searchTypes.includes(email.emailType)
              );
            } else if (searchEmail && !searchTypes.length) {
              return personEmailAddress.includes(searchEmail);
            } else if (!searchEmail && searchTypes.length) {
              return searchTypes.includes(email.emailType);
            }

            return false;
          }) ?? false
        );
      } else {
        return (
          record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()) ?? false
        );
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  useEffect(() => {
    const list: TableColumnType<any>[] = [
      {
        title: "Name",
        fixed: "left",
        dataIndex: "personName",
        key: "personName",
        ...getColumnSearchProps("personName"),
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.personName.localeCompare(b.personName),
        width: "6vw",
      },
      {
        title: "Email",
        dataIndex: "personPrimaryEmail",
        key: "personPrimaryEmail",
        ...getColumnSearchProps("personPrimaryEmail"),
        sorter: (a, b) =>
          a.personPrimaryEmail.localeCompare(b.personPrimaryEmail),
        hidden: false,
        width: "10vw",
      },
      {
        title: "Phone",
        dataIndex: "personPrimaryPhone",
        key: "personPrimaryPhone",
        ...getColumnSearchProps("personPrimaryPhone"),
        sorter: (a, b) => {
          const sanitizedA = a.personPrimaryPhone
            .replace(/\s/g, "")
            .replace(/[^0-9]/g, "");
          const sanitizedB = b.personPrimaryPhone
            .replace(/\s/g, "")
            .replace(/[^0-9]/g, "");
          return sanitizedA.localeCompare(sanitizedB);
        },
        render: (text, record) => renderPhone(record.personPrimaryPhone),
        hidden: false,
        width: "10vw",
      },
      {
        title: "Spheres",
        key: "personSpheres",
        ...getColumnSearchProps("personSpheres"),
        render(value, record, index) {
          return (
            <Space
              size="middle"
              style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
            >
              {record.personSpheres ? (
                <>
                  {record.personSpheres.map((sphere: any) => {
                    return (
                      <Link
                        to={"/people-list"}
                        key={sphere.sphereId}
                        onClick={() => handleAddSphere(sphere)}
                      >
                        <span
                          className="tag"
                          style={tagColor(
                            sphere.sphereColor ? sphere.sphereColor : "#1677ff"
                          )}
                        >
                          {sphere.sphereName}
                        </span>
                      </Link>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </Space>
          );
        },
        hidden: false,
        width: "10vw",
      },
      {
        title: "Platforms",
        key: "personPlatforms",
        hidden: true,
        ...getColumnSearchProps("personPlatforms"),
        render(value, record, index) {
          return (
            <Space
              size="middle"
              style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
            >
              {record.personPlatforms ? (
                <>
                  {record.personPlatforms.map((platform: any) => {
                    return (
                      <Link
                        to={platform.platformUrl}
                        target="_blank"
                        key={platform.platformId}
                      >
                        <Button type="default">
                          <Tooltip title="Open link">
                            {platform.platformName}
                          </Tooltip>
                          {platform.platformFavorite ? (
                            <Tooltip title="Preferred way of communication">
                              <HeartFilled
                                style={{
                                  color: "#ff5d8f",
                                  fontSize: "20px",
                                  paddingLeft: "8px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Button>
                      </Link>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </Space>
          );
        },
        width: "10vw",
      },
      {
        title: "Other Phones",
        key: "personPhones",
        hidden: true,
        ...getColumnSearchProps("personPhones"),
        render(value, record, index) {
          return (
            <Space
              size="middle"
              style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
            >
              {record.personPhones ? (
                <>
                  {record.personPhones.map((phone: any) => {
                    return (
                      <Tooltip title="Call">
                        {phone.phoneType} {renderPhone(phone.phoneNumber)}
                      </Tooltip>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </Space>
          );
        },
        width: "10vw",
      },
      {
        title: "Other Emails",
        key: "personEmails",
        ...getColumnSearchProps("personEmails"),
        hidden: true,
        render(value, record, index) {
          return (
            <Space
              size="middle"
              style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
            >
              {record.personEmails ? (
                <>
                  {record.personEmails.map((email: any) => {
                    return (
                      <p>
                        {email.emailType} {email.emailAddress}
                      </p>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </Space>
          );
        },
        width: "10vw",
      },
      {
        title: "Birthdate",
        dataIndex: "personBirthDate",
        key: "personBirthDate",
        sorter: (a, b) => {
          const dateA = new Date(a.personBirthDate).getTime();
          const dateB = new Date(b.personBirthDate).getTime();
          return dateA - dateB;
        },
        ...getColumnSearchProps("personBirthDate"),
        hidden: true,
        width: "10vw",
      },
    ];
    setColumns(list);
  }, [phoneTypes, emailTypes, data]);
  return columns;
};

export default useColumns;
