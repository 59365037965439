import {
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Select,
  Space,
} from "antd";
import PhoneInput from "antd-phone-input";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../hooks/cookie";
import { useMutation } from "@apollo/client";
import {
  CreatePeopleDocument,
  CreateSpheresDocument,
  UpdateEmailsDocument,
  UpdatePeopleDocument,
  UpdatePhonesDocument,
  UpdatePlatformDocument,
  useGetEmailTypesQuery,
  useGetPhoneTypesQuery,
  useGetSocialMediaPlatformsQuery,
  useGetUsersSpheresQuery,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { PlusCircleOutlined } from "@ant-design/icons";
import { handlePhoneFormat } from "../../hooks/formats";
import {
  EmailInterface,
  PhoneInterface,
  PlatformInterface,
} from "../../types/types";
import PlatformInputs from "./form-components/PlatformInputs";
import PhoneInputs from "./form-components/PhoneInputs";
import EmailInputs from "./form-components/EmailInputs";

const PersonForm = () => {
  const navigate = useNavigate();
  const { action } = useParams<{ action: "create" | "update" }>();
  const { state } = useLocation(); // Can contain a list of sphere IDs, a person's ID
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const [personBirthDate, setPersonBirthDate] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedSphereIds, setSelectedSphereIds] = useState<string[]>([]);
  const [selectedSphereNames, setSelectedSphereNames] = useState<string[]>([]);
  const [platforms, setPlatforms] = useState<PlatformInterface[]>([]);
  const [phones, setPhones] = useState<PhoneInterface[]>([]);
  const [emails, setEmails] = useState<EmailInterface[]>([]);
  const [searchSphere, setSearchSphere] = useState<string | null>(null);
  const [createPerson, { loading: creating, error: createError }] =
    useMutation(CreatePeopleDocument);
  const [updatePerson, { loading: updating, error: updateError }] =
    useMutation(UpdatePeopleDocument);
  const [createSphere] = useMutation(CreateSpheresDocument);
  const [updatePlatform] = useMutation(UpdatePlatformDocument);
  const [updateEmail] = useMutation(UpdateEmailsDocument);
  const [updatePhone] = useMutation(UpdatePhonesDocument);
  const { data = { users: [] }, refetch } = useGetUsersSpheresQuery({
    variables: { where: { userId: loggedUserId } },
  });
  const { data: platformList } = useGetSocialMediaPlatformsQuery();
  const { data: phoneTypes } = useGetPhoneTypesQuery();
  const { data: emailTypes } = useGetEmailTypesQuery();

  useEffect(() => {
    if (createError) {
      console.error(createError);
    }
    if (updateError) {
      console.error(updateError);
    }
    if (
      state &&
      state?.sphereIds &&
      state?.sphereIds.length &&
      data.users[0]?.userSpheres.length
    ) {
      setSelectedSphereIds([...state.sphereIds]);
      setSelectedSphereNames(
        data.users[0].userSpheres
          .filter((sphere) => state.sphereIds.includes(sphere.sphereId))
          .map((sphere) => sphere.sphereName)
      );
    }
    if (state && state?.personData?.personPlatforms) {
      setPlatforms(
        state.personData.personPlatforms.map((platform: PlatformInterface) => ({
          platformId: platform.platformId,
          platformName: platform.platformName,
          platformUrl: platform.platformUrl,
          platformFavorite: platform.platformFavorite,
          newPlatform: false,
        }))
      );
    }
    if (state && state?.personData?.personPhones) {
      setPhones(
        state.personData.personPhones.map((phone: PhoneInterface) => ({
          phoneId: phone.phoneId,
          phoneType: phone.phoneType,
          phoneNumber: phone.phoneNumber,
          newPlatform: false,
        }))
      );
    }
    if (state && state?.personData?.personEmails) {
      setEmails(
        state.personData.personEmails.map((email: EmailInterface) => ({
          emailId: email.emailId,
          emailType: email.emailType,
          emailAddress: email.emailAddress,
          newPlatform: false,
        }))
      );
    }
  }, [createError, state, action]);

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      setPersonBirthDate(dateString);
    }
  };

  const handleCreateSphere = async () => {
    if (searchSphere?.length) {
      try {
        const newId = uuidv4();
        const dateTime = new Date().toISOString();
        const { data } = await createSphere({
          variables: {
            input: [
              {
                sphereId: newId,
                sphereName: searchSphere,
                sphereColor: "#1677ff",
                sphereCreatedAt: dateTime,
                sphereUpdatedAt: dateTime,
                sphereUserOwnerId: loggedUserId,
                sphereUserOwner: {
                  connect: {
                    where: {
                      node: {
                        userId: loggedUserId,
                      },
                    },
                  },
                },
              },
            ],
            where: { userId: loggedUserId },
          },
        });

        if (data) {
          setSelectedSphereIds([...selectedSphereIds, newId]);
          setSelectedSphereNames([...selectedSphereNames, searchSphere]);
          refetch();
        }
      } catch (error: any) {
        console.error("[Failed to create Sphere]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    }
  };

  const handleSubmit = async (values: any) => {
    const dateTime = new Date().toISOString();
    const createPersonId = uuidv4();
    const { personPhone, birthDate, ...rest } = values;

    const platformCleanList = platforms.filter(
      (platform) => platform.platformName.length && platform.platformUrl.length
    );
    const emailCleanList = emails.filter(
      (email) => email.emailAddress.length && email.emailType.length
    );
    const phoneCleanList = phones.filter(
      (phone) => phone.phoneNumber.length && phone.phoneType.length
    );
    const connectSpheres = () => {
      const spheres = [...selectedSphereIds];
      if (spheres.length) {
        return spheres.map((id) => ({
          where: { node: { sphereId: id } },
        }));
      }
    };
    const disconnectSpheres = () => {
      if (state?.sphereIds.length && state?.personData?.personId) {
        const spheres = [...state.sphereIds];
        const deletedSpheres = spheres.filter(
          (sphereId) => !selectedSphereIds.includes(sphereId)
        );
        if (deletedSpheres.length) {
          return deletedSpheres.map((id) => ({
            where: {
              node: {
                sphereId: id,
                spherePersons_SINGLE: {
                  personId: state?.personData?.personId,
                },
              },
            },
          }));
        }
      }
    };
    const handleCreatePlatforms = () => {
      const list = [...platformCleanList];
      if (list.length && list[0].platformName) {
        const newPlatforms = list.filter((platform) => platform.newPlatform);

        if (newPlatforms.length) {
          return newPlatforms.map((platform) => ({
            node: {
              platformId: platform.platformId,
              platformName: platform.platformName,
              platformUrl: platform.platformUrl,
              platformFavorite: platform.platformFavorite,
              platformOwner: {
                connect: { where: { node: { personId: createPersonId } } },
              },
            },
          }));
        }
      }
    };
    const handleUpdatePlatforms = async () => {
      try {
        const list = [...platformCleanList];
        if (
          list.length &&
          list[0].platformName &&
          state?.personData?.personId
        ) {
          const modifiedPlatforms = list.filter(
            (platform) =>
              !platform.newPlatform && platform.modified === "update"
          );
          if (modifiedPlatforms.length) {
            modifiedPlatforms.forEach((platform) => {
              updatePlatform({
                variables: {
                  where: {
                    platformId: platform.platformId,
                    platformOwner: { personId: state.personData.personId },
                  },
                  update: {
                    platformName: platform.platformName,
                    platformUrl: platform.platformUrl,
                    platformFavorite: platform.platformFavorite,
                  },
                },
              });
            });
          }
        }
      } catch (error: any) {
        console.error("[Failed to update Platform]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    };
    const handleDeletePlatforms = () => {
      const list = [...platformCleanList];
      if (list.length && list[0].platformName && state?.personData?.personId) {
        const deletedPlatforms = list.filter(
          (platform) => !platform.newPlatform && platform.modified === "delete"
        );
        if (deletedPlatforms.length) {
        }

        return deletedPlatforms.map((platform) => ({
          where: {
            node: {
              platformId: platform.platformId,
              platformOwner: { personId: state.personData.personId },
            },
          },
        }));
      }
    };
    const handleCreateEmails = () => {
      const list = [...emailCleanList];
      if (list.length && list[0].emailAddress) {
        const newEmails = list.filter((email) => email.newEmail);

        if (newEmails.length) {
          return newEmails.map((email) => ({
            node: {
              emailId: email.emailId,
              emailAddress: email.emailAddress,
              emailType: email.emailType,
              emailOwner: {
                connect: { where: { node: { personId: createPersonId } } },
              },
            },
          }));
        }
      }
    };
    const handleUpdateEmails = async () => {
      try {
        const list = [...emailCleanList];
        if (
          list.length &&
          list[0].emailAddress &&
          state?.personData?.personId
        ) {
          const modifiedEmails = list.filter(
            (email) => !email.newEmail && email.modified === "update"
          );
          if (modifiedEmails.length) {
            modifiedEmails.forEach((email) => {
              updateEmail({
                variables: {
                  where: {
                    emailId: email.emailId,
                    emailOwner: { personId: state.personData.personId },
                  },
                  update: {
                    emailAddress: email.emailAddress,
                    emailType: email.emailType,
                  },
                },
              });
            });
          }
        }
      } catch (error: any) {
        console.error("[Failed to update Email]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    };
    const handleDeleteEmails = () => {
      const list = [...emailCleanList];
      if (list.length && list[0].emailAddress && state?.personData?.personId) {
        const deletedEmails = list.filter(
          (email) => !email.newEmail && email.modified === "delete"
        );
        if (deletedEmails.length) {
        }

        return deletedEmails.map((email) => ({
          where: {
            node: {
              emailId: email.emailId,
              emailOwner: { personId: state.personData.personId },
            },
          },
        }));
      }
    };
    const handleCreatePhones = () => {
      const list = [...phoneCleanList];
      if (list.length && list[0].phoneNumber) {
        const newPhones = list.filter((phone) => phone.newPhone);

        if (newPhones.length) {
          return newPhones.map((phone) => ({
            node: {
              phoneId: phone.phoneId,
              phoneType: phone.phoneType,
              phoneNumber: phone.phoneNumber,
              phoneOwner: {
                connect: { where: { node: { personId: createPersonId } } },
              },
            },
          }));
        }
      }
    };
    const handleUpdatePhones = async () => {
      try {
        const list = [...phoneCleanList];
        if (list.length && list[0].phoneNumber && state?.personData?.personId) {
          const modifiedPhones = list.filter(
            (phone) => !phone.newPhone && phone.modified === "update"
          );
          if (modifiedPhones.length) {
            modifiedPhones.forEach((phone) => {
              updatePhone({
                variables: {
                  where: {
                    phoneId: phone.phoneId,
                    phoneOwner: { personId: state.personData.personId },
                  },
                  update: {
                    phoneType: phone.phoneType,
                    phoneNumber: phone.phoneNumber,
                  },
                },
              });
            });
          }
        }
      } catch (error: any) {
        console.error("[Failed to update Phone]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    };
    const handleDeletePhones = () => {
      const list = [...phoneCleanList];
      if (list.length && list[0].phoneNumber && state?.personData?.personId) {
        const deletedPhones = list.filter(
          (phone) => !phone.newPhone && phone.modified === "delete"
        );
        if (deletedPhones.length) {
        }

        return deletedPhones.map((phone) => ({
          where: {
            node: {
              phoneId: phone.phoneId,
              phoneOwner: { personId: state.personData.personId },
            },
          },
        }));
      }
    };
    const presetValues = {
      personId: state?.personData?.personId
        ? state.personData.personId
        : createPersonId,
      personBirthDate: personBirthDate ? personBirthDate : "",
      personCreatedAt: state?.personData?.personCreatedAt
        ? state.personData.personCreatedAt
        : dateTime,
      personCreatedById: loggedUserId,
      personCreatedBy: {
        connect: { where: { node: { userId: loggedUserId } } },
      },
      personUpdatedAt: dateTime,
      personSpheres: {
        connect: connectSpheres(),
        disconnect: disconnectSpheres(),
      },
      personPlatforms: {
        create: handleCreatePlatforms(),
        delete: handleDeletePlatforms(),
      },
      personPhones: {
        create: handleCreatePhones(),
        delete: handleDeletePhones(),
      },
      personEmails: {
        create: handleCreateEmails(),
        delete: handleDeleteEmails(),
      },
    };
    const userData = () => {
      if (personPhone) {
        return {
          ...rest,
          ...presetValues,
          personPrimaryPhone: `${personPhone.isoCode}.${personPhone.countryCode}.${personPhone.areaCode}.${personPhone.phoneNumber}`,
        };
      } else {
        return { ...rest, ...presetValues };
      }
    };
    if (action === "create") {
      try {
        const response = await createPerson({
          variables: { input: [userData()] },
        });

        if (response.errors) {
          setErrorMessage(
            "ERROR TO CREATE PERSON" + response.errors[0].message
          );
        } else {
          navigate(-1);
        }
      } catch (error: any) {
        console.error("[Failed to create Person]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
          if (
            error.graphQLErrors[0].message.includes(
              "Constraint validation failed"
            )
          ) {
            setErrorMessage(
              "Person already exists, please use another EMAIL or PHONE"
            );
          }
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    }
    if (action === "update") {
      try {
        const response = await updatePerson({
          variables: {
            update: userData(),
            where: {
              personId: state?.personData?.personId,
            },
          },
        });

        if (response.errors) {
          setErrorMessage(
            "ERROR TO UPDATE PERSON" + response.errors[0].message
          );
        } else {
          handleUpdatePlatforms();
          handleUpdatePhones();
          handleUpdateEmails();
          navigate(`/person/${values?.personName}`, {
            state: { personId: state?.personData?.personId },
          });
        }
      } catch (error: any) {
        console.error("[Failed to update Person]", error);
        if (error.graphQLErrors) {
          console.error(
            "[PERSON FORM - GraphQL Error: ]" +
              error.graphQLErrors.map((e: any) => e.message).join(", ")
          );
          if (
            error.graphQLErrors[0].message.includes(
              "Constraint validation failed"
            )
          ) {
            setErrorMessage(
              "Person already exists, please use another EMAIL or PHONE"
            );
          }
        }
        if (error.networkError) {
          console.error(
            "[PERSON FORM - Network Error: ]" + error.networkError.message
          );
        }
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          background:
            "linear-gradient(145deg, rgba(144,144,144,1) 16%, rgba(109,109,109,1) 40%, rgba(114,114,114,0.9192051820728291) 72%, rgba(144,144,144,0.3981967787114846) 96%)",
          color: "white",
          padding: "16px 8px",
          margin: "0px 0px 16px 0px",
          borderRadius: "8px",
        }}
      >
        <h1>
          {action === "create"
            ? "Create Person".toUpperCase()
            : "Update Person".toUpperCase()}
        </h1>
      </div>
      <Button onClick={() => navigate(-1)} style={{ marginRight: "16px" }}>
        Cancel
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={{ maxWidth: "800px", width: "100%" }}>
          <Form
            className="person-form"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={
              action === "update" && state?.personData
                ? {
                    ...state.personData,
                    birthDate: state.personData?.personBirthDate
                      ? dayjs(state.personData?.personBirthDate, "YYYY-MM-DD")
                      : undefined,
                    personPhone: {
                      countryCode: handlePhoneFormat(
                        state.personData?.personPrimaryPhone
                      ).countryCode,
                      areaCode: handlePhoneFormat(
                        state.personData?.personPrimaryPhone
                      ).areaCode,
                      isoCode: handlePhoneFormat(
                        state.personData?.personPrimaryPhone
                      ).isoCode,
                      phoneNumber: handlePhoneFormat(
                        state.personData?.personPrimaryPhone
                      ).phoneNumber,
                    },
                  }
                : undefined
            }
          >
            <FormItem
              label="Name"
              name="personName"
              rules={[
                { required: true, message: "Please enter person's name" },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Primary Email"
              name="personPrimaryEmail"
              rules={[
                { required: true, message: "Please enter primary email" },
              ]}
            >
              <Input />
            </FormItem>
            <EmailInputs
              action={action}
              emails={emails}
              setEmails={setEmails}
              emailTypes={emailTypes}
            />
            <Button
              type="primary"
              style={{ margin: "16px 0px" }}
              onClick={() =>
                setEmails([
                  ...emails,
                  {
                    emailId: uuidv4(),
                    emailAddress: "",
                    emailType: "",
                    newEmail: true,
                  },
                ])
              }
            >
              <PlusCircleOutlined /> Add Email Address
            </Button>
            <FormItem label="Primary Phone" name="personPhone" required={true}>
              <PhoneInput required={true} />
            </FormItem>
            <FormItem>
              <PhoneInputs
                action={action}
                phones={phones}
                setPhones={setPhones}
                phoneTypes={phoneTypes}
              />
              <Button
                type="primary"
                style={{ margin: "16px 0px" }}
                onClick={() =>
                  setPhones([
                    ...phones,
                    {
                      phoneId: uuidv4(),
                      phoneType: "",
                      phoneNumber: "",
                      newPhone: true,
                    },
                  ])
                }
              >
                <PlusCircleOutlined /> Add Phone
              </Button>
            </FormItem>
            <FormItem label="Birth Date" name="birthDate">
              <DatePicker onChange={onDateChange} />
            </FormItem>
            <FormItem label="Description" name="personDescription">
              <TextArea rows={3} />
            </FormItem>
            <p>Platforms</p>
            <PlatformInputs
              action={action}
              platforms={platforms}
              setPlatforms={setPlatforms}
              platformList={platformList}
            />
            <Button
              type="primary"
              style={{ margin: "16px 0px" }}
              onClick={() =>
                setPlatforms([
                  ...platforms,
                  {
                    platformId: uuidv4(),
                    platformName: "",
                    platformUrl: "",
                    platformFavorite: false,
                    newPlatform: true,
                  },
                ])
              }
            >
              <PlusCircleOutlined /> Add Platform
            </Button>
            <p>Spheres</p>
            {data?.users[0]?.userSpheres.length ? (
              <Select
                mode="multiple"
                value={selectedSphereNames}
                allowClear={true}
                onChange={(value, option) => {
                  setSelectedSphereNames(value);
                  if (Array.isArray(option)) {
                    setSelectedSphereIds(option.map((o: any) => o.key));
                  }
                }}
                style={{ width: "100%" }}
                placeholder="Select spheres"
                onSearch={(value) => setSearchSphere(value.trim())}
                dropdownRender={(menu) => {
                  if (
                    searchSphere &&
                    !data.users[0].userSpheres.filter(
                      (sphere) => sphere.sphereName === searchSphere
                    ).length
                  ) {
                    return (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Button
                            type="primary"
                            onClick={handleCreateSphere}
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Create Sphere
                          </Button>
                        </Space>
                      </>
                    );
                  } else {
                    return menu;
                  }
                }}
                options={data.users[0].userSpheres.map(
                  (sphere: { sphereId: string; sphereName: string }) => ({
                    key: sphere.sphereId,
                    value: sphere.sphereName,
                    label: sphere.sphereName,
                  })
                )}
              />
            ) : (
              "No spheres available"
            )}
            <Form.Item></Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                {action === "create" ? "Create Person" : "Save changes"}
              </Button>
              {errorMessage && (
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default PersonForm;
