import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Alert,
  Button,
  Space,
  Input,
  TableColumnType,
} from "antd";
import { SortDirection, useGetPeopleQuery } from "../../../generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../hooks/cookie";
import { multiColorGradient, oneColorGradient } from "../../hooks/visual";
import CheckColumns from "./list-components/CheckColumns";
import useColumns from "./list-components/columns";

/**
 * Component to display list of people related to logged user and selected spheres.
 * Columns are listed in columns.tsx.
 * Button to select columns to display -> CheckColumns.
 * Button to create person -> PersonForm, click on a row to view person profile -> DisplayPerson.
 * Create person includes selected spheres by default.
 */
const PeopleList: React.FC = () => {
  const navigate = useNavigate();
  const [globalSearchText, setGlobalSearchText] = useState("");
  const columns = useColumns();
  const [selectedColumns, setSelectedColumns] = useState<
    TableColumnType<any>[]
  >([]);
  let { state } = useLocation();
  let sphereIds = state?.selectedSpheres.map((sphere: any) => sphere.sphereId);
  let sphereNames = state?.selectedSpheres.map(
    (sphere: any) => sphere.sphereName
  );
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    loading,
    error,
    data = { people: [] },
    refetch,
  } = useGetPeopleQuery({
    variables: {
      where: {
        personSpheres_SINGLE: {
          sphereId_IN: sphereIds,
          sphereUserOwner: { userId: loggedUserId },
        },
      },
      options: { sort: [{ personUpdatedAt: SortDirection.Desc }] },
    },
  });

  /**Refresh list data when closing create person form or fetch is true or sphereIds/sphereNames change */
  useEffect(() => {
    if (state) {
      refetch();
    }
  }, [state]);

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );

  const dataSource: any = data?.people
    .filter(
      (person) =>
        person.personName &&
        person.personName.toLowerCase().includes(globalSearchText.toLowerCase())
    )
    .map((person, index) => ({
      key: index,
      personId: person.personId,
      personName: person.personName,
      personPrimaryEmail: person.personPrimaryEmail,
      personSpheres: person.personSpheres,
      personPrimaryPhone: person.personPrimaryPhone,
      personPlatforms: person.personPlatforms,
      personPhones: person.personPhones,
      personEmails: person.personEmails,
      personBirthDate: person.personBirthDate,
    }));

  const handleGradient = () => {
    if (state?.selectedSpheres.length === 1) {
      return oneColorGradient(
        state?.selectedSpheres[0].sphereColor
          ? state?.selectedSpheres[0].sphereColor
          : "#1677ff"
      );
    } else {
      return multiColorGradient(
        state?.selectedSpheres.map((sphere: any) =>
          sphere.sphereColor ? sphere.sphereColor : "#1677ff"
        )
      );
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          background: handleGradient(),
          color: "white",
          padding: "2px 16px",
          margin: "0px 0px 16px 0px",
          borderRadius: "8px",
        }}
      >
        <h1
          style={{
            backgroundColor: "white",
            width: "fit-content",
            padding: "16px 16px",
            borderRadius: "8px",
            color: "#141414",
            opacity: 0.8,
          }}
        >
          {sphereNames
            .map((sphereName: string) => sphereName.toUpperCase())
            .join(" + ")}
        </h1>
      </div>
      <Space
        style={{
          marginBottom: 16,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <Button
            type="primary"
            onClick={() => {
              navigate("/person-form/create", {
                state: { sphereIds: sphereIds },
              });
            }}
          >
            Create Person
          </Button>
          <CheckColumns
            columns={columns}
            setSelectedColumns={setSelectedColumns}
          />
        </Space>
        <Input.Search
          placeholder="Search by name"
          onSearch={(value) => setGlobalSearchText(value)}
          style={{ marginBottom: 16 }}
          allowClear={true}
        />
      </Space>
      <Table
        columns={
          selectedColumns && selectedColumns[0] ? selectedColumns : columns
        }
        dataSource={dataSource}
        pagination={{ pageSize: 10 }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/person/${record.personName}`, {
              state: { personId: record.personId },
            });
          },
        })}
      />
    </>
  );
};

export default PeopleList;
