import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Spin,
} from "antd";
import PhoneInput from "antd-phone-input";
import FormItem from "antd/es/form/FormItem";
import { useState } from "react";
import {
  CreateUsersDocument,
  UpdateUsersDocument,
  useGetUsersQuery,
} from "../../generated/graphql";
import { getCookie } from "../hooks/cookie";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

/**
 * Component used to create or update User information
 * @param page ["register", "update"] : set the form page to register or update User
 */
const UserForm = ({ page }: { page: string }) => {
  const navigate = useNavigate();
  const [userBDate, setUserBDate] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    loading,
    error,
    data = { users: [] },
    refetch,
  } = useGetUsersQuery({
    variables: {
      where: {
        userId: loggedUserId,
      },
    },
    skip: page === "update" ? false : true,
  });
  const [updateUser] = useMutation(UpdateUsersDocument);
  const [createUser] = useMutation(CreateUsersDocument);

  if (loading) {
    return <Spin tip="Loading..." />;
  }
  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      setUserBDate(dateString);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={
        page === "update"
          ? {
              ...data.users[0],
              birthDate: dayjs(data.users[0].userBirthDate, "YYYY-MM-DD"),
              userPhone: {
                countryCode: data?.users[0]?.userCountryCode,
                areaCode: data?.users[0]?.userAreaCode,
                isoCode: data?.users[0]?.userIsoCode,
                phoneNumber: data?.users[0]?.userPhoneNumber,
              },
            }
          : undefined
      }
      onFinish={async (values) => {
        let { userPhone, birthDate, ...rest } = values;

        const dataToUpdate = {
          ...rest,
          userName: rest.userName.toLowerCase(),
          userEmail: rest.userEmail.toLowerCase(),
          userBirthDate: userBDate ? userBDate : data.users[0].userBirthDate,
          userCountryCode: userPhone?.countryCode,
          userAreaCode: userPhone?.areaCode,
          userIsoCode: userPhone?.isoCode,
          userPhoneNumber: userPhone?.phoneNumber,
        };

        if (page === "update" && dataToUpdate) {
          try {
            const { data } = await updateUser({
              variables: {
                update: dataToUpdate,
                where: {
                  userId: loggedUserId,
                },
              },
            });
            if (data) {
              navigate("/my-account");
            }
          } catch (error: any) {
            console.error("Update user error:", error);
            if (error.graphQLErrors) {
              console.error(
                "[UPDATE USER FORM - GraphQL Error: ]" +
                  error.graphQLErrors.map((e: any) => e.message).join(", ")
              );
              if (
                error.graphQLErrors[0].message.includes(
                  "Constraint validation failed"
                )
              ) {
                setErrorMessage(
                  "User already exists, please use another EMAIL"
                );
              }
            }
            if (error.networkError) {
              console.error(
                "[UPDATE USER FORM - Network Error: ]" +
                  error.networkError.message
              );
            }
          }
        } else if (page === "register" && dataToUpdate) {
          try {
            const { data } = await createUser({
              variables: {
                input: { ...dataToUpdate, userId: uuidv4() },
              },
            });
            if (data) {
              navigate("/login");
            }
          } catch (error: any) {
            console.error("Registration error:", error);
            if (error.graphQLErrors) {
              console.error(
                "[REGISTRATION FORM - GraphQL Error: ]" +
                  error.graphQLErrors.map((e: any) => e.message).join(", ")
              );
              if (
                error.graphQLErrors[0].message.includes(
                  "Constraint validation failed"
                )
              ) {
                setErrorMessage(
                  "User already exists, please use another EMAIL"
                );
              }
            }
            if (error.networkError) {
              console.error(
                "[REGISTRATION FORM - Network Error: ]" +
                  error.networkError.message
              );
            }
          }
        }
      }}
    >
      <FormItem
        label="Name"
        name="userName"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Email"
        name="userEmail"
        rules={[{ required: true, message: "Please enter your email" }]}
      >
        <Input />
      </FormItem>
      {page === "register" && (
        <FormItem
          label="Password"
          name="userPassword"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password />
        </FormItem>
      )}
      <FormItem
        label="Birth Date"
        name="birthDate"
        required={true}
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("You must enter your birth date")),
          },
        ]}
      >
        <DatePicker onChange={onDateChange} />
      </FormItem>
      <FormItem label="Phone" name="userPhone" required={false}>
        <PhoneInput />
      </FormItem>
      {page === "register" && (
        <Form.Item
          name="userPolicyAccepted"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("You must agree to the terms and conditions")
                    ),
            },
          ]}
        >
          <Checkbox>
            I agree to the{" "}
            <a href="/terms-and-conditions">terms and conditions</a>
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {page === "register" ? "Create Account" : "Save changes"}
        </Button>
      </Form.Item>
      {errorMessage && (
        <p style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          {errorMessage}
        </p>
      )}
    </Form>
  );
};

export default UserForm;
