import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Alert,
  Button,
  Space,
  Input,
  TableColumnType,
} from "antd";
import { SortDirection, useGetPeopleQuery } from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import { useNavigate } from "react-router-dom";
import CheckColumns from "./list-components/CheckColumns";
import useColumns from "./list-components/columns";

/**
 * Component to display ALL people related to logged user, with or without sphere.
 * Columns are listed in columns.tsx.
 * Button to select columns to display -> CheckColumns.
 * Button to create person -> PersonForm, click on a row to view person profile -> DisplayPerson.
 * Create person does NOT include any sphere unless selected.
 */
const AllPeople: React.FC = () => {
  const navigate = useNavigate();
  const [pageCalled, setPageCalled] = useState(false);
  const [globalSearchText, setGlobalSearchText] = useState("");
  const columns = useColumns();
  const [selectedColumns, setSelectedColumns] = useState<
    TableColumnType<any>[]
  >([]);
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    loading,
    error,
    data = { people: [] },
    refetch,
  } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
      options: { sort: [{ personUpdatedAt: SortDirection.Desc }] },
    },
  });

  useEffect(() => {
    if (!pageCalled) {
      refetch();
      setPageCalled(true);
    }
  }, [pageCalled]);

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );

  /**Data to display in list, updates when search by name from searchbar */
  const dataSource: any = data?.people
    .filter(
      (person) =>
        person.personName &&
        person.personName.toLowerCase().includes(globalSearchText.toLowerCase())
    )
    .map((person, index) => ({
      key: index,
      personId: person.personId,
      personName: person.personName,
      personPrimaryEmail: person.personPrimaryEmail,
      personSpheres: person.personSpheres,
      personPrimaryPhone: person.personPrimaryPhone,
      personPlatforms: person.personPlatforms,
      personPhones: person.personPhones,
      personEmails: person.personEmails,
      personBirthDate: person.personBirthDate,
    }));

  return (
    <>
      <div
        style={{
          width: "100%",
          background:
            "linear-gradient(145deg, rgba(24,144,255,1) 16%, rgba(9,109,217,1) 40%, rgba(11,114,222,0.9192051820728291) 72%, rgba(24,144,255,0.3981967787114846) 96%)",
          color: "white",
          padding: "16px 8px",
          margin: "0px 0px 16px 0px",
          borderRadius: "8px",
        }}
      >
        <h1>{"All people".toUpperCase()}</h1>
      </div>
      <Space
        style={{
          marginBottom: 16,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <Button
            type="primary"
            onClick={() => {
              navigate("/person-form/create");
            }}
          >
            Create Person
          </Button>
          <CheckColumns
            columns={columns}
            setSelectedColumns={setSelectedColumns}
          />
        </Space>
        <Input.Search
          placeholder="Search by name"
          onSearch={(value) => setGlobalSearchText(value)}
          style={{ marginBottom: 16 }}
          allowClear={true}
        />
      </Space>
      <Table
        columns={
          selectedColumns && selectedColumns[0] ? selectedColumns : columns
        }
        dataSource={dataSource}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 1000 }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/person/${record.personName}`, {
              state: { personId: record.personId },
            });
          },
        })}
      />
    </>
  );
};

export default AllPeople;
